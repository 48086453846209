import React, { useState, useEffect, useRef } from "react";
import useScreenSizes from "../../utils/useScreenSize";
import { useSelector } from "react-redux";

const FAQQuestions = () => {
  const faqData = {
    GeneralFAQ: [
      {
        Question: "What is StudyBuddy?",
        Answer:
          "StudyBuddy is a comprehensive Learning Management System (LMS) designed to streamline administrative tasks, enhance student learning, and foster a digitally connected school environment for educational institutions.",
      },
      {
        Question: "Who is StudyBuddy for?",
        Answer:
          "StudyBuddy is suitable for schools, colleges, and educational institutions of all sizes, from primary to tertiary levels, aiming to modernize operations and improve efficiency.",
      },
      {
        Question: "What makes StudyBuddy unique?",
        Answer:
          "StudyBuddy focuses on personalized learning, seamless communication, and advanced analytics, offering a one-stop solution for managing various aspects of school administration.",
      },
      {
        Question: "What are the core features of StudyBuddy?",
        Answer:
          "Core features include student information management, attendance tracking, fee management, library management, hostel management, transport management, and task automation.",
      },
      {
        Question: "How does StudyBuddy handle digitalization?",
        Answer:
          "StudyBuddy enables schools to go paperless through cloud storage, digital document management, and online communication channels.",
      },
      {
        Question: "What security measures does StudyBuddy have in place?",
        Answer:
          "StudyBuddy prioritizes data safety with robust security measures, including hack-proof technology, data ownership protection, and 24/7 customer support.",
      },
      {
        Question: "How does StudyBuddy benefit students?",
        Answer:
          "Students gain access to online study materials, project-based learning, daily practice problems, and personalized career guidance.",
      },
      {
        Question: "How does StudyBuddy benefit teachers?",
        Answer:
          "Teachers can automate tasks, access student data easily, and collaborate effectively with colleagues.",
      },
      {
        Question: "How does StudyBuddy benefit school administrators?",
        Answer:
          "Administrators can streamline operations, improve efficiency, and make data-driven decisions using advanced management and monitoring tools.",
      },
      {
        Question: "How does StudyBuddy integrate with existing school systems?",
        Answer:
          "StudyBuddy can be integrated with various existing systems through APIs and data import/export options, ensuring a smooth transition.",
      },
      {
        Question: "How easy is it to learn and use StudyBuddy?",
        Answer:
          "StudyBuddy is designed to be user-friendly, with a simple interface and intuitive navigation for users of all levels.",
      },
      {
        Question: "Does StudyBuddy provide training and support?",
        Answer:
          "Yes, StudyBuddy offers free initial setup and training to help institutions get started and make the most of the platform.",
      },
      {
        Question: "What is the cost of StudyBuddy?",
        Answer:
          "Pricing varies depending on the institution's size and needs. Please contact our sales team for a personalized quote.",
      },
      {
        Question: "Can I try StudyBuddy before purchasing?",
        Answer:
          "Yes, we offer a free trial period for users to experience the platform firsthand.",
      },
      {
        Question: "How does the fees management system work?",
        Answer:
          "The fees management system allows for easy collection, tracking, and reminders for student payments.",
      },
      {
        Question: "How does StudyBuddy assist with financial management?",
        Answer:
          "The finance management tools provide resources for budgeting, creating annual balance sheets, and detailed financial reporting.",
      },
      {
        Question: "How does StudyBuddy address future challenges in education?",
        Answer:
          "StudyBuddy aims to solve problems efficiently, promote socially responsible citizenship, and create a digitally connected environment for learning.",
      },
      {
        Question: "What is the vision for StudyBuddy's future development?",
        Answer:
          "The vision for StudyBuddy is to continue innovating and providing cutting-edge solutions to meet the evolving needs of education.",
      },
      {
        Question:
          "Is StudyBuddy compatible with different devices and operating systems?",
        Answer:
          "Yes, StudyBuddy is compatible with various devices and operating systems, making it accessible to all users.",
      },
      {
        Question: "How often is StudyBuddy updated with new features?",
        Answer:
          "StudyBuddy is regularly updated based on user feedback to improve functionality and introduce new features.",
      },
    ],
    student: [
      {
        Question: "How do I set up my account as a student?",
        Answer:
          "To set up your account, go to the StudyBuddy website or app. Click on 'Sign Up' and fill in your details, like your name, email, and password. Follow the instructions to complete your registration.",
      },
      {
        Question: "How do I log in if I forget my password?",
        Answer:
          "If you forget your password, click on 'Forgot Password?' on the login page. Enter your email address, and you will receive a link to reset your password.",
      },
      {
        Question: "How can I access study materials and assignments?",
        Answer:
          "You can access study materials and assignments by logging into your account, selecting your course, and navigating to the 'Materials' or 'Assignments' section.",
      },
      {
        Question: "How do I submit projects through StudyBuddy?",
        Answer:
          "To submit projects, go to the 'Assignments' section in your course, find the relevant assignment, and follow the instructions to upload your project file.",
      },
      {
        Question: "How can I track my assignment deadlines?",
        Answer:
          "You can track your assignment deadlines in the 'Assignments' section or by checking the calendar feature on your dashboard.",
      },
      {
        Question: "How do I participate in group discussions?",
        Answer:
          "To participate in group discussions, go to the 'Discussion' tab in your course. You can post your thoughts, ask questions, and reply to other students.",
      },
      {
        Question: "How do I find career guidance resources?",
        Answer:
          "You can find career guidance resources in the 'Career' section of the StudyBuddy platform. This area offers articles, videos, and tools to help with your career planning.",
      },
      {
        Question: "Can I download study materials for offline use?",
        Answer:
          "Yes, you can download study materials for offline use if the option is available for that specific resource. Look for a 'Download' button next to the material.",
      },
      {
        Question: "How can I review my graded assignments?",
        Answer:
          "To review your graded assignments, go to the 'Grades' section in your course. You can see your scores and any feedback your teacher has provided.",
      },
      {
        Question: "How do I communicate with my teachers through StudyBuddy?",
        Answer:
          "You can communicate with your teachers by sending messages through the 'Messages' feature or by posting questions in the discussion forums.",
      },
      {
        Question: "How do I join a class on StudyBuddy?",
        Answer:
          "To join a class, you usually need a class code or invitation link from your teacher. Enter the code or click the link to enroll in the class.",
      },
      {
        Question: "How can I check my overall performance on StudyBuddy?",
        Answer:
          "You can check your overall performance by going to the 'Performance' or 'Grades' section. Here, you can see your grades and progress across all your courses.",
      },
      {
        Question: "Can I change the language of my StudyBuddy dashboard?",
        Answer:
          "Yes, you can change the language by going to your account settings. Look for the 'Language' option and select your preferred language.",
      },
      {
        Question: "How can I track my learning goals?",
        Answer:
          "You can track your learning goals in the 'Goals' section of your dashboard. Set your objectives and monitor your progress toward achieving them.",
      },
      {
        Question: "How do I access live lessons or webinars?",
        Answer:
          "To access live lessons or webinars, go to the 'Live Lessons' or 'Webinars' section in your course. Click on the scheduled lesson to join.",
      },
      {
        Question: "How do I know if my teacher has posted new assignments?",
        Answer:
          "You can check for new assignments in the 'Assignments' section or look for notifications on your dashboard. You may also receive email alerts.",
      },
      {
        Question: "How can I join study groups on StudyBuddy?",
        Answer:
          "To join study groups, go to the 'Study Groups' section and browse available groups. Request to join any group that interests you.",
      },
      {
        Question: "Can I ask questions directly to my teacher on StudyBuddy?",
        Answer:
          "Yes, you can ask questions directly to your teacher using the messaging feature or by posting in the class discussion forum.",
      },
      {
        Question: "How do I set up notifications for deadlines and grades?",
        Answer:
          "To set up notifications, go to your account settings and enable notifications for deadlines and grades. Choose how you want to receive alerts (email or app notifications).",
      },
      {
        Question: "How do I find career-focused materials on StudyBuddy?",
        Answer:
          "You can find career-focused materials in the 'Career' section or by searching using keywords related to your interests in the search bar.",
      },
    ],
    teacher: [
      {
        Question: "How do I create a new course on StudyBuddy?",
        Answer:
          "Go to the 'Teacher Dashboard' and select 'Create Course.' Fill in the course details like title, description, and objectives, then upload your course materials.",
      },
      {
        Question: "How do I upload assignments for students?",
        Answer:
          "Navigate to your course, click on the 'Assignments' section, and select 'Upload Assignment.' You can set instructions, attach files, and set deadlines.",
      },
      {
        Question: "How can I manage student enrollments in my course?",
        Answer:
          "In the course settings, you can view and manage student enrollments, approve or deny requests, and monitor who is enrolled in the course.",
      },
      {
        Question: "How do I grade assignments through StudyBuddy?",
        Answer:
          "Access the 'Assignments' tab, select the student’s submission, and provide grades and comments directly in the grading interface.",
      },
      {
        Question: "How can I provide feedback to students?",
        Answer:
          "You can provide feedback by commenting on assignments, quizzes, or through direct messaging within the platform.",
      },
      {
        Question: "How do I collaborate with other teachers on StudyBuddy?",
        Answer:
          "You can collaborate by inviting co-teachers to your course or participating in teacher forums and groups on the platform.",
      },
      {
        Question: "How do I set up live classes or webinars for my students?",
        Answer:
          "Go to the 'Live Classes' section in your course and schedule a live session. You can set a date, time, and share the webinar link with students.",
      },
      {
        Question: "Can I customize the layout of my course?",
        Answer:
          "Yes, you can customize the layout by organizing content into modules, lessons, and assignments as per your preference.",
      },
      {
        Question: "How do I track student progress in my class?",
        Answer:
          "Use the 'Progress Tracker' tool available in the course dashboard to monitor student engagement, completed tasks, and overall progress.",
      },
      {
        Question: "How can I set deadlines for assignments?",
        Answer:
          "While creating or editing an assignment, there is an option to set submission deadlines and enable late submissions if needed.",
      },
      {
        Question: "How do I create quizzes and exams on StudyBuddy?",
        Answer:
          "Go to the 'Quizzes' or 'Exams' section in your course, create new quizzes, add questions (multiple-choice, true/false, etc.), and set grading rules.",
      },
      {
        Question: "How do I communicate with students through the platform?",
        Answer:
          "You can use the in-platform messaging system, discussion forums, or announcements to communicate with your students.",
      },
      {
        Question: "How do I create group projects or discussions?",
        Answer:
          "In the course settings, create group projects by assigning students to groups and setting collaborative tasks. You can also create discussion threads for group interaction.",
      },
      {
        Question: "How do I upload video or audio lessons?",
        Answer:
          "Navigate to the 'Resources' or 'Multimedia' section in your course, and upload your video or audio files to share with students.",
      },
      {
        Question: "Can I reuse my course for different classes?",
        Answer:
          "Yes, you can duplicate a course and make adjustments for new classes without having to recreate the entire course from scratch.",
      },
      {
        Question: "How do I manage my course calendar?",
        Answer:
          "In the 'Course Calendar' section, you can add important dates such as deadlines, live sessions, and exams for students to see.",
      },
      {
        Question: "How do I export grades from StudyBuddy?",
        Answer:
          "You can export grades by navigating to the 'Grades' section and selecting 'Export' to download them in a CSV or Excel format.",
      },
      {
        Question: "How can I get insights into student performance?",
        Answer:
          "Use the 'Analytics' feature in your course to view detailed insights on student performance, participation, and completion rates.",
      },
      {
        Question:
          "Can I set prerequisites for students before they access certain course materials?",
        Answer:
          "Yes, you can set prerequisites by going to the module settings and choosing conditions such as completion of a previous lesson or achieving a certain grade.",
      },
      {
        Question: "How do I monitor class attendance?",
        Answer:
          "For live classes, the platform tracks attendance automatically. You can review attendance reports in the 'Live Classes' section.",
      },
    ],
    parents: [
      {
        Question: "How can I create a parent account on StudyBuddy?",
        Answer:
          "Parents can create an account through an invitation link provided by the school or register directly via the StudyBuddy platform using their child’s school credentials.",
      },
      {
        Question: "How can I monitor my child's progress?",
        Answer:
          "You can monitor your child's progress through the parent dashboard, which provides access to grades, assignments, and performance reports.",
      },
      {
        Question: "What tools are available for communication with teachers?",
        Answer:
          "StudyBuddy offers a messaging system where parents can directly communicate with teachers to discuss their child’s progress and any concerns.",
      },
      {
        Question: "How do I access the LMS dashboard as a parent?",
        Answer:
          "Once you’ve created a parent account, you can log in to the StudyBuddy dashboard through the web or mobile app using your credentials.",
      },
      {
        Question: "Can I see my child's assignments and grades?",
        Answer:
          "Yes, you can view all your child’s assignments, grades, and feedback from teachers directly through the dashboard.",
      },
      {
        Question:
          "What resources are available for helping my child with studies?",
        Answer:
          "StudyBuddy provides access to online study materials, project-based learning resources, and practice problems to assist your child’s learning.",
      },
      {
        Question: "How often will I receive updates on my child's performance?",
        Answer:
          "You will receive regular updates through notifications, including progress reports, upcoming deadlines, and performance alerts.",
      },
      {
        Question: "Is there a way to set up alerts for important deadlines?",
        Answer:
          "Yes, you can customize notifications to receive alerts for assignment deadlines, exams, and important school announcements.",
      },
      {
        Question: "How do I update my contact information in StudyBuddy?",
        Answer:
          "You can update your contact information by logging into your parent account and navigating to the profile settings in the dashboard.",
      },
      {
        Question: "Can I participate in discussions or forums as a parent?",
        Answer:
          "Yes, some schools allow parents to join forums or discussion groups to interact with teachers and other parents regarding school activities and student learning.",
      },
      {
        Question:
          "What should I do if I have concerns about my child's learning?",
        Answer:
          "You can reach out to your child’s teacher directly through the platform or request a meeting to discuss your concerns.",
      },
      {
        Question: "How can I support my child's use of StudyBuddy at home?",
        Answer:
          "Encourage your child to regularly use the platform, check assignments, and use available resources. You can also set up a study routine based on their schedule.",
      },
      {
        Question:
          "Are there resources for parents to understand the platform better?",
        Answer:
          "Yes, StudyBuddy offers tutorials, FAQs, and customer support to help parents navigate the platform and use its features effectively.",
      },
      {
        Question: "How do I report issues related to my child's account?",
        Answer:
          "You can report any issues through the 'Help' section on the platform or contact StudyBuddy’s customer support for assistance.",
      },
      {
        Question: "Can I view all the courses my child is enrolled in?",
        Answer:
          "Yes, you can view all the courses your child is enrolled in from the parent dashboard, including course materials and upcoming assessments.",
      },
      {
        Question: "Is there a way to track my child's attendance?",
        Answer:
          "Yes, you can monitor your child’s attendance records through the dashboard, which provides real-time updates on their presence in classes.",
      },
      {
        Question: "How can I provide feedback about the platform?",
        Answer:
          "You can provide feedback by using the 'Feedback' option available on the platform or by contacting StudyBuddy’s support team.",
      },
      {
        Question: "What should I do if my child is struggling with a subject?",
        Answer:
          "You can reach out to your child’s teacher for guidance, and StudyBuddy also offers additional learning resources and support materials for struggling students.",
      },
      {
        Question:
          "Can I request a meeting with my child's teacher through StudyBuddy?",
        Answer:
          "Yes, StudyBuddy allows parents to request meetings with teachers directly through the communication feature in the platform.",
      },
      {
        Question:
          "What security measures are in place to protect my child's data?",
        Answer:
          "StudyBuddy ensures data protection through encryption, secure logins, and strict data ownership policies to ensure that all personal information is kept safe.",
      },
    ],
    admin: [
      {
        Question: "How do I set up a school account on StudyBuddy?",
        Answer:
          "You can set up a school account by contacting StudyBuddy’s support team, who will guide you through the registration and onboarding process. Alternatively, you can sign up directly through the platform if available.",
      },
      {
        Question: "What are the steps to create and manage classrooms?",
        Answer:
          "Once the school account is set up, you can easily create classrooms by entering class details such as class name, grade, and subjects. From the admin dashboard, you can assign teachers and students to each classroom.",
      },
      {
        Question:
          "How can I streamline administration processes with StudyBuddy?",
        Answer:
          "StudyBuddy offers tools like task automation, attendance tracking, report generation, and document management to help streamline administration processes and reduce manual tasks.",
      },
      {
        Question: "What tools are available for paperless administration?",
        Answer:
          "StudyBuddy provides paperless tools such as digital attendance, report card generation, bonafide certificate issuance, fee management, and document storage, allowing for a fully digital administration.",
      },
      {
        Question: "How do I manage user roles and permissions?",
        Answer:
          "You can manage user roles (e.g., administrator, teacher, student, parent) through the platform’s settings, where you can assign different access levels and permissions for each role.",
      },
      {
        Question: "Can I generate reports on student performance?",
        Answer:
          "Yes, StudyBuddy allows administrators to generate comprehensive reports on student performance, attendance, and other metrics, which can be customized according to school requirements.",
      },
      {
        Question: "How do I set up course templates for teachers?",
        Answer:
          "In the 'Course Templates' section, create a new template by filling in the required fields. This allows teachers to use a standard format for their courses.",
      },
      {
        Question: "How do I integrate StudyBuddy with existing school systems?",
        Answer:
          "StudyBuddy can be integrated with other school systems such as ERP, SIS, or grade management software via APIs or through support from the StudyBuddy technical team.",
      },
      {
        Question: "What options do I have for student enrollment management?",
        Answer:
          "You can manage student enrollment directly through the platform, including adding new students, assigning them to classrooms, and tracking enrollment data.",
      },
      {
        Question: "How do I handle technical issues related to administration?",
        Answer:
          "For technical issues, you can contact StudyBuddy’s 24/7 customer support or access the troubleshooting guide available within the platform.",
      },
      {
        Question: "Can I customize administrative settings?",
        Answer:
          "Yes, StudyBuddy allows customization of administrative settings, including user permissions, notification preferences, and report formats, to suit your school’s specific needs.",
      },
      {
        Question:
          "What support is available for implementing StudyBuddy in schools?",
        Answer:
          "StudyBuddy offers onboarding support, training sessions, and a dedicated customer service team to assist schools with implementation.",
      },
      {
        Question: "How do I ensure compliance with educational regulations?",
        Answer:
          "StudyBuddy ensures compliance with educational regulations by offering customizable templates and tools for attendance, reporting, and record-keeping that meet local and national standards.",
      },
      {
        Question: "What should I do if I encounter issues with user access?",
        Answer:
          "If there are issues with user access, you can check the user roles and permissions in the dashboard or contact StudyBuddy’s technical support for assistance.",
      },
      {
        Question: "How can I provide feedback about administrative features?",
        Answer:
          "You can provide feedback through the 'Feedback' option on the platform or by contacting StudyBuddy’s customer service.",
      },
      {
        Question:
          "What features are available for tracking school-wide performance?",
        Answer:
          "StudyBuddy offers features like performance dashboards, reports on student and teacher activity, and tools to track overall school performance metrics.",
      },
      {
        Question: "Can I set up alerts for important administrative tasks?",
        Answer:
          "Yes, you can set up automated alerts for key administrative tasks such as fee collection, document submission, and report card deadlines.",
      },
      {
        Question: "How do I manage budget and funding through the platform?",
        Answer:
          "StudyBuddy offers financial management tools to help track budgets, manage fees, generate balance sheets, and plan future expenditures.",
      },
      {
        Question: "Are there guidelines for effective school administration?",
        Answer:
          "Yes, StudyBuddy provides guidelines and best practices through their support documentation and training resources to help administrators run schools efficiently using the platform.",
      },
      {
        Question:
          "How can I facilitate communication between different departments?",
        Answer:
          "StudyBuddy includes messaging and notification systems that allow for smooth communication between various school departments, ensuring collaboration and efficiency.",
      },
    ],
    technicalSupport: [
      {
        Question: "What should I do if I encounter a technical issue?",
        Answer:
          "If you encounter a technical issue, first try basic troubleshooting like refreshing the page or restarting the app. If the problem persists, contact StudyBuddy’s technical support through the help section.",
      },
      {
        Question: "How do I reset my password?",
        Answer:
          "To reset your password, click on the 'Forgot Password' link on the login page, and follow the instructions to receive a password reset link via email.",
      },
      {
        Question: "Are there device compatibility requirements for StudyBuddy?",
        Answer:
          "Yes, StudyBuddy is compatible with most modern devices, including desktops, laptops, tablets, and smartphones. Ensure your device meets the minimum system requirements for optimal performance.",
      },
      {
        Question: "What browsers work best with the platform?",
        Answer:
          "StudyBuddy works best with the latest versions of Chrome, Firefox, Safari, and Edge. Always keep your browser up to date for the best experience.",
      },
      {
        Question: "How can I report a bug or technical problem?",
        Answer:
          "You can report bugs or technical problems through the 'Report a Problem' option in the platform’s help section or by contacting the technical support team directly.",
      },
      {
        Question: "Can I access StudyBuddy offline?",
        Answer:
          "No, StudyBuddy requires an active internet connection to access its features and content.",
      },
      {
        Question: "How do I clear my cache and cookies for troubleshooting?",
        Answer:
          "In your browser settings, go to 'Privacy & Security' and select 'Clear browsing data' to clear your cache and cookies. This can help resolve some performance issues.",
      },
      {
        Question: "What are the minimum system requirements to use StudyBuddy?",
        Answer:
          "StudyBuddy requires at least 2GB of RAM, a dual-core processor, and an updated web browser. A stable internet connection of 5 Mbps or higher is recommended.",
      },
      {
        Question: "How can I troubleshoot login problems?",
        Answer:
          "If you’re having trouble logging in, ensure your internet connection is stable, check your credentials, and try resetting your password. If issues persist, contact support.",
      },
      {
        Question: "What should I do if my account gets locked?",
        Answer:
          "If your account gets locked due to multiple login attempts, contact StudyBuddy support to unlock it or follow the instructions in the account recovery email.",
      },
      {
        Question: "How do I sync my account across multiple devices?",
        Answer:
          "Your StudyBuddy account is cloud-based and can be accessed from multiple devices by simply logging in with your credentials. Any changes made will automatically sync across devices.",
      },
      {
        Question: "Are there guides available for technical troubleshooting?",
        Answer:
          "Yes, StudyBuddy provides troubleshooting guides in the help section, covering common issues like connectivity, account access, and feature navigation.",
      },
      {
        Question: "How do I enable notifications for updates and alerts?",
        Answer:
          "To enable notifications, go to your account settings and adjust the notification preferences for updates, reminders, and alerts.",
      },
      {
        Question: "What should I do if I can’t access a specific feature?",
        Answer:
          "If a feature isn’t working, ensure that your browser or app is up to date. If the problem persists, contact StudyBuddy support for assistance.",
      },
      {
        Question: "Is there a way to contact technical support directly?",
        Answer:
          "Yes, you can contact technical support directly through the 'Help' or 'Support' section in the platform, via email, or through a live chat option if available.",
      },
      {
        Question: "How can I ensure my internet connection is stable?",
        Answer:
          "Ensure that your device is connected to a reliable Wi-Fi network or a stable mobile data connection. You can also perform an internet speed test to check your connection.",
      },
      {
        Question: "What are common issues reported by users?",
        Answer:
          "Common issues include login problems, slow loading times, and difficulty accessing certain features. These can usually be resolved by clearing cache or updating the app or browser.",
      },
      {
        Question: "How do I set up my account on a new device?",
        Answer:
          "Simply log in to StudyBuddy on your new device using your existing credentials. Your account data will sync automatically.",
      },
      {
        Question: "Can I use StudyBuddy on tablets or smartphones?",
        Answer:
          "Yes, StudyBuddy is compatible with both Android and iOS devices and can be accessed via the mobile app or through a web browser.",
      },
      {
        Question: "How often is the platform maintained or updated?",
        Answer:
          "StudyBuddy is regularly maintained and updated to improve performance and add new features. Notifications for scheduled maintenance or updates are sent in advance to users.",
      },
    ],

    privacyDataSecurity: [
      {
        Question:
          "What data protection measures does StudyBuddy have in place?",
        Answer:
          "StudyBuddy uses encryption, secure servers, and regular security audits to protect personal data. Only authorized personnel have access to sensitive information.",
      },
      {
        Question: "How is my personal information handled?",
        Answer:
          "Your personal information is securely stored and only used for the purposes outlined in StudyBuddy's privacy policy, such as improving your learning experience and managing your account.",
      },
      {
        Question: "Are there privacy policies I should be aware of?",
        Answer:
          "Yes, StudyBuddy has a comprehensive privacy policy that outlines how data is collected, used, and protected. You can review it on the platform’s website.",
      },
      {
        Question: "How is cloud storage secured?",
        Answer:
          "All data stored in the cloud is encrypted, ensuring that only authorized users can access it. StudyBuddy partners with trusted cloud service providers with high security standards.",
      },
      {
        Question: "What should I do if I have concerns about data security?",
        Answer:
          "If you have any concerns about data security, you should immediately contact StudyBuddy’s data protection team through the support section or email.",
      },
      {
        Question: "Can I delete my account, and what happens to my data?",
        Answer:
          "Yes, you can request to delete your account at any time. Once deleted, your personal data will be permanently removed from StudyBuddy’s systems, except for legally required records.",
      },
      {
        Question: "How is sensitive information protected?",
        Answer:
          "Sensitive information, such as passwords or payment details, is encrypted and only transmitted over secure channels. StudyBuddy ensures strict compliance with data protection standards.",
      },
      {
        Question: "What types of data does StudyBuddy collect?",
        Answer:
          "StudyBuddy collects basic user information (name, email), learning activity data, and any information you provide to complete assignments. No unnecessary personal data is collected.",
      },
      {
        Question: "Are there options for parental controls regarding data?",
        Answer:
          "Yes, parental controls can be set to manage what data is accessible for children using StudyBuddy. This includes monitoring progress and restricting certain data-sharing options.",
      },
      {
        Question: "How does StudyBuddy comply with privacy laws?",
        Answer:
          "StudyBuddy complies with all relevant privacy laws, such as the GDPR and COPPA, and takes measures to ensure data is handled in accordance with legal requirements.",
      },
      {
        Question: "Can I access my data, and how?",
        Answer:
          "Yes, you can request access to your data at any time by contacting StudyBuddy’s support. They will provide details on the data collected and how it’s used.",
      },
      {
        Question: "What happens to my data if I stop using StudyBuddy?",
        Answer:
          "If you stop using StudyBuddy, your data will remain securely stored for a set period as outlined in the privacy policy. After this, it will be deleted unless otherwise requested.",
      },
      {
        Question: "How are third parties involved in data handling?",
        Answer:
          "StudyBuddy may work with trusted third parties for data processing, such as cloud storage providers. These parties must comply with strict data security standards and privacy policies.",
      },
      {
        Question:
          "What should I do if I suspect unauthorized access to my account?",
        Answer:
          "If you suspect unauthorized access to your account, change your password immediately and contact StudyBuddy’s support team for assistance.",
      },
      {
        Question: "Are there security measures for online transactions?",
        Answer:
          "Yes, all online transactions on StudyBuddy are secured using SSL encryption and comply with PCI standards to ensure that payment details are safe.",
      },
      {
        Question: "How does StudyBuddy handle data breaches?",
        Answer:
          "In the event of a data breach, StudyBuddy will follow legal protocols to notify affected users and take immediate steps to mitigate any risks.",
      },
      {
        Question: "Can I control what information I share?",
        Answer:
          "Yes, you can control what personal information you share by adjusting your privacy settings in your account.",
      },
      {
        Question: "How often is the privacy policy updated?",
        Answer:
          "StudyBuddy’s privacy policy is reviewed and updated regularly to comply with changing regulations and improve user security. Users will be notified of major updates.",
      },
      {
        Question: "What is the process for reporting a privacy concern?",
        Answer:
          "To report a privacy concern, you can contact StudyBuddy’s data protection officer or submit a report through the platform’s support section.",
      },
      {
        Question: "How does StudyBuddy ensure the safety of student data?",
        Answer:
          "StudyBuddy takes extra precautions to safeguard student data, including encrypted data storage, restricted access, and compliance with laws protecting minors' data.",
      },
    ],
    accountManagement: [
      {
        Question: "How do I troubleshoot login problems?",
        Answer:
          "If you experience login problems, check your internet connection, ensure you’re entering the correct email and password, and clear your browser's cache and cookies. If the issue persists, try resetting your password.",
      },
      {
        Question: "What steps should I take to reset my password?",
        Answer:
          "To reset your password, click on the 'Forgot Password?' link on the login page. Follow the instructions sent to your registered email to create a new password.",
      },
      {
        Question: "How can I update my account information?",
        Answer:
          "To update your account information, log in to your account, go to the 'Account Settings' or 'Profile' section, and edit your details as needed.",
      },
      {
        Question: "Can I delete my account, and how?",
        Answer:
          "Yes, you can delete your account by navigating to the 'Account Settings' section and selecting the option to delete your account. Follow the prompts to confirm the deletion.",
      },
      {
        Question:
          "What should I do if I don't receive the password reset email?",
        Answer:
          "If you don't receive the password reset email, check your spam or junk folder. If it’s not there, ensure you entered the correct email address. If the issue persists, contact customer support for assistance.",
      },
      {
        Question: "How can I change my username or email address?",
        Answer:
          "You can change your username or email address in the 'Account Settings' section. Update the information and save your changes.",
      },
      {
        Question: "What should I do if my account is locked?",
        Answer:
          "If your account is locked due to multiple failed login attempts, wait for a specified period before trying again. If you still cannot access it, contact customer support for assistance.",
      },
      {
        Question: "How can I check my account activity?",
        Answer:
          "You can check your account activity in the 'Account Settings' or 'Activity Log' section, where you’ll find a record of recent logins and actions taken on your account.",
      },
      {
        Question: "What is the process for recovering a deactivated account?",
        Answer:
          "To recover a deactivated account, contact customer support with your account details. They will guide you through the recovery process.",
      },
      {
        Question: "Can I set up two-factor authentication for added security?",
        Answer:
          "Yes, you can set up two-factor authentication in the 'Security Settings' of your account. This adds an extra layer of security by requiring a verification code in addition to your password.",
      },
      {
        Question: "How can I manage notifications and preferences?",
        Answer:
          "You can manage your notification preferences in the 'Account Settings' under the 'Notifications' section, where you can customize what updates you receive.",
      },
      {
        Question: "Are there limitations on account usage?",
        Answer:
          "Account usage limitations may depend on your subscription plan. Check your plan details in the 'Subscription' section of your account for specific limits.",
      },
      {
        Question: "How do I link multiple accounts if needed?",
        Answer:
          "If you need to link multiple accounts, contact customer support for guidance on the process and any requirements for linking accounts.",
      },
      {
        Question:
          "What should I do if I suspect my account has been compromised?",
        Answer:
          "If you suspect your account has been compromised, change your password immediately, enable two-factor authentication, and contact customer support to report the issue.",
      },
      {
        Question: "Can I transfer my account to someone else?",
        Answer:
          "No, accounts are non-transferable. If someone else needs access, they will need to create a new account.",
      },
      {
        Question: "How do I unsubscribe from newsletters or updates?",
        Answer:
          "To unsubscribe from newsletters or updates, look for the 'Unsubscribe' link at the bottom of any email communication or adjust your preferences in the 'Account Settings.'",
      },
      {
        Question: "What happens to my account if I stop using the platform?",
        Answer:
          "If you stop using the platform, your account will remain active until you choose to deactivate or delete it. After a period of inactivity, it may be subject to automatic deletion as per the policy.",
      },
      {
        Question: "How can I receive updates about account features?",
        Answer:
          "You can opt-in for updates about account features in the 'Notifications' section of your account settings. This way, you'll receive news and updates directly.",
      },
      {
        Question: "Can I change my subscription plan, and how?",
        Answer:
          "Yes, you can change your subscription plan in the 'Subscription' section of your account settings. Select the desired plan and follow the prompts to complete the change.",
      },
      {
        Question: "What resources are available for managing my account?",
        Answer:
          "StudyBuddy provides help articles, tutorials, and customer support for account management. Check the 'Help Center' or 'Support' section for more information.",
      },
    ],
    platformFeatures: [
      {
        Question:
          "What is the overview of paperless administration in StudyBuddy?",
        Answer:
          "StudyBuddy provides a digital platform for managing school operations, reducing the need for physical paperwork through online documentation, forms, and communication tools.",
      },
      {
        Question: "How do smart notifications work on the platform?",
        Answer:
          "Smart notifications alert users about important updates, deadlines, and events, ensuring timely information delivery via email or app notifications.",
      },
      {
        Question: "What tools are available for school operations?",
        Answer:
          "The platform includes tools for attendance tracking, grading, assignment management, communication, and data analysis, streamlining school administrative tasks.",
      },
      {
        Question: "Can I customize the features available in my dashboard?",
        Answer:
          "Yes, users can customize their dashboard by selecting the tools and features they find most useful for easy access and navigation.",
      },
      {
        Question: "How do I access and use the assessment tools?",
        Answer:
          "Assessment tools are accessible from the dashboard, allowing teachers to create quizzes, exams, and assignments, track performance, and provide feedback.",
      },
      {
        Question: "Are there features for tracking student engagement?",
        Answer:
          "Yes, StudyBuddy includes analytics and reporting tools that monitor student participation, assignment completion, and interaction levels within the platform.",
      },
      {
        Question:
          "What collaboration tools are available for students and teachers?",
        Answer:
          "The platform offers discussion forums, group project tools, and messaging features that facilitate communication and collaboration among users.",
      },
      {
        Question: "How does StudyBuddy facilitate classroom management?",
        Answer:
          "It allows teachers to manage class rosters, track attendance, assign tasks, and communicate with students efficiently.",
      },
      {
        Question:
          "Can I integrate external tools or resources into StudyBuddy?",
        Answer:
          "Yes, the platform supports integrations with various educational tools and resources, enhancing the overall learning experience.",
      },
      {
        Question:
          "What analytics features are available for performance tracking?",
        Answer:
          "Users can access detailed reports and analytics on student performance, engagement levels, and overall class progress.",
      },
      {
        Question: "How do I manage course materials within the platform?",
        Answer:
          "Course materials can be uploaded, organized, and shared easily, with options to set access permissions for students.",
      },
      {
        Question: "What features support parent-teacher communication?",
        Answer:
          "StudyBuddy provides messaging tools and updates for parents, enabling effective communication regarding student progress and events.",
      },
      {
        Question: "How can I use StudyBuddy for scheduling and reminders?",
        Answer:
          "The platform includes calendar features for scheduling classes, events, and assignments, with reminder notifications to keep users informed.",
      },
      {
        Question:
          "What options are available for creating and managing assignments?",
        Answer:
          "Teachers can create, assign, and grade various types of assignments, including quizzes and projects, all within the platform.",
      },
      {
        Question: "How does StudyBuddy enhance interactive learning?",
        Answer:
          "It offers multimedia resources, discussion forums, and collaborative tools that promote student engagement and interactive learning experiences.",
      },
      {
        Question:
          "Are there features specifically designed for group projects?",
        Answer:
          "Yes, the platform includes tools for creating groups, assigning collaborative tasks, and tracking group progress on projects.",
      },
      {
        Question:
          "Can I access resources for professional development through the platform?",
        Answer:
          "StudyBuddy provides links to training materials, workshops, and other resources to support professional development for educators.",
      },
      {
        Question: "How does StudyBuddy ensure user-friendly navigation?",
        Answer:
          "The platform is designed with an intuitive interface, making it easy for users to navigate through different sections and access features.",
      },
      {
        Question: "What types of multimedia resources can be integrated?",
        Answer:
          "Users can integrate videos, images, documents, and interactive content to enhance lessons and engage students effectively.",
      },
      {
        Question:
          "How do I access updates and new features as they are released?",
        Answer:
          "Users will receive notifications about updates and can check the platform's news section or help center for information on new features.",
      },
    ],

    community: [
      {
        Question: "How do I join the StudyBuddy community?",
        Answer:
          "You can join by creating an account on StudyBuddy and navigating to the community section to register for discussions and forums.",
      },
      {
        Question: "What types of forums are available for discussion?",
        Answer:
          "Forums cover various topics, including teaching strategies, subject-specific discussions, technology in education, and general student support.",
      },
      {
        Question: "How can I participate in workshops offered by StudyBuddy?",
        Answer:
          "Workshops can be accessed through the community section, where you can register for upcoming sessions and participate in live discussions.",
      },
      {
        Question: "Are there events or meetups for users?",
        Answer:
          "Yes, StudyBuddy organizes virtual and in-person events, including webinars and meetups, for networking and collaboration.",
      },
      {
        Question: "How can I share my experiences with other users?",
        Answer:
          "You can share experiences by posting in discussion forums, participating in community events, or contributing to user-generated content.",
      },
      {
        Question: "Can I ask questions and receive answers from experts?",
        Answer:
          "Yes, community forums allow you to post questions, and you can receive responses from experienced educators and experts in the field.",
      },
      {
        Question: "What resources are available for networking with educators?",
        Answer:
          "StudyBuddy offers forums, events, and groups dedicated to networking, allowing users to connect with other educators and professionals.",
      },
      {
        Question: "How do I contribute to community discussions?",
        Answer:
          "You can contribute by posting your thoughts, responding to others' posts, and engaging in conversations on relevant topics.",
      },
      {
        Question:
          "Are there guidelines for respectful communication in forums?",
        Answer:
          "Yes, there are community guidelines emphasizing respectful and constructive communication, promoting a positive environment for all users.",
      },
      {
        Question: "What is the purpose of community guidelines?",
        Answer:
          "Community guidelines ensure a respectful and constructive environment for discussions and interactions among users.",
      },
      {
        Question: "How can I suggest topics for community discussions?",
        Answer:
          "You can suggest topics by posting in the community forums or directly messaging moderators or community leaders.",
      },
      {
        Question:
          "What should I do if I encounter inappropriate content in forums?",
        Answer:
          "If you encounter inappropriate content, report it to the moderators or use the reporting feature available in the forum.",
      },
      {
        Question: "Are there success stories shared within the community?",
        Answer:
          "Yes, community members often share success stories and testimonials that highlight effective practices and positive experiences.",
      },
      {
        Question: "How do I stay updated on community events and activities?",
        Answer:
          "You can stay updated by checking the community section regularly, subscribing to newsletters, or enabling notifications for new events.",
      },
      {
        Question:
          "What types of feedback are encouraged from community members?",
        Answer:
          "Constructive feedback on discussions, workshops, and platform features is encouraged to improve the community and user experience.",
      },
      {
        Question:
          "Can I collaborate with others on projects through the community?",
        Answer:
          "Yes, the community encourages collaboration on projects, allowing users to form teams and work together on educational initiatives.",
      },
      {
        Question: "Are there platforms for peer reviews of assignments?",
        Answer:
          "Yes, StudyBuddy may have sections dedicated to peer reviews, where users can give and receive feedback on assignments.",
      },
      {
        Question: "How do I report issues or concerns within the community?",
        Answer:
          "Issues or concerns can be reported to community moderators or through a dedicated reporting system within the platform.",
      },
      {
        Question: "Can I create my own discussion threads?",
        Answer:
          "Yes, users can create new discussion threads on relevant topics, fostering conversation and collaboration.",
      },
      {
        Question:
          "How can I support other community members in their learning?",
        Answer:
          "You can support others by sharing resources, answering questions, providing encouragement, and participating actively in discussions.",
      },
    ],

    feedbackSuggestion: [
      {
        Question: "How do I submit feedback about StudyBuddy?",
        Answer:
          "You can submit feedback through the app or website by using the feedback form or the designated feedback section.",
      },
      {
        Question: "Where can I suggest new features for the platform?",
        Answer:
          "Feature suggestions can be made through the feedback form or community forum available on the StudyBuddy platform.",
      },
      {
        Question: "How do I report bugs or technical issues?",
        Answer:
          "Report bugs or technical issues by filling out the bug report section in the app or website, detailing the problem and steps to reproduce it.",
      },
      {
        Question: "Can I request a feature update?",
        Answer:
          "Yes, you can request a feature update by submitting your request through the feedback section.",
      },
      {
        Question: "How are user feedback and suggestions handled?",
        Answer:
          "User feedback is collected, reviewed by the development team, and prioritized based on impact and feasibility for future updates.",
      },
      {
        Question: "How long does it take to get a response to my feedback?",
        Answer:
          "Response times can vary, but typically you can expect an acknowledgment within a few business days.",
      },
      {
        Question: "How can I track the status of my feedback submission?",
        Answer:
          "You may receive updates via email regarding the status of your feedback or can check back in the feedback section for updates.",
      },
      {
        Question: "Can I provide feedback on specific courses or modules?",
        Answer:
          "Yes, you can provide feedback on specific courses or modules through the course feedback option within each course.",
      },
      {
        Question:
          "What kind of feedback is most helpful to the StudyBuddy team?",
        Answer:
          "Specific, constructive feedback that includes details about your experience and suggestions for improvement is most helpful.",
      },
      {
        Question: "How can I get involved in testing new features?",
        Answer:
          "You can express your interest in testing new features through the feedback form or by joining the community forum for beta testers.",
      },
      {
        Question: "Is there a community forum for feedback and ideas?",
        Answer:
          "Yes, StudyBuddy has a community forum where users can discuss ideas, share feedback, and collaborate on suggestions.",
      },
      {
        Question: "How does StudyBuddy handle feature requests from users?",
        Answer:
          "Feature requests are reviewed by the development team, and those that align with user needs and the platform's goals are prioritized for implementation.",
      },
      {
        Question: "Are there any feedback surveys available for users?",
        Answer:
          "Yes, periodic feedback surveys are conducted to gather insights and suggestions from users.",
      },
      {
        Question: "How can I contact support for immediate concerns?",
        Answer:
          "For immediate concerns, you can contact customer support through the help section, email, or live chat feature if available.",
      },
      {
        Question: "Can I suggest changes to the platform layout?",
        Answer:
          "Yes, you can suggest layout changes through the feedback submission form, providing specific details on your suggestions.",
      },
      {
        Question: "What happens to my feedback after submission?",
        Answer:
          "After submission, your feedback is reviewed and categorized for further analysis by the development team.",
      },
      {
        Question: "How can I report a recurring issue on the platform?",
        Answer:
          "Report recurring issues by submitting detailed information through the bug report section, including how often it occurs and steps to reproduce it.",
      },
      {
        Question: "What channels can I use to provide feedback?",
        Answer:
          "You can provide feedback through the app, website forms, community forums, email, or support chat.",
      },
      {
        Question: "How often does StudyBuddy review user suggestions?",
        Answer:
          "User suggestions are reviewed regularly, typically during scheduled development meetings.",
      },
      {
        Question: "Can I request a demo of new features before they are live?",
        Answer:
          "You may request a demo of new features through the feedback form, especially if you’re interested in beta testing or preview opportunities.",
      },
    ],
    mobileApp: [
      {
        Question: "How do I download the StudyBuddy mobile app?",
        Answer:
          "To download the StudyBuddy mobile app, go to the App Store for iOS devices or Google Play Store for Android devices. Search for 'StudyBuddy,' and click 'Install' to download it.",
      },
      {
        Question: "What features are available on the mobile app?",
        Answer:
          "The mobile app offers key features such as accessing courses, submitting assignments, receiving notifications, viewing grades, and joining live classes or webinars.",
      },
      {
        Question: "How do I navigate the mobile app interface?",
        Answer:
          "The app's interface is simple. You can use the menu to access courses, assignments, notifications, and your profile. Each section is clearly labeled to help you find what you need.",
      },
      {
        Question: "Can I sync my progress across devices?",
        Answer:
          "Yes, your progress will automatically sync when you log in on different devices using the same account.",
      },
      {
        Question: "How do I enable notifications on the mobile app?",
        Answer:
          "To enable notifications, go to your phone's settings, find the StudyBuddy app, and allow notifications. You will receive alerts about new assignments, grades, and updates.",
      },
      {
        Question: "Can I access all courses and materials on the mobile app?",
        Answer:
          "Yes, you can access all your courses and materials through the mobile app. However, certain advanced features may be easier to use on a desktop.",
      },
      {
        Question: "How do I update the app to the latest version?",
        Answer:
          "To update the app, visit the App Store or Google Play Store, search for StudyBuddy, and click the 'Update' button if a new version is available.",
      },
      {
        Question: "What should I do if the app crashes or freezes?",
        Answer:
          "If the app crashes, close it and restart. If the problem continues, try reinstalling the app or check if your phone's system needs updating.",
      },
      {
        Question: "How do I submit assignments through the mobile app?",
        Answer:
          "To submit assignments, go to your course in the app, find the assignment section, and upload your work following the instructions provided.",
      },
      {
        Question: "How can I manage storage space for the app?",
        Answer:
          "To manage storage, you can delete unnecessary downloaded files or clear the app's cache from your phone's settings.",
      },
      {
        Question: "Is there an offline mode for the mobile app?",
        Answer:
          "Currently, the app does not support offline mode. You need an internet connection to access your courses and materials.",
      },
      {
        Question: "How do I view grades and feedback on the mobile app?",
        Answer:
          "You can view your grades and feedback in the 'Grades' section of each course. This will show any comments or scores given by your teacher.",
      },
      {
        Question: "What are the system requirements for the mobile app?",
        Answer:
          "The app requires iOS 11.0 or later for Apple devices and Android 6.0 or later for Android devices.",
      },
      {
        Question: "How do I log out of the mobile app?",
        Answer:
          "To log out, go to your profile section, and click on the 'Log out' option.",
      },
      {
        Question: "Can I access the community features on the mobile app?",
        Answer:
          "Yes, you can join discussions, ask questions, and engage with the community through the app.",
      },
      {
        Question: "How do I join a live class or webinar through the app?",
        Answer:
          "To join a live class, go to the course page and click on the scheduled live session. You’ll automatically be directed to the live stream.",
      },
      {
        Question:
          "Are there any differences between the mobile and desktop versions?",
        Answer:
          "The mobile app is designed for convenience on-the-go, but some features like in-depth analytics or larger file uploads may be easier to access on the desktop version.",
      },
      {
        Question:
          "How can I troubleshoot connectivity issues on the mobile app?",
        Answer:
          "If you face connectivity issues, check your internet connection, restart the app, or reinstall it if necessary.",
      },
      {
        Question: "Can I use the mobile app in different languages?",
        Answer:
          "Yes, the mobile app supports multiple languages. You can change the language in the app settings.",
      },
      {
        Question: "What support is available for mobile app users?",
        Answer:
          "For support, you can access the help section in the app, visit the website's support page, or contact customer service directly.",
      },
    ],
  };
  const screenType = useSelector((state) => state.website.screenType);  // State to track the selected category
  const [selectedCategory, setSelectedCategory] = useState(
    Object.keys(faqData)[0]
  );
  const [hasAnimated, setHasAnimated] = useState(false); // Track if animation has run
  const containerLeftRef = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const containerRightRef = useRef(null);
  // State to track the active FAQ question for each category
  const [activeIndex, setActiveIndex] = useState({});

  const toggleAccordion = (index) => {
    setActiveIndex((prevState) => ({
      ...prevState,
      [selectedCategory]: prevState[selectedCategory] === index ? null : index,
    }));
  };
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasAnimated) {
          // Check if not animated yet
          setIsInView(true);
          setHasAnimated(true); // Set animated to true after first animation
        }
      });
    });

    if (containerLeftRef.current) observer.observe(containerLeftRef.current);
    if (containerRightRef.current) observer.observe(containerRightRef.current);

    return () => {
      if (containerLeftRef.current)
        observer.unobserve(containerLeftRef.current);
      if (containerRightRef.current)
        observer.unobserve(containerRightRef.current);
    };
  }, [hasAnimated]); // Add hasAnimated as a dependency

  
  const styles = {
    container: {
      display: "flex",
      // maxWidth: "90vw",
      backgroundColor: "red",
      fontFamily: "'Sora', sans-serif",
      width: "95vw",
      marginLeft: "5vw",
    },
    sidebar: {
      width: screenType==="tab" ? "20vw" : "20vw",
      backgroundColor: "white", // marginTop: "14px",
      padding: "20px",
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
      marginRight: "20px",
      fontSize: screenType==="smallPhone" || screenType==="phone" ? "10px" : "",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      height: screenType==="smallPhone" || screenType==="phone" ? "80vh" : "70vh", // Fixed height to allow scrolling
      overflowY: "auto", // Enables vertical scrolling
    },
    sidebarItem: {
      marginBottom: "10px",
      cursor: "pointer",
      marginLeft: screenType==="smallPhone" || screenType==="phone" ? "30px" : "",
      padding: "10px",

      backgroundColor: "#fff",
      borderRadius: "6px",
      textAlign: "center",
      boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
      marginTop: "20px",
      height: screenType==="smallPhone" ? "6vh" : "",
         textTransform:"capitalize"
    },
    sidebarItemActive: {
      backgroundColor: "#007bff",
      color: "#fff",
    },
    faqContentContainer: {
      // flexGrow: 1,

      height: screenType==="smallPhone" || screenType==="phone" ? "80vh" : "76vh", // Fixed height to allow scrolling
      overflowY: "scroll", // Enables vertical scrolling
      width: screenType==="phone" || screenType==="smallPhone" ? "" : "80vw",
    },
    faqContent: {
      // flexGrow: 1,
      paddingRight: screenType==="tab" || screenType==="tablet" ? "" : "10px",
      // paddingTop : screenType==="tab"||screenType==="tablet" ? "10px": "2%",
      // width:screenType==="smallPhone"||screenType==="phone"? "100vw":""
    },
    heading: {
      fontSize: "28px",
      textAlign: "center",
      marginBottom: "20px",
      fontFamily: "raleway",
    },
    accordionItem: {
      marginBottom: "16px",
      border: "1px solid #fcfcfc",
      borderRadius: "6px",
      marginRight: screenType==="phone" || screenType==="smallPhone" ? "" : screenType==="tab" || screenType==="tablet" ? "2vw" : "",
      backgroundColor: "#ffffff",
      boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
    },
    accordionQuestion: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "15px",
      cursor: "pointer",
      padding: "14px 20px",
      fontFamily: "raleway",
    },
    accordionDesc: {
      display: "none",
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 300,
      color: "#444",
      borderTop: "1px dashed #ddd",
      padding: "10px 20px",
    },
    accordionDescOpen: {
      display: "block",
      fontFamily: "raleway",
    },
    icon: {
      fontSize: "20px",
    },
    LeftContainer: {
      width: screenType==="phone" || screenType==="smallPhone" ? "" : "50vw",

      transform: isInView ? "translateY(0)" : "translateY(-100%)",
      opacity: isInView ? 1 : 0,
      transition: "transform 1s ease-in-out, opacity 1s ease-in-out",
      borderRadius: "10px",
      display: "flex",
      alignItems: "center",
    },
    RightContainer: {
      // height:"50vh",

      width: screenType==="phone" || screenType==="smallPhone" ? "" : "50Vw",
      transform: isInView ? "translateX(0)" : "translateX(-100%)",
      opacity: isInView ? 1 : 0,
      transition: "transform 1s ease-in-out, opacity 1s ease-in-out",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        margin:"2vw auto",
    
      }}
    >
      <div
        style={{
          marginTop: "50px",
          marginLeft: screenType==="phone" || screenType==="smallPhone" ? "" : "3vw",
          height: screenType==="tab" || screenType==="tablet" ? "50vw" : screenType==="phone" || screenType==="smallPhone" ? "" : "60vh",
          width: "95vw",
          margin: "2vw auto",
          marginLeft: screenType==="smallPhone" || screenType==="phone" ? "2vw" : "",
          flexDirection: screenType==="smallPhone" || screenType==="phone" ? "column" : "",
          backgroundColor: "white",
          display: "flex",
          borderRadius: "10px",
          boxShadow: "20px 20px 25px rgba(0, 0, 0, 0.3)",
          overflow: "hidden",
              padding: "15px",
         
        }}
      >
        <div ref={containerLeftRef} style={styles.LeftContainer}>
          <p
            style={{
              fontSize: "30px",
              marginLeft: "20px",
              marginTop: "50px",
              fontFamily: "raleway",
            }}
          >
            "Your Questions, Answered – Explore Our Resources and Find Clarity
            on Every Step of Your Journey."
          </p>
        </div>
        <div ref={containerRightRef} style={styles.RightContainer}>
          <img
            src={require("../../assets/WebsiteImages/FAQ.png")}
            style={{
              height: "100%",
              objectFit: "contain",
              width: "100%",
            }}
          />
        </div>
      </div>
      <div>
        <h1 style={styles.heading}>{selectedCategory} FAQs</h1>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: screenType==="smallPhone" || screenType==="phone" ? "column" : "",
          margin: "2vw auto",
          backgroundColor: "ButtonFace",
          borderRadius: "10px",
          width: "95vw",
          padding: screenType==="tab" || screenType==="tablet" ? "10px" : "10px",
          overflow: "hidden",
        }}
      >
        {!(screenType==="smallPhone" || screenType==="phone") ? (
          <div style={styles.sidebar}>
            <div>
              {Object.keys(faqData).map((category) => (
                <div
                  key={category}
                  style={{
                    ...styles.sidebarItem,
                    ...(selectedCategory === category
                      ? styles.sidebarItemActive
                      : {}),
                  }}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              width: screenType==="smallPhone" || screenType==="phone" ? "76vw" : "96vw",
              overflowX: "scroll",
              height: screenType==="smallPhone" ||screenType==="phone"? "" : "8vh",
              marginBottom: screenType==="smallPhone" ? "30px" : "",
              margin: "0 auto",
              textTransform:"capitalize"
            }}
          >
            {Object.keys(faqData).map((category) => (
              <div
                key={category}
                style={{
                  ...styles.sidebarItem,
                  ...(selectedCategory === category
                    ? styles.sidebarItemActive
                    : {}),
                }}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </div>
            ))}
          </div>
        )}
        {/* FAQ Content */}
        <div style={styles.faqContentContainer}>
          <div style={styles.faqContent}>
            {faqData[selectedCategory] ? (
              faqData[selectedCategory].map((item, index) => (
                <div key={index} style={styles.accordionItem}>
                  <div
                    style={styles.accordionQuestion}
                    onClick={() => toggleAccordion(index)}
                  >
                    {item.Question}
                    <span style={styles.icon}>
                      {activeIndex[selectedCategory] === index ? "-" : "+"}
                    </span>
                  </div>
                  <div
                    style={{
                      ...styles.accordionDesc,
                      ...(activeIndex[selectedCategory] === index &&
                        styles.accordionDescOpen),
                    }}
                  >
                    {item.Answer}
                  </div>
                </div>
              ))
            ) : (
              <p>No FAQs available for this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FAQQuestions;
