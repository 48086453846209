import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className="about">
      <h1>About Us</h1>
      <p>
        Welcome to the About page. Here you will find information about our company, mission, and values. We are committed to providing the best services and ensuring customer satisfaction. Our team is dedicated to excellence and innovation.
      </p>
      <p>
        Our company has been serving clients for over a decade, and we continue to strive for improvement and growth. Thank you for your interest in our company. If you have any questions, feel free to reach out to us through the contact page.
      </p>
      <h1>About Us</h1>
      <p>
        Welcome to the About page. Here you will find information about our company, mission, and values. We are committed to providing the best services and ensuring customer satisfaction. Our team is dedicated to excellence and innovation.
      </p>
    
      
    </div>
  );
};

export default About;
