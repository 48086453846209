import React, { useEffect, useRef, useState } from 'react';
import useScreenSizes from '../../utils/useScreenSize';
import { motion } from 'framer-motion';
import PhoneGraphics from "../../assets/Animations/mobile animation for studybuddy app.mp4";
import { useSelector } from 'react-redux';

// Custom hook for typing effect
const useTypingEffect = (text, speed = 100, pause = 1000) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let timer;
    if (isDeleting) {
      if (index > 0) {
        timer = setTimeout(() => {
          setDisplayedText((prev) => prev.slice(0, -1));
          setIndex((prev) => prev - 1);
        }, speed);
      } 
    } else {
      if (index < text.length) {
        timer = setTimeout(() => {
          setDisplayedText((prev) => prev + text[index]);
          setIndex((prev) => prev + 1);
        }, speed);
      } 
    }

    return () => clearTimeout(timer);
  }, [index, isDeleting, text, speed, pause]);

  return displayedText;
};

const StudybuddyGraphics = () => {
  const [isInView, setIsInView] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const containerLeftRef = useRef(null);
  const containerRightRef = useRef(null);
  
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  const screenType = useSelector((state) => state.website.screenType);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasAnimated) {
          setIsInView(true);
          setHasAnimated(true);
        }
      });
    });
   
    const useTypingEffect = (text, speed = 100) => {
      const [displayedText, setDisplayedText] = useState('');
      const [index, setIndex] = useState(0);
    
      useEffect(() => {
        let timer;
        if (index < text.length) {
          timer = setTimeout(() => {
            setDisplayedText((prev) => prev + text[index]);
            setIndex((prev) => prev + 1);
          }, speed);
        }
        return () => clearTimeout(timer);
      }, [index, text, speed]);
    
      return displayedText;
    };
    
    if (containerLeftRef.current) observer.observe(containerLeftRef.current);
    if (containerRightRef.current) observer.observe(containerRightRef.current);

    return () => {
      if (containerLeftRef.current) observer.unobserve(containerLeftRef.current);
      if (containerRightRef.current) observer.unobserve(containerRightRef.current);
    };
  }, [hasAnimated]);






  const styles = {
    listContainer: {
      display: 'flex',

      // alignItems: 'center',
      justifyContent:"center",
      marginTop: screenType==="smallPhone"||screenType==="phone"? "":"50px",      gap: '20px',
    },
    fixedText: {
      fontSize: screenType==="smallPhone"||screenType==="phone" ? '20px' : '28px',
      fontFamily: '',
      color: 'black',
    },
    animatedText: {
      fontSize: screenType==="smallPhone" ? '20px' : '34px',
      fontFamily: '',
      color: 'black',
    },
  };

  const containerStyle = {
    width: screenType==="smallPhone"||screenType==="phone" ? '90vw' : '80vw',
    height: screenType==="smallPhone"||screenType==="phone" ? '' : screenType==="tab"||screenType==="tablet"?"50vw" : '60vh',
    opacity: isInView ? 1 : 0,
    transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
    position: 'relative',
    borderRadius: '10px',
    margin: '10px ',
    // overflow: "hidden",
  
  };

  const containerWrapperStyle = {
    display: 'flex',
    flexDirection: screenType==="smallPhone"||screenType==="phone" ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // overflow: "hidden",
    
  };

  const leftContainerStyle = {
    width: screenType==="smallPhone"||screenType==="phone" ? '90vw' : '60vw',
    height: screenType==="smallPhone"||screenType==="phone" ? '50%' : screenType==="tab"||screenType==="tablet"  ? "": '60vh',
    opacity: isInView ? 1 : 0,
    transition: 'transform 0.1s ease-in-out, opacity 0.1s ease-in-out',
    position: 'relative',
    borderRadius: '10px',
    margin: '10px',
    display:"flex",

    // overflow: "hidden",
    transform: isInView ? 'translateY(0)' : 'translateY(100%)',

  };

  const rightContainerStyle = {
    ...containerStyle,
    height: screenType==="smallPhone"||screenType==="phone" ? '' : screenType==="tab"||screenType==="tablet"  ? "": '60vh',    width: screenType==="smallPhone"||screenType==="phone" ? '90vw' : '60vw',
    transform: isInView ? 'translateY(0)' : 'translateY(100%)',
    display:"flex",
    alignItems:"end",
    justifyContent:"end"

  };
  const paragraph1 = "Your Journey to Success Starts";
  const paragraph2 = "Here.";
  const paragraph3 = "Let’s Achieve Together!";
  
  // Applying typing effect to each paragraph
  const animatedText1 = useTypingEffect(paragraph1);
  const animatedText2 = useTypingEffect(paragraph2);
  const animatedText3 = useTypingEffect(paragraph3);
  const [currentParagraph, setCurrentParagraph] = useState(1);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentParagraph(2); // Show second paragraph after the first
    }, animatedText1.length * 50000); // Delay based on the length of the first text

    return () => clearTimeout(timer);
  }, [animatedText1]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentParagraph(3); // Show third paragraph after the second
    }, animatedText2.length * 10000); // Delay based on the length of the second text

    return () => clearTimeout(timer);
  }, [animatedText2]);

  return (
    <div style={{ width: "95%", overflow: screenType==="smallPhone"||screenType==="phone" ?"": "hidden", backgroundColor: "#ffffff" ,
      borderRadius:"10px",
      boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
   
      margin:"5vh auto",
      padding:"15px",
      display: screenType==="smallPhone"||screenType==="phone"? "flex":"",
      height: screenType==="smallPhone"||screenType==="phone" ? "":screenType==="tablet"? "35vw": screenType==="tab" ? "55vh":"60vh",
      position:"relative",
       fontFamily:"raleway",
      //  backgroundImage:require("../../assets/images/footer.jpg"),
       opacity:1,
    }}>
        <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: `url(${require("../../assets/images/footer.jpg")})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      opacity: 0.4, // Set the opacity of the image
      zIndex: 0, // Ensure it stays behind the content
    }}
  ></div>

      <div style={containerWrapperStyle}>
        
      <motion.div
          ref={containerLeftRef}
          style={leftContainerStyle}
          animate={{ x: [" 10%", "0%", "10%"] }} // Loop from 0% to 100% back to 0%
          transition={{
            duration: 5, // Adjust speed of animation
            ease: "easeInOut",
            repeat: Infinity, // Infinite loop
          }}
        >
          <div style={styles.listContainer}>
          <div style={styles.fixedText}>
            {/* Typing effect applied to individual <p> tags */}
            {currentParagraph >= 1 && <p>{animatedText1}</p>}
              {currentParagraph >= 2 &&  <p style={{ marginLeft: "150px" }}>{animatedText2}</p>}
              {currentParagraph >= 3 &&   <p style={{ marginLeft: "0%" }}>{animatedText3}</p>}
            
          
         
          </div>
            <div style={{
              position:"absolute",
              top: screenType==="tablet" ? "66%": screenType==="tab"? "44vh": "64%",
              right:"10%"
            }}>
              <img src={require("../../assets/WebsiteImages/rb_37825.png")}  style={{
                height:"10vw"
              }}/>
            </div>
          </div>
       
        </motion.div>
        <div ref={containerRightRef} style={rightContainerStyle}>
          <video
            src={PhoneGraphics}
            autoPlay
            loop
            muted
            style={{ width: '100%', borderRadius: '10px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default StudybuddyGraphics;
