import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import About from '../About/About';
import LoginPage from '../loginPage/loginPage';
import HomePage from '../Home/HomePage';
import DefaultScreen from '../loginPage/DefaultScreen';
import Logout from '../loginPage/logout';
import Solutios from '../Solution/ERPWEBSiTE';
import PricingCard from '../Pricing/PricingContainer';
import FAQQuestions from '../FAQ/FAQ';
import VisionContainer from '../Vision/Vision';


import ContactUs from '../../Screens/Info/contact';
import Info from '../Info/info';
import Policy from '../Policies/Policies';
import NotFound from '../../Components/test/Error';
import LMS from '../LMS/LMS';
const MainContentArea = () => {
  const location = useLocation();
  const token= useSelector((state) => state.auth.token);
  const reach = useSelector((state) => state.website.reach);
 
  const containersRef = useRef(null);
  const styles = {
    MainContentArea: {
      minHeight: "100vh",
      // display:"flex",
      backgroundColor: '#c7985f',
      width: "100vw",
     
     
    
    },
  };
  useEffect(() => {
    // Scroll the `containersRef` div to the top on route change
    if (containersRef.current) {
      containersRef.current.scrollTo(0, 0);
    }
  }, [location.pathname]); 
  useEffect(() => {
    // Scroll the `containersRef` div to the top on route change
    if (containersRef.current) {
      containersRef.current.scrollTo(0, 0);
    }
  }, [location.state]); 
  return (
    <div  ref={containersRef} style={styles.MainContentArea}>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/logout' element={<Logout />} />
        <Route path="/about" element={<About />} />
        <Route path="/solution" element={<Solutios/>} />
        <Route path="/pricing" element={<PricingCard/>} />
        <Route path="/FAQ" element={<FAQQuestions/>} />
        <Route path="/vision" element={<VisionContainer/>} />
        <Route path="/LMS" element={token ? <LMS/> : <LoginPage />} />
        <Route path='/forStudent' element={<HomePage/>} />
        <Route path='/forParent' element={<HomePage/>} />
        <Route path='/forTeacher' element={<HomePage/>} />
        <Route path='/forInstitute' element={<HomePage/>} />
        <Route path='/info' element={<Info/>} />
        <Route path='/policies' element={<Policy/>} />
        <Route path='/contactus' element={<ContactUs/>} />
        <Route path='/LMS' element={<LMS/>} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </div>
  );
};

export default MainContentArea;
