
import logo from "../assets/images/logo.png"
import springtree from "../assets/images/spriningseasontree.jpg"
const themes = {
  default: {
    logo:  logo||'url(https://firebasestorage.googleapis.com/v0/b/studybuddy-d48bb.appspot.com/o/StudyBuddyLogo.png?alt=media&token=68e7d070-f946-432f-8a39-67183d80ceb6)', // Set the path to your image here
    soilColor: '#8B4513', // Brown
    treeColor: '#228B22', // Forest green
    textColor: '#000000', // Black
  },
  dark: {
    logo: springtree||'url(../../../../assets/images/logo.png)', // Set the path to your image here
    soilColor: '#8B4513', // Brown
    treeColor: '#16A085', // Teal green
    textColor: '#FFFFFF', // White
  },
  forest: {
    backgroundImage: null, // You can set an image if needed
    soilColor: '#3B2C20', // Dark brown
    treeColor: '#A3C13A', // Light green
    textColor: '#1C1C1C', // Dark gray
  },
  // Add more themes as needed
};

export default themes;
