import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPath: '/',  
};

const pathSlice = createSlice({
  name: 'path',
  initialState,
  reducers: {
    setPath: (state, action) => {
      state.currentPath = action.payload;
    },
  },
});

// Export actions to use in your components
export const { setPath } = pathSlice.actions;

// Export the reducer to add to the Redux store
export default pathSlice.reducer;
