import React from 'react'
import { Button } from "@mui/material";
import {clearAuth, logout} from "../../reduxStore/slices/authSlice"
import { useDispatch } from "react-redux";
function LMS() {
  const dispatch =useDispatch();
  const handlelogout =()=>{
    dispatch(clearAuth()
  )
  console.log('clearAuth',clearAuth)
  }
  return (
    <div>
      <p>
         nb vbhv 
      </p>
      <div>
        <Button onClick={handlelogout}>LogOut</Button>
      </div>
    </div>
  )
}

export default LMS
