import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, PERSIST, PAUSE, REHYDRATE, FLUSH, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from '../reduxStore/slices/authSlice';
import websiteReducer from './slices/WebSiteData';
import pathReducer from './slices/headerFixedSlice';
import { combineReducers } from 'redux';

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  website: websiteReducer,
  path: pathReducer,
});

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'website', 'path'], // Specify reducers to persist
};

// Wrap the root reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Persistor
export const persistor = persistStore(store);
export default store;
