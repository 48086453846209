import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import useScreenSizes from '../../utils/useScreenSize';



const LoopingImageAnimation = () => {
  const reach = useSelector((state) => state.website.reach);
  const screenType = useSelector((state) => state.website.screenType);
  const images =[

    { imagePath: redBird, style: { width: screenType==="phone" ?  20 :screenType==="smallPhone"?20 : screenType==="tab"? 30: screenType==="tablet"? 40: screenType==="desktop" ? 60:"", height:"auto" }, originEnd: 'left', verticalAlign:'top', duration:10000, zIndex:-1000 },
    { imagePath: flockOfBirds, style: { width: screenType==="phone" ?  20 :screenType==="smallPhone"?20 : screenType==="tab"? 30: screenType==="tablet"? 40:  100,  height:"auto"  }, originEnd: 'right', verticalAlign: 'top', duration: 5000 ,  },
    { imagePath: blackBird, style: {width: screenType==="phone" ?  20 :screenType==="smallPhone"?20 : screenType==="tab"? 30: screenType==="tablet"? 40:  60,   height:"auto"  }, originEnd: 'left', verticalAlign: 'top', duration: 3000 },
    { imagePath: wiseBird, style: { width: screenType==="phone" ?  20 :screenType==="smallPhone"?20 : screenType==="tab"? 30: screenType==="tablet"? 40:  60,  height:"auto"  }, originEnd: 'left', verticalAlign: 'top', duration: 6000 },
    { imagePath: ikgaiBird, style: {width:screenType==="phone" ?  20 :screenType==="smallPhone"?20 :screenType==="tab"? 30: screenType==="tablet"? 40:  60,  height:"auto"  }, originEnd: 'right', verticalAlign: 'top', duration: 4000 },
  ]
  
  const [currentImageIndex, setCurrentImageIndex] = useState(  
    images.length > 0 ? Math.floor(Math.random() * images.length) : 0
  );
  const [activeDirection, setActiveDirection] = useState('');
  const [verticalAlign, setVerticalAlign] = useState('');
  const [count, setCount] = useState(0);
  const headerHeight = useSelector((state) => state.website.headerHeight);


 

  useEffect(() => {
    if (!images.length) return; // Exit if images array is empty.

    const currentImage = images[currentImageIndex];
    if (!currentImage) return; // Protect against invalid currentImageIndex.

    setActiveDirection(
      currentImage.originEnd === 'left' ? 'leftToRight' : 'rightToLeft'
    );
    setVerticalAlign(currentImage.verticalAlign);

    const timer = setTimeout(() => {
      setActiveDirection('');
      setVerticalAlign('');
      let nextImageIndex;

      // Ensure the next image index is different from the current index
      do {
        nextImageIndex = Math.floor(Math.random() * images.length);
      } while (nextImageIndex === currentImageIndex);

      setCurrentImageIndex(nextImageIndex);
      setCount((prevCount) => prevCount + 1);
    }, currentImage.duration);

    return () => clearTimeout(timer);
  }, [count, currentImageIndex, images]);

  const renderAnimation = () => {
    if (!images.length) return null; // Exit if images array is empty.

    const currentImage = images[currentImageIndex];
    if (!currentImage) return null; // Protect against invalid currentImageIndex.

    const animationStyle =
      activeDirection === 'leftToRight'
        ? {
            initial: { x: '100%' },
            animate: { x: '100vw' },
            transition: { duration: currentImage.duration / 1000, ease: 'linear' },
          }
        : {
            initial: { x:  '100vw' },
            animate: { x: '-100%' },
            transition: { duration: currentImage.duration / 1000, ease: 'linear' },
          };


    const verticalAlignStyle =
      verticalAlign === 'center'
        ? {
            top: '50%',
            transform: 'translateY(-50%)', // Centers vertically
          }
        : verticalAlign === 'top'
        ? { top: 0 }
        : verticalAlign === 'bottom'
        ? { bottom: 0 }
        : {};

    return (
      <motion.img
        src={currentImage.imagePath}
        style={{
          position: 'absolute',
          ...currentImage.style,
          ...verticalAlignStyle,
          zIndex:-1000,
        }}
        {...animationStyle}
      />
    );
  };

  return (
    <div
      style={{
        // position: 'absolute',
        // width: '100%',
        zIndex:1,

        height: headerHeight,
      }}
    >
      {activeDirection && renderAnimation()}
    </div>
  );
};

// Animation Items
const redBird = require('../../assets/Animations/redBird.gif');
const flockOfBirds = require('../../assets/Animations/flockBirds.gif');
const blackBird = require('../../assets/Animations/blackBird.gif');
const wiseBird = require('../../assets/Animations/wiseBird.gif');
const ikgaiBird = require('../../assets/Animations/ikgai-bird.gif');

const animationItems = [
];


const App = () => {
  return (
  
      <LoopingImageAnimation images={animationItems}  />
  );
};

export default App;
