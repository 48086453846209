import React, { useState, useCallback } from 'react';
import { Home, RotateCcw, Volume2, VolumeX, Rocket, Star, Moon } from 'lucide-react';

const styles = {
  container: {
    minHeight: '100vh',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    overflow: 'hidden',
    // backgroundColor:"white"
  },
  audioButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    zIndex: 20,
    padding: '0.75rem',
    borderRadius: '9999px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    color: 'var(--text-primary)',
  },
  content: {
    maxWidth: '64rem',
    width: '100%',
    position: 'relative',
        backgroundColor:"white",
        borderRadius:"25px",
        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.2)", // Example of a soft, dark shadow

    zIndex: 10,
  },
  card: {
    backgroundColor: 'rgba(15, 23, 42, 0.4)',
    backdropFilter: 'blur(16px)',
    borderRadius: '1.5rem',
    padding: '2rem',
    textAlign: 'center',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    boxShadow: '0 25px 50px -12px rgba(147, 51, 234, 0.1)',
    animation: 'float 4s ease-in-out infinite',  },
  '@keyframes float': {
    '0%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(-10px)' },
    '100%': { transform: 'translateY(0)' },
  },
  '@keyframes rocketFloat': {
    '0%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(-15px)' },
    '100%': { transform: 'translateY(0)' },
  },
  title: {
    fontSize: '6rem',
    fontWeight: 'bold',
    background: 'linear-gradient(to right, #60a5fa, #a855f7, #ec4899)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    margin: '2rem 0',
  },
  subtitle: {
    fontSize: '3rem',
    fontWeight: 'bold',
    color: 'var(--text-primary)',
    margin: '1rem 0',
  },
  description: {
    fontSize: '1.25rem',
    color: 'var(--text-secondary)',
    maxWidth: '32rem',
    margin: '1rem auto',
  },
  buttonContainer: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2rem 0',
  },
  primaryButton: {
    padding: '1rem 2rem',
    background: 'linear-gradient(to right, var(--primary-color), var(--secondary-color))',
    color: 'var(--text-primary)',
    borderRadius: '9999px',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    border: 'none',
    cursor: 'pointer',
    transition: 'transform 0.3s, box-shadow 0.3s',
  },
  secondaryButton: {
    padding: '1rem 2rem',
    background: 'rgba(255, 255, 255, 0.1)',
    color: 'var(--text-primary)',
    borderRadius: '9999px',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    cursor: 'pointer',
    transition: 'transform 0.3s, background-color 0.3s',
  },
  missionStatus: {
    color: 'var(--text-secondary)',
    fontSize: '1.125rem',
    fontFamily: 'monospace',
  },
  astronautContainer: {
    position: 'relative',
    width: '16rem',
    height: '7rem',
    margin: '0 auto',
  },
  rocketContainer: {
    position: 'absolute',
    inset: 0,
    animation: 'rocketFloat 3s ease-in-out infinite', // Apply the new animation here
  },
  rocket: {
    position: 'relative',
    color: 'var(--text-primary)',
  },
  flame: {
    position: 'absolute',
    bottom: '2rem',
    left: '-0.5rem',
    width: '2rem',
    height: '4rem',
    background: 'linear-gradient(to top, #f97316, #facc15, transparent)',
    borderRadius: '9999px',
    filter: 'blur(4px)',
    animation: 'flame 1.5s ease-in-out infinite',
  },
  star: {
    position: 'absolute',
    color: '#fde047',
    animation: 'twinkle 3s ease-in-out infinite',
  },
  moon: {
    position: 'absolute',
    color: 'var(--text-secondary)',
    animation: 'pulse 4s ease-in-out infinite',
  },
 

};

const FloatingAstronaut = ({ scale = 1 }) => (
  <div style={styles.astronautContainer}>
    <div style={styles.rocketContainer}>
    <style>
        {`
          @keyframes rocketFloat {
            0% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0); }
          }
          .rocketFloat {
            animation: rocketFloat 3s ease-in-out infinite;
          }
        `}
      </style>
      <div style={{ ...styles.rocket, transform: `scale(${scale})` }}>
        <Rocket size={128} style={{ transform: 'rotate(-45deg)' }} strokeWidth={1.5} />
        <div style={styles.flame} />
      </div>
    </div>
    <Star size={32} style={{ ...styles.star, top: '-1rem', right: '1rem' }} />
    <Star size={24} style={{ ...styles.star, bottom: '4rem', left: '-1rem', animationDelay: '500ms' }} />
    <Moon size={48} style={{ ...styles.moon, top: '4rem', left: '-2rem' }} strokeWidth={1.5} />
  </div>
);

const NotFound = () => {
  const [isAudioEnabled, setIsAudioEnabled] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  const handleRocketClick = useCallback(() => {
    setClickCount((prev) => prev + 1);
    if (isAudioEnabled) {
      const audio = new Audio('https://www.soundjay.com/mechanical/sounds/robot-movement-1.mp3');
      audio.play().catch(() => {});
    }
  }, [isAudioEnabled]);

  return (
    <div style={styles.container}>
      

      <div style={styles.content}>
        <div style={styles.card}>
          <div onClick={handleRocketClick} style={{ cursor: 'pointer'  }}>
            <FloatingAstronaut scale={1 +clickCount * 0.01} />
          </div>

          <h1 style={styles.title}>404</h1>
          <h2 style={styles.subtitle}>Lost in Space</h2>
          <p style={styles.description}>
            {clickCount > 2
              ? "Warning: Rocket fuel reaching critical levels! 🚀"
              : "Houston, we have a problem! The page you're looking for has drifted into deep space."}
          </p>

          <div style={styles.buttonContainer}>
            <button onClick={() => (window.location.href = '/')} style={styles.primaryButton}>
              <Home size={20} />
              <span>Mission Control</span>
            </button>
            <button onClick={() => window.history.back()} style={styles.secondaryButton}>
              <RotateCcw size={20} />
              <span>Return to Orbit</span>
            </button>
          </div>

          <div style={styles.missionStatus}>
            <p>Mission Status: {clickCount > 15 ? '⚠️ Critical' : 'Page Not Found'}</p>
            <p>Error Code: 404 | Sector: {clickCount > 0 ? `Deep Space ${clickCount}` : 'Unknown'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
