
import { Button } from "@mui/material";
import {clearAuth,  setSideBar, } from "../../reduxStore/slices/authSlice"
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
const Logout = () => {
  const dispatch =useDispatch();

  
  const handlelogout =()=>{
  
    dispatch(setSideBar(false));  
        dispatch(clearAuth(),
   
  )
  }
  return (
    <div className="contact">
      <h1>Contact Us</h1>
      <p>hello succes </p>
      <div>
        <Button onClick={handlelogout}>LogOut</Button>
      </div>
    </div>
    
  );
};

export default Logout;
