import React from 'react';
import useScreenSizes from '../../utils/useScreenSize';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const SmartSchool = () => {
  // Data array for all management systems
  const Data = [
    {
      title: 'GPS-Enabled School Bus Tracking',
      explanation:
        'Ensuring Safety in Every Ride With Study Buddy’s GPS-enabled school bus tracking feature, parents can monitor the real-time location of their children’s school bus, ensuring peace of mind and heightened security. This system allows for live tracking, route alerts, and notifications, giving parents reassurance that their child has safely arrived at school or home.',
      features: [
        {
          title: 'Live Location Updates',
          content: 'With GPS tracking integrated into the Study Buddy app, parents can access live updates of their child’s school bus location.',
          image: require("../../assets/WebsiteImages/liveLocationUpdates (1)(1).png"),
        },
        {
          title: 'Route Alerts and Notifications',
          content: 'Parents receive instant alerts if there are changes in the bus route due to traffic, weather conditions, or other unexpected events.',
          image: require("../../assets/WebsiteImages/routeAlertAndNotifications (1).png"),
        },
        {
          title: 'Arrival and Departure Alerts',
          content: 'When the bus arrives at school or reaches home, parents receive a direct notification.',
          image: require("../../assets/WebsiteImages/arrivalAndDepartureAlerts.png"),
        },
      ],
    },
    {
      title: 'Biometric Attendance Systems',
      explanation:
        'Say goodbye to manual attendance errors with Study Buddy\'s biometric attendance system. Our app supports fingerprint and facial recognition technologies, making it effortless for schools to record accurate attendance. This feature saves time for teachers while ensuring parents are always informed about their child’s presence or absence with real-time updates.',
      features: [
        {
          title: 'Quick and Accurate Records',
          content: 'Biometric attendance uses unique physical characteristics, like fingerprints or facial recognition, ensuring accurate attendance.',
          image: require("../../assets/WebsiteImages/biometricIntergration.png"),
        },
        {
          title: 'Time-Saving for Teachers',
          content: 'With biometric technology, attendance is recorded instantly with just a scan, saving valuable class time.',
          image: require("../../assets/WebsiteImages/timeSavingForTeacher (1).png"),
        },
        {
          title: 'Instant Parent Notifications',
          content: 'Each time a student enters or exits the school, the system sends an immediate notification to parents.',
          image: require("../../assets/WebsiteImages/instantParentNotifications (1).png"),
        },
      ],
    },
    {
      title: 'Parent-Teacher Communication Portal',
      explanation:
        'Study Buddy’s communication portal allows for seamless interaction between parents and teachers. Through this dedicated platform, parents can receive updates on their child’s academic performance, attendance, and upcoming events, fostering better engagement and collaboration in their child’s education journey.',
      features: [
        {
          title: 'Real-Time Academic Updates',
          content: 'Parents can check on their child’s grades, assignments, and overall progress in real-time.',
          image: require("../../assets/WebsiteImages/realTimeAcademicUpdates (2).png"),
        },
        {
          title: 'Direct Communication with Teachers',
          content: 'Parents can directly message teachers with questions or concerns about their child’s education.',
          image: require("../../assets/WebsiteImages/directCommunicationWithTeachers (1)(1).png"),
        },
        {
          title: 'Event and Schedule Reminders',
          content: 'The portal provides reminders for upcoming events, such as parent-teacher meetings, exams, and extracurricular activities.',
          image: require("../../assets/WebsiteImages/eventAndSchduleReminders (2).png"),
        },
      ],
    },
    {
      title: 'E-library with Digital Resources',
      explanation:
        'Study Buddy provides access to an extensive e-library filled with e-books, research papers, and other digital learning resources. Students can dive into learning at their own pace, beyond school hours, ensuring they have all the tools they need to succeed from anywhere at any time.',
      features: [
        {
          title: '24/7 Access to Learning Materials',
          content: 'The e-library provides continuous access to educational resources, enabling students to study at their convenience.',
          image: require("../../assets/WebsiteImages/accessToLearningMaterials (1)(1).png"),
        },
        {
          title: 'Wide Range of Resources',
          content: 'The e-library is stocked with various materials such as e-books, research papers, study guides, and reference materials.',
          image: require("../../assets/WebsiteImages/wideRangeOfResource (1).png"),
        },
        {
          title: 'Downloadable Content for Offline Use',
          content: 'Students can download resources for offline access, making it possible to study even without internet access.',
          image: require("../../assets/WebsiteImages/dowanloadableConntentForOffline (1)(1).png"),
        },
      ],
    },
    {
      title: 'Smart Campus Security with CCTV Integration',
      explanation:
        'Study Buddy supports real-time CCTV monitoring, ensuring school campuses are safe and secure. Administrators and parents receive alerts in case of suspicious activity, providing an added layer of protection for students and staff alike.',
      features: [
        {
          title: 'Real-Time Monitoring',
          content: 'The CCTV integration within Study Buddy provides school staff and security personnel with a real-time view of key areas across the campus.',
          image: require("../../assets/WebsiteImages/realTimeMonitoring.png"),
        },
        {
          title: 'Alert System for Suspicious Activity',
          content: 'If any unusual or suspicious activity is detected, the system can automatically send alerts to administrators and staff.',
          image: require("../../assets/WebsiteImages/alertSystemForSuspiciousActivity.png"),
        },
        {
          title: 'Parental Access to Important Events',
          content: 'Parents can be given restricted access to live CCTV feeds for significant events such as sports meets or performances.',
          image: require("../../assets/WebsiteImages/parentalAccessToImportantEvents.png"),
        },
      ],
    },
    {
      title: 'Online Fee Payment System',
      explanation:
        'With Study Buddy’s secure online fee payment system, parents can conveniently pay school fees without the hassle of visiting the school or dealing with cash transactions. The digital platform ensures a streamlined and secure process, making fee management quick and easy.',
      features: [
        {
          title: 'Secure Digital Payments',
          content: 'The Study Buddy app ensures that all fee transactions are protected with encryption technology, keeping sensitive financial information safe.',
          image: require("../../assets/WebsiteImages/secureDigitalPayments.png"),
        },
        {
          title: 'Automated Receipts and Notifications',
          content: 'After each payment, the system generates a digital receipt and sends it to the parent, ensuring transparency.',
          image: require("../../assets/WebsiteImages/automatedRecepitsAndNotifications.png"),
        },
        {
          title: 'Flexible Payment Options',
          content: 'Parents can choose from various payment methods, including credit/debit cards, bank transfers, or UPI.',
          image: require("../../assets/WebsiteImages/flexiblePaymentsMethodsOptions.png"),
        },
      ],
    },
    {
      title: 'RFID Scanners for Student Access',
      explanation:
        'Study Buddy also supports RFID (Radio-Frequency Identification) scanners, ensuring that students’ entry and exit from the campus are recorded in real-time. This feature not only provides added security but also helps track student attendance and movement across school premises.',
      features: [
        {
          title: 'Automated Entry and Exit Logs',
          content: 'When students enter or exit the campus, their RFID tags automatically log the time and date, ensuring accurate records.',
          image: require("../../assets/WebsiteImages/automatedEntryAndExitLogs.png"),
        },
        {
          title: 'Enhanced Campus Security',
          content: 'Only students and staff with authorized RFID tags can access specific areas on campus, restricting access to sensitive locations.',
          image: require("../../assets/WebsiteImages/enhancedCampusSecurity (1).png"),
        },
        {
          title: 'Parental Access to Entry/Exit Records',
          content: 'Parents can monitor their child’s entry and exit times through the app, adding an additional layer of safety.',
          image: require("../../assets/WebsiteImages/parentalAccessEntryToExitRecords.png"),
        },
      ],
    },
  ];

  const screen = useScreenSizes();
  
  const [hoveredCardIndex, setHoveredCardIndex] = useState();
  const screenType = useSelector((state) => state.website.screenType);
  const styles = {
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingtop:  screenType==="smallPhone"||screenType==="phone"?"7vh" : '10vh', 
       },
    cardContainer: {
      display: 'flex',
      justifyContent: screenType==="smallPhone" || screenType==="phone" ? "center" : 'space-between',
      width: screenType==="smallPhone" || screenType==="phone" ? "100vw" : '95vw',
      flexDirection: screenType==="smallPhone" || screenType==="phone" ? "column" : "",
      height: screenType==="smallPhone" || screenType==="phone" ? "100vh" : '50vh',
      marginBottom: "25px"
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: screenType==="smallPhone" || screenType==="phone" ? "90vw" : '30%',
      backgroundColor: '#f0f0f0',
      borderRadius: '10px',
      overflowY: 'scroll',
      boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)",
      marginBottom: "25px",
      transition: 'transform 0.3s ease', // Smooth transition for scaling
    },
    cardHover: {
      transform: 'scale(1.05)', // Scale up the card on hover
    },
    cardImage: {
      height: '70%',
      overflow: 'hidden',
    },
    cardContent: {
      height: '30%',
      padding: '10px',
      backgroundColor: '#fff',
      textAlign: 'center',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    dataContainer: {
      marginTop: screenType==="smallPhone" ? "25vw":screenType==="phone"?"25vw" : screenType==="tab"? "15vw":screenType==="tablet"?"12vw": '2vw',  
      width: screenType==="smallPhone" || screenType==="phone" ? "98vw" : '95vw',
      padding: '20px',
      backgroundColor: '#fff',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
    },
  };

  return (
    <div style={styles.mainContainer}>
      {Data.map((system, systemIndex) => (
        <div key={systemIndex} style={styles.dataContainer}>
         <p style={{fontSize:"25px", fontWeight:"bold" ,}}>{system.title}</p>
         <div style={styles.cardContainer}>
            {system.features.map((feature, index) => (
              <div
                key={index}
                style={{
                  ...styles.card,
                  ...(hoveredCardIndex === index ? styles.cardHover : {}),
                }}
                onMouseEnter={() => setHoveredCardIndex(index)}
                onMouseLeave={() => setHoveredCardIndex(null)}
              >
                <div style={styles.cardImage}>
                  <img
                    src={feature.image} // Dynamic image loading
                    alt={feature.title}
                    style={styles.image}
                  />
                </div>
                <div style={styles.cardContent}>
                <p style={{fontSize:"20px", margin:"0px"}}>{feature.title}</p>
                                  <p>{feature.content}</p>
                </div>
              </div>
            ))}
          </div>
          <p>{system.explanation}</p>
        </div>
      ))}
    </div>
  );
};

export default SmartSchool;
