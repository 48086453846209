import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Ensure this is imported if using location.state

import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndPrivacy from "./TermOfUse";
import RefundPolicy from "./RefundPolicy";

const Policy = () => {
  const location = useLocation();
  const [activeComponent, setActiveComponent] = useState(null);

  // This will log the section from location.state
  console.log(location.state?.section);

  useEffect(() => {
    if (location.state) {
      const { section } = location.state;
console.log(location.state)
      if (section === 'PRIVACY POLICY') {
        setTimeout(() => {
          setActiveComponent('PRIVACY POLICY');
        }, 200);
      } else if (section === 'TERMS OF USE') {
        setTimeout(() => {
          setActiveComponent('TERMS OF USE');
        }, 200);
      } else if (section === 'REFUND POLICY') {
        setTimeout(() => {
          setActiveComponent('REFUND POLICY');
        }, 200);
      }
    }
  }, [location]);

  return (
    <div>
      {/* Render components based on the activeComponent state */}
      {activeComponent === 'PRIVACY POLICY' && <PrivacyPolicy />}
      {activeComponent === 'TERMS OF USE' && <TermsAndPrivacy/>}
      {activeComponent === 'REFUND POLICY' && <RefundPolicy/>} {/* Display 'Hello' for the CARRIER section */}
    </div>
  );
};

export default Policy;
