import React from "react";

const ContactUs = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.sectionTitle}>Contact Us</h1>
            
            <div style={styles.contactWrapper}>
                <div style={styles.contactInfo}>
                    <h2 style={styles.sectionTitle}>Get in Touch</h2>
                    
                    <div style={styles.contactInfoItem}>
                        <div style={styles.icon}>📍</div>
                        <div style={styles.infoContent}>
                            <h3 style={styles.infoTitle}>Address</h3>
                            <p style={styles.infoText}>123 Business Street, New York, NY 10001</p>
                        </div>
                    </div>

                    <div style={styles.contactInfoItem}>
                        <div style={styles.icon}>📞</div>
                        <div style={styles.infoContent}>
                            <h3 style={styles.infoTitle}>Phone</h3>
                            <p style={styles.infoText}>+1 (555) 123-4567</p>
                        </div>
                    </div>

                    <div style={styles.contactInfoItem}>
                        <div style={styles.icon}>✉️</div>
                        <div style={styles.infoContent}>
                            <h3 style={styles.infoTitle}>Email</h3>
                            <p style={styles.infoText}>info@yourcompany.com</p>
                        </div>
                    </div>

                    <div style={styles.socialLinks}>
                        <a href="#" style={styles.socialLink} title="Facebook">f</a>
                        <a href="#" style={styles.socialLink} title="Twitter">t</a>
                        <a href="#" style={styles.socialLink} title="LinkedIn">in</a>
                        <a href="#" style={styles.socialLink} title="Instagram">ig</a>
                    </div>
                </div>

                <div style={styles.contactForm}>
                    <h2 style={styles.sectionTitle}>Send Message</h2>
                    <form>
                        <div style={styles.formGroup}>
                            <label style={styles.label} htmlFor="name">Your Name</label>
                            <input style={styles.input} type="text" id="name" name="name" required />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label} htmlFor="email">Email Address</label>
                            <input style={styles.input} type="email" id="email" name="email" required />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label} htmlFor="subject">Subject</label>
                            <input style={styles.input} type="text" id="subject" name="subject" required />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label} htmlFor="message">Message</label>
                            <textarea style={styles.textarea} id="message" name="message" rows="5" required />
                        </div>

                        <button type="submit" style={styles.submitBtn}>Send Message</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "2rem",
    },
    contactWrapper: {
        display: "flex",
        flexWrap: "wrap",
        gap: "2rem",
        marginTop: "3rem",
    },
    contactInfo: {
        flex: 1,
        minWidth: "300px",
        background: "#fff",
        padding: "2rem",
        borderRadius: "10px",
        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
    },
    contactForm: {
        flex: 2,
        minWidth: "300px",
        background: "#fff",
        padding: "2rem",
        borderRadius: "10px",
        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
        animation: "fadeIn 0.6s ease-out forwards",
    },
    sectionTitle: {
        color: "#2c3e50",
        marginBottom: "1.5rem",
        fontSize: "2rem",
        position: "relative",
        paddingBottom: "0.5rem",
    },
    contactInfoItem: {
        display: "flex",
        alignItems: "center",
        marginBottom: "1.5rem",
    },
    icon: {
        width: "40px",
        height: "40px",
        background: "#3498db",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "1rem",
        color: "white",
    },
    infoContent: {},
    infoTitle: {
        color: "#2c3e50",
        fontSize: "1.1rem",
        marginBottom: "0.3rem",
    },
    infoText: {
        color: "#666",
    },
    formGroup: {
        marginBottom: "1.5rem",
    },
    label: {
        display: "block",
        marginBottom: "0.5rem",
        color: "#2c3e50",
        fontWeight: 500,
    },
    input: {
        width: "100%",
        padding: "0.8rem",
        border: "1px solid #ddd",
        borderRadius: "5px",
        fontSize: "1rem",
        transition: "border-color 0.3s ease",
    },
    textarea: {
        width: "100%",
        padding: "0.8rem",
        border: "1px solid #ddd",
        borderRadius: "5px",
        fontSize: "1rem",
        transition: "border-color 0.3s ease",
    },
    submitBtn: {
        background: "#3498db",
        color: "white",
        padding: "0.8rem 2rem",
        border: "none",
        borderRadius: "5px",
        fontSize: "1rem",
        cursor: "pointer",
        transition: "background-color 0.3s ease",
    },
    socialLinks: {
        marginTop: "2rem",
    },
    socialLink: {
        display: "inline-block",
        width: "35px",
        height: "35px",
        background: "#3498db",
        color: "white",
        borderRadius: "50%",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "0.5rem",
        textDecoration: "none",
        transition: "transform 0.3s ease",
    },
};

export default ContactUs;
