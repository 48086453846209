import React, { useEffect, useRef, useState } from "react";
import useScreenSizes from "../../utils/useScreenSize";
import { useSelector } from "react-redux";

const NEPcontainer = () => {
  const [isInView1, setIsInView1] = useState(false);
  const [isInView2, setIsInView2] = useState(false);
  const [isInView3, setIsInView3] = useState(false);
  const containerRef1 = useRef(null);
  const containerRef2 = useRef(null);
  const containerRef3 = useRef(null);
  const screenType = useSelector((state) => state.website.screenType);
  const handleIntersection = (entries, observer, setInView) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setInView(true);
        observer.unobserve(entry.target);
      }
    });
  };

  useEffect(() => {
    const observer1 =  new IntersectionObserver((entries) => handleIntersection(entries, observer1, setIsInView1), { threshold: screenType==="phone"||screenType==="smallPhone"? 0.3: 0.5 });
    const observer2 = new IntersectionObserver((entries) => handleIntersection(entries, observer2, setIsInView2), {threshold: screenType==="phone"||screenType==="smallPhone"? 0.3: 0.5 });
    const observer3 = new IntersectionObserver((entries) => handleIntersection(entries, observer3, setIsInView3), { threshold: screenType==="phone"||screenType==="smallPhone"? 0.3: 0.5  });

    if (containerRef1.current) observer1.observe(containerRef1.current);
    if (containerRef2.current) observer2.observe(containerRef2.current);
    if (containerRef3.current) observer3.observe(containerRef3.current);

    return () => {
      observer1.disconnect();
      observer2.disconnect();
      observer3.disconnect();
    };
  }, []);

  const screen = useScreenSizes();
  


  const item = [{ title: "What is NEP?", description: "The National Education Policy (NEP) is a comprehensive framework designed by the Government of India to transform and modernise the educational system. It aims to make education more flexible, inclusive, and aligned with the needs of a dynamic world. NEP promotes the development of critical thinking, creativity, and innovation among students, ensuring they are prepared for future challenges." }];
  const listItem= [
    { title: "Holistic and Multidisciplinary Learning", description: "Encourages a broader understanding of subjects, not just bookish knowledge" },
    { title: "Flexible Curriculums: ", description: "Offers students the freedom to choose their courses based on their interests." },
    { title: "Skill Development: ", description: "Promotes coding, digital literacy, and vocational skills from an early age." },
    { title: "Focus on Technology: ", description: "Emphasises the use of technology for teaching, assessment, and management." },
   
  ];
  const listItems = [
    { title: "Instant Notifications", description: "Get real-time updates about the latest decisions, guidelines, and changes related to NEP directly on the app. Stay informed without the hassle of checking multiple sources" },
    { title: "Holistic Progress Tracking: ", description: "Study Buddy doesn't just focus on academic performance. The app allows parents and teachers to track students’ overall growth—both academic and extracurricular—aligned with NEP’s emphasis on holistic development" },
    { title: "Multilingual Support for Regional Languages: ", description: "In line with NEP's push for education in the mother tongue, Study Buddy offers learning materials in multiple Indian languages to cater to diverse linguistic backgrounds" },
   
  ];

  const containerStyle = (isInView) => ({
    width: screenType==="phone" || screenType==="smallPhone" ? "95%" : "95vw",
    // height: screenType==="phone" || screenType==="smallPhone" ? "auto" : "70vh",
    opacity: isInView ? 1 : 0,
    transition: "opacity 0.6s ease-in-out",
    position: "relative",
    borderRadius: "12px",
    display:"flex",
    padding: " 40px 15px",
    margin:"5vw auto",
    // margin: screenType==="phone"||screenType==="smallPhone" ? "": "",
    marginTop: screenType==="phone"||screenType==="smallPhone" ? "30px":"",
    display: "flex",
    flexDirection: screenType==="phone"||screenType==="smallPhone" ? "column" : "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderRadius:"10px",
    // marginTop:"10%",
    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
     fontFamily:"raleway"
  });

  const leftContainerStyle = (isInView) => ({
    width: screenType==="phone" || screenType==="smallPhone" ? "100%" : "50%",
    borderRadius: "8px",
    display: "flex",
    marginRight: screenType==="phone" || screenType==="smallPhone" ? "0" : "40px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "15px",
    transform: isInView ? "translateX(0)" : "translateX(-100%)",
    transition: "transform 0.7s ease-in-out",
    boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)",
  });

  const rightContainerStyle = (isInView) => ({
    height: screenType==="phone" || screenType==="smallPhone" ? "auto" : "80%",
    width: screenType==="phone" || screenType==="smallPhone" ? "100%" : "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: isInView ? "translateX(0)" : "translateX(100%)",
    transition: "transform 0.7s ease-in-out",
  });

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <div ref={containerRef1} style={containerStyle(isInView1)}>
        <div style={rightContainerStyle(isInView1)}>
          <img
            src={require("../../assets/WebsiteImages/nepPolocy.png")}
            style={{
              width: "73%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
            alt="NEP Policy"
          />
        </div>
        <div style={leftContainerStyle(isInView1)}>
          {item.map((data, index) => (
            <React.Fragment key={index}>
              <h2 style={{ fontSize: screenType==="phone" ? "1.8rem" : "2.5rem", fontWeight: "bold", color: "#333", marginBottom: "15px" }}>
                {data.title}
              </h2>
              <p style={{ fontSize: "1.1rem", lineHeight: "1.6", color: "#555" }}>{data.description}</p>
            </React.Fragment>
          ))}
        </div>
      </div>

      <div ref={containerRef2} style={{ ...containerStyle(isInView2), flexDirection: screenType==="phone" || screenType==="smallPhone" ? "column-reverse" : "row" }}>
      <div style={leftContainerStyle(isInView2)}>
          <h2 style={{ fontSize: screenType==="phone" ? "1.8rem" : "2.5rem", fontWeight: "bold", color: "#333", marginBottom: "15px", padding: "10px" }}>
          How Does NEP Work?
          </h2>
          <ul style={{ paddingLeft: "1.2rem", marginTop: "1rem", listStyleType: "disc" }}>
            {listItem.map((item, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                <span style={{ fontSize: "1.2rem", fontWeight: "bold", color: "#333" }}>{item.title}</span>
                <span style={{ fontSize: "1.1rem", color: "#555", lineHeight: "1.5" }}> {item.description}</span>
              </li>
            ))}
          </ul>
        </div>
        <div style={rightContainerStyle(isInView2)}>
          <img
            src={require("../../assets/WebsiteImages/nepWork (1).png")}
            style={{
              height: screenType==="phone" || screenType==="smallPhone" ? "auto" : "100%",
              objectFit: "contain",
              width: screenType==="phone" || screenType==="smallPhone" ? "100%" : "90%",
              borderRadius: "8px",
              marginRight: "10px",
            }}
            alt="NEP Work"
          />
        </div>
      </div>

      <div ref={containerRef3} style={containerStyle(isInView3)}>
        <div style={rightContainerStyle(isInView3)}>
          <img
            src={require("../../assets/WebsiteImages/incorporatesNep (1).png")}
            style={{
              height: screenType==="phone" || screenType==="smallPhone" ? "auto" : "100%",
              width: "80%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
            alt="NEP"
          />
        </div>
        <div style={leftContainerStyle(isInView3)}>
          <h2 style={{ fontSize: screenType==="phone" ? "1.8rem" : "2.5rem", fontWeight: "bold", color: "#333", marginBottom: "15px", padding: "10px" }}>
            What is NEP 2020?
          </h2>
          <ul style={{ paddingLeft: "1.2rem", marginTop: "1rem", listStyleType: "disc" }}>
            {listItems.map((item, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                <span style={{ fontSize: "1.2rem", fontWeight: "bold", color: "#333" }}>{item.title}</span>
                <span style={{ fontSize: "1.1rem", color: "#555", lineHeight: "1.5" }}> {item.description}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NEPcontainer;
