import React, { useEffect, useRef, useState } from "react";
import useScreenSizes from "../../utils/useScreenSize";
import { useSelector } from "react-redux";

const cardData = [
  {
    title: "Real-Time Progress Monitoring",
    smallCards: [
      {
        src: require("../../assets/WebsiteImages/instant grade updates.png"),

        title: "Instant Grade Updates",
        content:
          "With instant grade updates, parents can access their child’s test scores and assignment results as soon as they are uploaded by the teacher. No more waiting until report cards are issued. This feature ensures that parents are always informed about their child's academic performance in real-time, allowing them to celebrate successes or address concerns immediately.",
      },
      {
        src: require("../../assets/WebsiteImages/daily performance insights.png"),

        title: "Daily Performance Insights",
        content:
          "This feature offers parents a clear view of their child's daily academic activities. Parents can track how well their child is participating in class, staying on top of homework, and progressing in real-time. These insights provide a daily snapshot of a student’s engagement and learning, empowering parents to offer timely support or encouragement when needed.",
      },
      {
        src: require("../../assets/WebsiteImages/Attendance Tracking.png"),

        title: "Attendance Tracking",
        content:
          "Stay informed about your child's attendance with this feature, which provides real-time records of classroom presence. Parents are instantly notified of any absences, tardiness, or missed activities, ensuring they can take quick action to keep their child on track and maintain strong communication with the school.",
      },
      {
        src: require("../../assets/WebsiteImages/Costom notifications.png"),

        title: "Custom Notifications",
        content:
          "Parents can receive personalised alerts for important updates like test results, upcoming project deadlines, or significant academic milestones. These custom notifications ensure parents remain engaged and proactive in supporting their child's education by never missing key events or performance updates.",
      }
     
 ],

 },
 {
  title: "Enhanced Communication",
  smallCards:[
    {
      src: require("../../assets/WebsiteImages/Direct Messaging.png"),

      title: "Direct Messaging",
      content:
        "Facilitate quick, secure conversations between parents and teachers, allowing for instant feedback and addressing concerns promptly. This feature ensures seamless communication, helping to resolve issues or provide updates without delay.",
    },
    {
      src: require("../../assets/WebsiteImages/automated updates.png"),

      title: "Automated Updates",
      content:
        "Keep parents informed effortlessly with automated notifications about important events, grades, and announcements. This feature ensures parents never miss crucial information, keeping them continuously updated on their child’s academic life.",
    },
    {
      src: require("../../assets/WebsiteImages/Parents-teacher conferences.png"),

      title: "Parent-Teacher Conferences",
      content:
        "Simplify the process of scheduling and managing meetings with teachers directly through the app. This feature promotes open dialogue about student progress, ensuring productive conversations for student development.",
    },
    {
      src: require("../../assets/WebsiteImages/Share resources.png"),

      title: "Shared Resources",
      content:
        "Give parents access to teaching materials and resources used in the classroom. This feature empowers parents to better support their child’s learning at home, reinforcing concepts and contributing to academic success",
    },
  ]
 },
 {
  title: "Instant Notifications",
  smallCards:[
    {
      src: require("../../assets/WebsiteImages/Timely Reminders.png"),

      title: "Timely Reminders",
      content:
        "Parents receive alerts for critical deadlines and events, such as exams or project submissions, ensuring they never miss important dates in their child’s academic calendar. This feature keeps parents on track with their child’s progress and school activities.",
    },
    {
      src: require("../../assets/WebsiteImages/Cutmiezable Alerts.png"),

      title: "Customizable Alerts",
      content:
        "Parents can choose which notifications they prefer to receive, personalizing their experience. Whether it's grades, attendance, or event reminders, this feature allows parents to stay informed in a way that suits their needs",
    },
    {
      src: require("../../assets/WebsiteImages/Event Calendars.png"),

      title: "Event Calendars",
      content:
        "A shared calendar provides an overview of upcoming exams, school events, and parent-teacher meetings, making it easier for parents to plan and stay organized.",
    },
    {
      src: require("../../assets/WebsiteImages/Real Time Updates.png"),

      title: "Real-Time Updates",
      content:
        "Any changes to schedules or events are communicated instantly, ensuring parents are always up-to-date with the latest information. This real-time feature prevents missed opportunities or last-minute surprises",
    },
  ]
 },
 {
  title: "Personalised Reports",
  smallCards:[
    {
      src: require("../../assets/WebsiteImages/Custmizable Reporting Options.png"),

      title: "Customizable Reporting Options",
      content:
        "Reports can be customized to focus on particular subjects or skills, providing parents with insights that are directly relevant to their child's specific needs, making targeted support more efficient",
    },
    {
      src: require("../../assets/WebsiteImages/Comprehensive Performance Overview.png"),

      title: "Comprehensive Performance Overview",
      content:
        "Parents can access detailed reports that highlight their child’s academic strengths and areas for growth. This helps in making well-informed decisions to support their child’s educational progress effectively",
    },
    {
      src: require("../../assets/WebsiteImages/Progress Tracking.png"),

      title: "Progress Tracking",
      content:
        "Parents can monitor their child’s academic progress over time, observing both improvements and challenges. This feature enables timely interventions and celebrations of achievements.",
    },
    {
      src: require("../../assets/WebsiteImages/Actionable Insights.png"),

      title: "Actionable Insights",
      content:
        "Reports include practical suggestions for resources and strategies that can help students improve in identified areas. Parents receive clear, actionable steps to enhance their child’s learning experience",
    },
  ]
 },

 {
  title: "Secure Access to Student Data",
  smallCards:[
    {
      src: require("../../assets/WebsiteImages/User-Friendly Interface (1).png"),

      title: "User-Friendly Interface",
      content:
        "The app’s intuitive design allows parents to easily navigate and access their child’s educational data without dealing with any technical complexities. This ensures a smooth, hassle-free experience for all users",
    },
    {
      src: require("../../assets/WebsiteImages/Encryption For Privacy (1).png"),

      title: "Encryption for Privacy",
      content:
        "Advanced encryption safeguards all sensitive student data, ensuring that information remains secure and protected from unauthorized access, giving parents peace of mind about their child’s privacy",
    },
    {
      src: require("../../assets/WebsiteImages/Cantrolled Access Leval (1).png"),

      title: "Controlled Access Levels",
      content:
        "Only authorized individuals can access specific student data, ensuring that privacy is maintained at all times. This controlled access protects the integrity of personal information",
    },
    {
      src: require("../../assets/WebsiteImages/Regular Security Updates (1).png"),

      title: "Regular Security Updates",
      content:
        "The app regularly receives updates to strengthen security, safeguarding against potential threats. Parents can trust that the app remains up-to-date with the latest protective measures.",
    }
  ]
 },
 {
    title:"Active Involvement",
    smallCards:[
      {
        src: require("../../assets/WebsiteImages/Homework Tracking (1).png"),

        title: "Homework Tracking",
        content:
          "Parents can easily monitor their child’s assignments and deadlines, helping to foster accountability and maintain consistent study habits. This feature ensures that parents stay engaged in their child's academic responsibilities",
      },
      {
        src: require("../../assets/WebsiteImages/Resource Access.png"),

        title: "Resource Access: ",
        content:
          "Parents can access educational materials and tools, enabling them to support their child's learning at home. These resources empower parents to reinforce lessons and provide additional help when needed",
      },
      {
        src: require("../../assets/WebsiteImages/Progress Discussions.png"),

        title: "Progress Discussions",
        content:
          "Parents can engage in productive conversations with teachers about their child’s performance, ensuring collaborative efforts to support academic success. This open dialogue helps address challenges early and build on strengths",
      },
      {
        src: require("../../assets/WebsiteImages/Encryption For Privacy (1).png"),

        title: "Encouragement Tools",
        content:
          "Built-in features allow parents to set academic goals and celebrate their child’s achievements, motivating students to develop strong study habits and stay engaged in their education",
      },
    
    ]
 },
 {
  title: "Paperless and Organized",
  smallCards:[
    {
      src: require("../../assets/WebsiteImages/Centralised Storage.png"),

      title: "Centralised Storage",
      content:
        "All educational records, grades, and reports are stored on a single secure platform, eliminating the hassle of managing physical files. Parents can access everything they need from one place with ease",
    },
    {
      src: require("../../assets/WebsiteImages/Easy Retrieval.png"),

      title: "Easy Retrieval",
      content:
        "Important documents and reports can be quickly searched and retrieved, saving parents time and reducing the stress of locating paperwork. This feature streamlines the process of accessing essential information.",
    },
    {
      src: require("../../assets/WebsiteImages/Enviornmentally Friendly.png"),

      title: "Environmentally Friendly",
      content:
        "By reducing the need for printed materials, this digital solution helps contribute to environmental sustainability, minimising paper use and promoting eco-friendly practices",
    },
    {
      src: require("../../assets/WebsiteImages/Automatic Backups.png"),

      title: "Automatic Backups",
      content:
        "All records are automatically backed up, ensuring that data is always safe and accessible, even in case of technical issues. Parents can rest assured their child’s information is secure.",
    },
  ]
 },
];

const StackingCardforParent = ({ customStyles = {} }) => {
  const stackCardsRef = useRef(null);
  const smallCardRefs = useRef([]);
  const screenType = useSelector((state) => state.website.screenType);
 const screen = useScreenSizes()
  useEffect(() => {
    const stackCards = stackCardsRef.current;
    if (!stackCards) return;

    const items = stackCards.getElementsByClassName("js-stack-cards__item");
    let scrollingFn = null;
    let scrolling = false;

    const setStackCards = () => {
      const marginY = parseInt(
        getComputedStyle(stackCards).getPropertyValue("--stack-cards-gap"),
        10
      );
      stackCards.style.paddingBottom = `${marginY * (items.length - 1)}px`;

      for (let i = 0; i < items.length; i++) {
        items[i].style.transform = `translateY(${marginY * i}px)`;
      }
    };

    const stackCardsCallback = (entries) => {
      if (entries[0].isIntersecting) {
        if (scrollingFn) return;
        stackCardsInitEvent();
      } else {
        if (!scrollingFn) return;
        window.removeEventListener("scroll", scrollingFn);
        scrollingFn = null;
      }
    };

    const stackCardsInitEvent = () => {
      scrollingFn = stackCardsScrolling;
      window.addEventListener("scroll", scrollingFn);
    };

    const stackCardsScrolling = () => {
      if (scrolling) return;
      scrolling = true;
      window.requestAnimationFrame(animateStackCards);
    };

    const animateStackCards = () => {
      const marginY = parseInt(
        getComputedStyle(stackCards).getPropertyValue("--stack-cards-gap"),
        10
      );
      const top = stackCards.getBoundingClientRect().top;
      const cardStyle = getComputedStyle(items[0]);
      const cardTop = Math.floor(parseFloat(cardStyle.getPropertyValue("top")));
      const cardHeight = Math.floor(
        parseFloat(cardStyle.getPropertyValue("height"))
      );
      const windowHeight = window.innerHeight;

      if (
        cardTop - top + windowHeight - cardHeight + marginY * items.length >
        0
      ) {
        scrolling = false;
        return;
      }

      for (let i = 0; i < items.length; i++) {
        const scrollingValue = cardTop - top - i * (cardHeight + marginY);
        if (scrollingValue > 0) {
          const scaling =
            i === items.length - 1
              ? 1
              : (cardHeight - scrollingValue * 0.05) / cardHeight;
          items[i].style.transform = `translateY(${
            marginY * i
          }px) scale(${scaling})`;
        } else {
          items[i].style.transform = `translateY(${marginY * i}px)`;
        }
      }

      scrolling = false;
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const cardIndex = Array.from(items).indexOf(entry.target);
        if (entry.isIntersecting) {
          setActiveButtonIndex(cardIndex); // Update active button index based on visible card
        }
      });
    });

    for (let i = 0; i < items.length; i++) {
      observer.observe(items[i]);
    }

    window.addEventListener("resize", setStackCards);
    return () => {
      window.removeEventListener("resize", setStackCards);
      observer.disconnect();
      window.removeEventListener("scroll", scrollingFn);
    };
  }, []);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
 

 
  const defaultStyles = {
    container: {
      "--stack-cards-gap": "40px",
      "--stack-cards-item-ratio": "2/1",
     
    },
    stackCards: {
      paddingBottom: "calc(var(--stack-cards-gap) * 1)",
      // height: "80%",
    
     
    },
    cardItem: {
      position: "relative",
      position: "sticky",
      
      top: "30px",
      // height: "60%",
      paddingBottom: screenType==="smallPhone"?"calc(35vh / var(--stack-cards-item-ratio))" : screenType==="phone" ? "calc(40vh / var(--stack-cards-item-ratio))": screenType==="tablet"? "calc(20vh / var(--stack-cards-item-ratio))": screenType==="tab" ?"calc(20vh/ var(--stack-cards-item-ratio))" : screen >= 1200 ?"calc(40vh / var(--stack-cards-item-ratio))" :"",
      transformOrigin: "center top",
      backgroundColor:"#ffffff",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      marginBottom: screenType==="smallPhone" ? "130px" : screenType==="tab"?"170px": "110px",
      width: "95%",
      borderRadius:"10px",
      boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
      // overflow: "hidden",
      margin:"0 auto",
      padding:"15px"
    },
    cardContent: {
      position: "relative",
      top: 0,
      left: 0,
     
      height: "100%", // Default height
      display: "flex",
      flexDirection: "column",
      // overflowX: "hidden", // Add this line
      overflow:"hidden",
            backgroundColor:"#ffffff",
        padding:"10px",
        borderRadius:"10px",
      justifyContent: "space-between",
      transition: "height 0.3s ease", 
    },
    smallCardContainer: {
      display:     "flex",
     
      width:"96%",
      overflowX: "scroll",

      // overflowX: "hidden", // Add this line
      overflowY: "auto",
      // width: "97%",
      height: "100%",
      padding: "10px 11px",
      justifyContent: "space-evenly",
      // whiteSpace: "nowrap",

    },
    smallCard: {
      height: screenType==="smallPhone"?"40vh": screenType==="tablet"? "30vw": screenType==="tab"? "40vw": "54vh",
      width: "20%",
      borderRadius: "8px",
      marginRight: "10px",
      flex: screenType==="smallPhone" ||screenType==="phone"?"0 0 70%"  :screenType==="tablet"? "0 0 30%": screenType==="tab" ?"0 0 32.5%": "0 0 21%",
      display: "flex",
      flexDirection: "column",
      overflowX: "hidden", // Ensure overflow is hidden
      justifyContent: "flex-start",
      overflowY: "auto",
      // overflow: "hidden",
      position: "relative",
      transition: "height 0.3s ease",
      boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
    },
    smallCardImage: {
      height: "70%",
      // maxWidth: "100%",
      borderRadius: "8px 8px 0 0",
      objectFit: "cover",
      transition: "height 0.3s ease",
    },
    smallCardContent: {
      height: "auto",

      display: "flex",
      flexDirection: "column",
      textOverflow: "ellipsis",
      fontSize:  screenType==="smallPhone"?"small":screenType==="tablet" ? "1rem" : "1.1rem",
      overflowX: "hidden", // Ensure overflow is hidden
      overflowY:"scroll",
      whiteSpace: "normal", // Allow text to wrap to the next line
        backgroundColor:"#ffffff",
      backgroundColor: "#ffffff",
      rowGap: screenType==="smallPhone"? "13px": screenType==="tab"?"9px":"13px",
      borderRadius: "0 0 8px 8px",
      transition: "height 0.3s ease", // Transition for height change
      position: "absolute",
      bottom: "0",
      width: "100%",
      padding: "5px", // Add padding to give some space
      marginTop:"20px"
    },
    smallCardHover: {
      height: "80%", // Expanded height on hover
    },
    title: {
      padding: "10px", // Added padding for title
      fontSize: "1.5rem", // Adjust as needed
      color: "#333", // Title color
      flexShrink: 0, // Prevent shrinking
    },
    smallCardTitle: {
      fontSize: screenType==="smallPhone" ||screenType==="phone" ? "1.2rem":screenType==="tablet"? "1.8rem":"1.7rem", // Adjust size as needed
      color: "black", // Title color
      // padding: "5px", // Add padding for better spacing
      // textAlign: 'center', // Center the title
      flexShrink: 0, // Prevent title from shrinking
    },
    navbar: {
      display: "flex",
      // marginLeft: "10px",
      width:"100vw",
      overflowX:"scroll",
      // height:"6vh",
      justifyContent: "flexstart",
      // marginTop: "10px",
      overflowX:"scroll"
    },
    navItem: {
      marginRight: "10px",
      cursor: "pointer",
      // padding: screenType==="smallPhone"? "": "5px 10px",
      color: "grey",
     
      borderRadius: "4px",
      fontSize: screenType==="smallPhone"||screenType==="phone"?"0.9rem": screenType==="tablet"?"1rem":"",
      // textDecoration: 'underline',
    },
    activeNavItem: {
     
      textDecoration: "underline",
      fontWeight: "bold",
      color: "black",
     
      color: "black", // Change color for active state if needed
    },
    cardtitle:{
      margin:"0px",
      fontSize: screenType==="tablet"?"1.7rem": screenType==="tab"? "1.6rem": screenType==="smallPhone"||screenType==="phone" ?"1.7rem":"2rem",
      fontFamily:""
    }
  };


  return (
    <>
    

    <div
      style={{ ...defaultStyles.container, ...customStyles.container }}
      ref={stackCardsRef}
    >
    
      <div style={defaultStyles.stackCards}>
      {cardData.map((card, largeCardIndex) => (
      // Conditionally render based on active index
              <div
                style={{ ...defaultStyles.cardItem, ...card.largeCardStyle }}
                key={largeCardIndex}
                className="js-stack-cards__item"
              >
                <div style={defaultStyles.cardContent}>
                  <p style={defaultStyles.cardtitle}>{card.title}</p>
                
                  <div style={defaultStyles.smallCardContainer}>
                    {card.smallCards.map((smallCard, smallCardIndex) => (
                      <div
                        key={smallCardIndex}
                        ref={(el) => {
                          if (!smallCardRefs.current[largeCardIndex]) {
                            smallCardRefs.current[largeCardIndex] = [];
                          }
                          smallCardRefs.current[largeCardIndex][smallCardIndex] = el;
                        }}
                        style={defaultStyles.smallCard}
                        className="small-card"
                        onMouseEnter={(e) => {
                          const content = e.currentTarget.querySelector(".small-card-content");
                          content.style.height = "90%"; // Expand content height on hover
                        }}
                        onMouseLeave={(e) => {
                          const content = e.currentTarget.querySelector(".small-card-content");
                          content.style.height = "30%"; // Reset to original height
                        }}
                      >
                        <img
                          src={smallCard.src}
                          alt={smallCard.alt}
                          style={defaultStyles.smallCardImage}
                          className="small-card-image"
                        />
                        <div
                          style={{
                            ...defaultStyles.smallCardContent,
                            height: "30%", // Initial height
                          }}
                          className="small-card-content"
                        >
                          <div style={defaultStyles.smallCardTitle}>
                            <p style={{ display:"flex",  marginLeft:"20px"  }}>{smallCard.title}</p>
                          </div>
                          <div
                            style={{
                              height: "100%",
                              height: "auto", // Set to auto to allow it to expand
                              flex: "1",
                              padding:"20px",
                            }}
                          >
                            {smallCard.content}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default StackingCardforParent;