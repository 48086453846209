import React, { useEffect, useRef, useState } from "react";
import MainContentArea from "../Screens/MainContentArea/MainContentArea";
import TitleBarWebsite from "../Components/studyBuddyWA/TitleBarWebsite";
import TitleBar from "../Components/studyBuddyWA/TitleBar.js";
import SidebarMobile from "../Components/studyBuddyWA/SidebarMobile.js";
import Footer from "../Components/studyBuddyWA/Footer.js";
import { useSelector, useDispatch } from "react-redux";
import { setHeaderHeight, setReach } from "../reduxStore/slices/WebSiteData.js";
import headerBg from "../assets/images/background.png";
import useScreenSizes from "../utils/useScreenSize.js";
import { useLocation } from "react-router-dom";
import SideBarScreen from "../Components/studyBuddyWA/sideBarNav";

const Apps = () => {
  const dispatch = useDispatch();
  const headerHeight = useSelector((state) => state.website.headerHeight);
const sidebar = useSelector((state)=> state.auth.sidebar)  
  const reach = useSelector((state) => state.website.reach);
  const screen = useScreenSizes();
  const location = useLocation();
  const setFixed = !(['/'].includes(location.pathname));

  const containersRef = useRef(null);
  
  const containerRef = useRef(null); // Ref for the outer container
  const [headerFixed, setHeaderFixed] = useState(false); // State for toggling header fixed position
  const [Tab, setTab] = useState(); // State for tablet
  const [phone, setPhone] = useState(); // State for phone
  const [enableScroll, setEnableScroll] = useState(false);
  


  
  useEffect (()=>{
    if (setFixed){
      let newHeaderHeight;
      
      if (screen >= 1200) {
        newHeaderHeight = "18vh";
        
      } else if (screen >= 1000 && screen <= 1200) {
        newHeaderHeight = "11vw";
      }  else if (screen >= 650 && screen <= 1000) {
        newHeaderHeight = "14vw";
      }  else {
        newHeaderHeight = "10vh";
      }

      dispatch(setHeaderHeight(newHeaderHeight));

      setHeaderFixed(true); // Set header to fixed when scrolling
      dispatch(setReach(true));

    }
  }
 
  )
  useEffect(() => {
    let initialHeaderHeight;

    // Determine device type based on screen size
    if (screen > 1200) {
      initialHeaderHeight = "90vh";
      setTab(false);
   
      setPhone(false);
      dispatch(setReach(false));
    } else if (screen >= 800 && screen <= 1200) {
      initialHeaderHeight = "50vh";
      dispatch(setReach(false));

      setTab(true); // Set Tab to true if screen is tablet
      setPhone(false); // Set phone to false
    } else {
      initialHeaderHeight = "50vh";
      setTab(false); // Set Tab to false
      setPhone(true); // Set phone to true
      dispatch(setReach(false));

    }

    dispatch(setHeaderHeight(initialHeaderHeight));
  }, [screen, dispatch]);


  useEffect(() => {
    if (reach) {
      const timer = setTimeout(() => {
        setEnableScroll(true);
      }, 500);
  
      return () => clearTimeout(timer); // Cleanup the timeout on unmount or when `reach` changes
    } else {
      setEnableScroll(false);
    }
  }, [reach]);
  

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = containerRef.current.scrollTop;

      if (currentScrollY > 0) {
        dispatch(setReach(true));

        let newHeaderHeight;
      
        if (screen >= 1200) {
          newHeaderHeight = "18vh";
          
        } else if (screen >= 1000 && screen <= 1200) {
          newHeaderHeight = "11vw";
        }  else if (screen >= 650 && screen <= 1000) {
          newHeaderHeight = "14vw";
        }else {
          newHeaderHeight = "10vh";
        }

        dispatch(setHeaderHeight(newHeaderHeight));

        setHeaderFixed(true); // Set header to fixed when scrolling
        
      } else {
        
        if (!setFixed) {
          let resetHeaderHeight;
          if (screen >= 1200) {
            resetHeaderHeight = "90vh";
          } else if (screen >= 650 && screen <= 1200) {
            resetHeaderHeight = "50vh";
          } else {
            resetHeaderHeight = "50vh";
          }

          dispatch(setHeaderHeight(resetHeaderHeight));
          dispatch(setReach(false));
          setHeaderFixed(false); // Reset header to relative when at the top
        } 
        
        
      }
    };

    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [screen, dispatch, setFixed]);

  const routesWithoutSidebar = ["/LMS"];
  const routesWithoutTitleBar = [ ,"/LMS"];
   
  const showTitleBar = routesWithoutTitleBar.includes(location.pathname);
  const showSidebar = routesWithoutSidebar.includes(location.pathname);
  useEffect(() => {
    // Scroll the `containersRef` div to the top on route change
    if (containersRef.current) {
      containersRef.current.scrollTo(0, 0);
    }
  }, [location.pathname]); 
  useEffect(() => {
    // Scroll the `containersRef` div to the top on route change
    if (containersRef.current) {
      containersRef.current.scrollTo(0, 0);
    }
  }, [location.state]); 


  return (
    <div
      ref={containerRef}
      style={{ 
        height: '100vh', 
        
        overflowY: "scroll", 
        overflowX: "hidden", 
        scrollbarWidth: "none",
        backgroundColor: '#c7985f',
      
      }}
    >
      <div
        style={{
          width: "100vw",
          backgroundImage: location.pathname==="/LMS" ? null :`url(${headerBg})`,
          height:  location.pathname==="/LMS" ? null : headerHeight,
          position: reach ? "fixed" : "relative",
          top: 0,
          left: 0,
          backgroundColor: '#c7985f',
          zIndex: 10,
          transition: "height 0.5s ease",
          scrollbarWidth: "none",
          backgroundPosition:"top",
          
          backgroundRepeat: reach ? "repeat-y" : "repeat-x",
        }}
      >
        {showTitleBar ? <TitleBar /> : <TitleBarWebsite setFixed={setFixed} />}
      </div>

      <div
        style={{
          height: screen >= 650 && screen <= 800 ?  "90%": Tab ? "92%" :  phone ? "100vh" : "83vh",
          position: "relative",
          backgroundColor: '#c7985f',
          zIndex: 1,
          // bottom:phone ? (reach ? -5 : ""):"",
          marginTop: reach ? headerHeight :"",
          overflowY: enableScroll ? "scroll" : "hidden",
          // scrollbarWidth: "none",
          transition:"margintop 0.5s ease bottom 0.5 ease"
        }}
        ref={containersRef}
      >
        <div style={{display:"flex"}}>

        {sidebar ?    <SideBarScreen/>:null  }
        <MainContentArea />
        </div>
      

        <Footer />
     
      </div>
    </div>
  );
};

export default Apps; 