import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import StackingCards from "./StackingCards";
import GuidanceContainer from "./GuidanceContainer";
import NEPcontainer from "./NEPContainer";
import PricingContainer from "./PricingContainer";
import AboutStudyBuddy from "./AboutStuBuddyy";
import Card from "./StylesCard";
import AnimatedText from "./AnimatedText";
import StudybuddyGraphicsInstitute from "../InstituteComponent/StudyBuddyGraphics";
import StackingCardforInstitute from "../InstituteComponent/Stackingcard";
import StackingCardforParent from "../ParentComponent/Stackingcard"
import StackingCardforTeacher from "../TeacherComponent/Stackingcard"
import StackingCardforStudent from "../StudentComponent/Stackingcard"
import StudybuddyGraphicsForParent from "../ParentComponent/StudyBuddyGraphics";
import StudybuddyGraphicsForStudent from "../StudentComponent/StudyBuddyGraphics";
import StudybuddyGraphicsForTeacher from "../TeacherComponent/StudyBuddyGraphics";
import StudybuddyGraphics from "./StudyBuddyGraphics";
const HomePage = () => {
  const location = useLocation();
  const [showComponent, setShowComponent] = useState(null);
  const [component, setComponent] = useState(null)
  useEffect(() => {
    // Set the specific component to show based on the route
    if (location.pathname === "/forInstitute") {
      setShowComponent(<StackingCardforInstitute/>);
      setComponent(<StudybuddyGraphicsInstitute/>)
    }
    else if (location.pathname === "/forParent") {
      setShowComponent(<StackingCardforParent/>);
      setComponent (<StudybuddyGraphicsForParent/>)
    }
    else if (location.pathname === "/forStudent") {
      setShowComponent(<StackingCardforStudent/>);
      setComponent (<StudybuddyGraphicsForStudent/>)
    }
    else if (location.pathname === "/forTeacher") {
      setShowComponent(<StackingCardforTeacher/>);
      setComponent (<StudybuddyGraphicsForTeacher/>)
    }
    else {
      setShowComponent(<StackingCards/>);
      setComponent (<StudybuddyGraphics/>)
    }
  }, [location.pathname]);

  return (
    <div
      style={{
        height: "100%",
        width: "100vw",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        rowGap: "30px",
        marginTop: "5px",
      }}
    >
      {/* <AnimatedText /> */}
     {component}
 
      <Card />
         {showComponent}
      <GuidanceContainer />
      <NEPcontainer />
      <PricingContainer />
      <AboutStudyBuddy />
      {/* <VerticalCarousel /> */}
    </div>
  );
};

export default HomePage;
