import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useScreenSizes from "../../utils/useScreenSize";
import AnimatedBird from "../studyBuddyWA/AnimationBird"
const TitleBar = () => {

  const screen = useScreenSizes();
  const [isPhone, setIsPhone] = useState(false);
  const [Phone, setPhone] = useState(false);
  const [Tab, setTab] = useState(false);
  const [Tablet, setTablet] = useState(false);
  const [height, setHeight] = useState("");
  const [headerSize, setHeaderSize] = useState("small");
  const [animate, setAnimate] = useState(false);

  const location = useLocation()
  useEffect(() => {
    if (screen >= 1200) {
      setTab(false);
      setTablet(false);
      setIsPhone(false);
      setPhone(false);
    } else if (screen >= 1000 && screen < 1200) {
      setTablet(true);
      setTab(false);
      setPhone(false);
      setIsPhone(false);
    } else if (screen >= 650 && screen < 1000) {
      setTab(true);
      setTablet(false);
      setIsPhone(false);
      setPhone(false);
    } else if (screen >= 350 && screen < 650) {
      setPhone(true);
      setTab(false);
      setTablet(false);
      setIsPhone(false);
    } else {
      setTab(false);
      setTablet(false);
      setIsPhone(true);
      setPhone(false);
    }
  }, [screen]);
  useEffect(() => {
    if (animate) {
      setTimeout(() => setAnimate(false), 2000); 
    }
  }, [animate]);


  useEffect(() => {
    if (isPhone || Phone) {
      setHeight("10vh"); // For phones
    } else if (Tab || Tablet) {
      setHeight("18vh"); // For tablets
    } else {
      setHeight("20vh"); // Default height for larger screens
    }
  }, [isPhone, Phone, Tab, Tablet]);
  const handleTreeButtonClick = () => {
    setAnimate(true);
    if (headerSize === "small") {
      setHeight("100vh");
      setHeaderSize("large");
      // setisDisplaynone(true)
    } else if (headerSize === "large") {
     
      if (isPhone || Phone) {
        setHeight("10vh"); // For phones
      } else if (Tab || Tablet) {
        setHeight("18vh"); // For tablets
      } else {
        setHeight("20vh"); // Default height for larger screens
      }
     
      setHeaderSize("small");
    }
  };

  const styles = {
    container: {
      margin: 0,
      padding: 0, 
      height: height,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      position: 'fixed',
      zIndex: 100,
    },
    header: {
      backgroundImage: `url(${require("../../assets/images/background.png")})`,
      backgroundSize: "cover",
      display: "grid",
      alignItems: "end",
      width: "100%",
      backgroundRepeat:"round",
      height: height,
      transition: "height 2s ease-in-out",
      position: "absolute",
      overflow:'hidden'
      
      
    },
    GrassContainer: {
      position: "absolute",
      display: "flex",
      width: "100vw",
      height: isPhone||Phone ? "10px": "20px",
      flexDirection: "row",
      backgroundImage: `url(${require("../../assets/images/grass.png")})`,
      backgroundSize: "contain",
      backgroundRepeat: "repeat-x",
      zIndex: 2,
    },
    TreeButton: {
      cursor: "pointer",
      position: "absolute",
      bottom: "0px",
      width : Phone||isPhone? "10vh": Tab ?   "18vh" :Tablet ?  "18vh":"19vh"
    },
  };

  return (
    <div>
     
     <div style={styles.container}>
     
          <AnimatedBird/>
      <div style={styles.header}>
       
        <div
          style={styles.TreeButton}
          onClick={handleTreeButtonClick}
        >
          <img
            src={require("../../assets/images/logo.png")}
            style={{  width:"100%", bottom:0  , marginLeft: 10 }}
          />
       
        </div>
        <div style={styles.GrassContainer}>
        </div>
      </div>
    </div> 
    </div>
  );
};

export default TitleBar;
