import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import useScreenSizes from "../../utils/useScreenSize";
import { transform } from "framer-motion";
import { useSelector } from "react-redux";

const GuidanceContainer = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const screenType = useSelector((state) => state.website.screenType);


  // Style variables
  const styles = {
    '--bg-color': '#fff',
    '--text-color': '#444',
    '--highlight-color': '#ffee02',
    '--box-color': '#f7f6f2',
    '--hover-bg-color': '#fffab3',
    // '--font-family': '"Epilogue", sans-serif',
    '--font-size': screenType==="smallPhone"||screenType==="phone" ? '14px' : '17px', // Smaller font for phones
    '--line-height': screenType==="smallPhone"||screenType==="phone" ? '22px' : '30px',
    '--font-weight': '400',
    '--card-width': screenType==="smallPhone"||screenType==="phone" ? '100%' : '30%', // Full width on phone
    '--icon-size': screenType==="smallPhone"||screenType==="phone" ? '50px' : '70px', // Smaller icon size on phone
    '--transition': '0.3s ease-in-out',
    overflow:"hidden",
     fontFamily:"raleway"
  };

  const containerStyle = {
    height: screenType==="smallPhone"||screenType==="phone" ? 'auto' : screenType==="tab"? "65vw":screenType==="tablet" ? "45vw": '75vh', // Adjust for phone
    width: screenType==="tab"||screenType==="tablet"?"90vw" : '95vw',
    padding:"15px",
    padding: '0 0.75rem',
    margin: '0 auto',
    fontFamily: 'var(--font-family)',
    display: screenType==="smallPhone"||screenType==="phone" ? 'block' : 'flex', // Stack vertically on phone
    justifyContent: screenType==="smallPhone"||screenType==="phone" ? 'initial' : 'center', // Align center on phone
    backgroundColor: "white",
    alignItems: 'center',
    borderRadius: "10px"
  };

  const cardColumnStyle = {
    flex: '0 0 auto',
    width: 'var(--card-width)',
    textAlign: 'center',
    maxWidth: '100%',
    padding: screenType==="smallPhone"||screenType==="phone" ? '10px 15px' : '87px 15px', // Smaller padding on phone
    boxSizing: 'border-box',
    marginTop:screenType==="smallPhone"||screenType==="phone" ? "10%" :"" ,
    marginBottom: screenType==="smallPhone"||screenType==="phone" ? '20px' : '0',
  };

  const cardDetailsStyle = (isHovered) => ({
    // width: '100%',
    margin: 'auto',
    position: 'relative',
    transition: 'var(--transition)',
    padding: screenType==="smallPhone"||screenType==="phone" ? '20px 0': screenType==="tab"? "": '36px 0', // Adjust padding for phone
    // backgroundColor: isHovered ?   'var(--hover-bg-color)' : 'var(--bg-color)',
    // boxShadow:  isHovered? "10px 10px 15px rgba(0, 0, 0, 0.3)":"",
    // backgroundColor: isHovered ? 'var(--hover-bg-color)' : 'var(--bg-color)',
    transform: isHovered ? 'translateX(10px)' : 'translateX(0)',
    
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    
    
  });

  const cardBeforeStyle = (isHovered) => ({
    content: '""',
    width:screenType==="tablet" ?  "150px":  screenType==="tab" ?"17vw" : '210px',
    height:  screenType==="smallPhone"||screenType==="phone"? "300px" :'380px',
    background: isHovered ? 'var(--hover-bg-color)' : 'var(--box-color)',
    position: 'absolute',
    left: screenType==="tab"||screenType==="tablet"?"40%" : '50%',
    top: '50%',
    transform: 'translate(-50%, -50%) skew(-20deg, 0deg)',
  
    zIndex: '-1',
    transition: 'var(--transition)',
  });

  const cardIconsStyle = {
    width: screenType==="smallPhone"||screenType==="phone" ? '90px' : screenType==="tab"?  "15vw": screenType==="tablet" ? "100px" : '140px', // Smaller icon area for phone
    height: screenType==="smallPhone"||screenType==="phone" ? '90px'  : screenType==="tab" ? "12vw" :screenType==="tablet"? "100px":  '150px',
    position: 'relative',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const cardIconsBeforeStyle = (isHovered) => ({
    content: '""',
    position: 'absolute',
    top: '0',
    left: '0',
    border: '1px solid var(--highlight-color)',
    width: screenType==="tab"||screenType==="tablet"? "90%": '100%',
    height: '100%',
    transform: 'skew(-20deg, 0deg)',
    background: isHovered ? 'var(--highlight-color)' : 'var(--bg-color)',
    transition: 'var(--transition)',
    boxShadow:  isHovered? "10px 10px 15px rgba(0, 0, 0, 0.3)":"",
  }); 

  const imgStyle = {
    position: 'relative',
    width: 'var(--icon-size)',
    height: 'var(--icon-size)',
    transform:  "rotate(360deg)",
  };

  const cardTitleStyle = {
    marginBottom: '15px',
    marginTop: screenType==="smallPhone"||screenType==="phone" ? '20px' : '30px',
    fontWeight: '700',
    fontSize: screenType==="smallPhone"||screenType==="phone" ? '1.25rem' : '1.75rem', // Smaller title on phone
    lineHeight: '1.2',
     fontFamily:"raleway"
  };

  const cardTextStyle = {
    fontSize: screenType==="smallPhone"||screenType==="phone" ? '14px' : '16px', // Adjust text size for phone
    lineHeight: '30px',
    color: 'var(--text-color)',
    fontWeight: '400',
    marginBottom: '30px',
  };

  const readMoreBtnStyle = (isHovered) => ({
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid var(--highlight-color)',
    borderRadius: '100%',
    margin: 'auto',
    background: 'var(--bg-color)',
    transform: isHovered ? 'translateX(0)' : 'translateX(-10px)',
    opacity: isHovered ? 1 : 0,
    visibility: isHovered ? 'visible' : 'hidden',
    transition: 'var(--transition)',
  });

  // Card content data
  const cardsData = [
    {
      title: "Watch our demo",
      text: "After our call, our dedicated team will arrange a personalized demo just for you. This is your chance to explore StudyBuddy up close. ",
      imgSrc:require("../../assets/Animations/video-conference_11781840.gif")
    },
    {
      title: "Book a Call ",
      text: "Reach out to us to schedule a call—whether by phone or in person. We’re eager to understand your unique needs so we can customize our solutions just for you",
      imgSrc: require("../../assets/Animations/call_12999687.gif")
    },
    {
      title: "Try before you buy",
      text: "Experience StudyBuddy with our 7-day free trial. Immerse yourself in our platform and explore all its features and resources",
      imgSrc: require("../../assets/Animations/shopping-cart_11614817.gif")
      
    }
  ];

  return (
    <section className="creative-cards" style={styles}>
      <div className="container" style={containerStyle}>
        
        {cardsData.map((card, index) => (
          <div
            key={index}
            className="card-column"
            style={cardColumnStyle}
            onMouseEnter={() => setHoveredCard(index)}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <div className="card-details" style={cardDetailsStyle(hoveredCard === index)}>
              <div className="before" style={cardBeforeStyle(hoveredCard === index)}></div>
              <div className="card-icons" style={cardIconsStyle}>
                <div className="before" style={cardIconsBeforeStyle(hoveredCard === index)}></div>
                <img className="light-icon" src={card.imgSrc} alt="icon" style={imgStyle} />
              </div>
              <p style={cardTitleStyle}>
                <p href="#" style={{ color: "#000", textDecoration: "none" }}>
                  {card.title}
                </p>
              </p>
              <p style={cardTextStyle}>{card.text}</p>
              <a href="/contactus" className="read-more-btn" style={readMoreBtnStyle(hoveredCard === index)}>
                <FontAwesomeIcon icon={faAngleRight} />
              </a>
              
            </div>
            
          </div>
        ))}
      </div>
    </section>
  );
};

export default GuidanceContainer;
