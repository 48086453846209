import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import dropdownArrow from '../../assets/images/dropdownArrow.png';
import dropdownUpArrow from '../../assets/images/dropdownUpArrow.png'; 
import aboutIcon from "../../assets/images/about.png";
import contact from "../../assets/images/contact.png";
import useScreenSizes from "../../utils/useScreenSize";
import SidebarMobile from './SidebarMobile';
import { useSelector } from 'react-redux';

const SideBarScreen = () => {
const [isPhone,setIsPhone]=useState();
const [Tab,setTab]=useState();
const screen=useScreenSizes();

  const location = useLocation();
  const [showAboutItems, setShowAboutItems] = useState(false);
  
  const [isExpanded, setIsExpanded] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState('');
  const [activeView, setActiveView] = useState('');

  const handleMouseEnter = () => setIsExpanded(true);
  const handleMouseLeave = () => setIsExpanded(false);
  const headerHeight = useSelector((state) => state.website.headerHeight);

  const handleSubmenuClick = (submenu) => {
    setOpenSubmenu(openSubmenu === submenu ? '' : submenu);
  };

  // const handleViewToggle = (view) => {
  //   setActiveView(activeView === view ? '' : view);
  // };


  useEffect(() => {
    setShowAboutItems(location.pathname === '/about');
  }, [location.pathname]);

  useEffect(() => {
    if(screen>=950){
    
 setTab(false);
 setIsPhone(false);
    }else if(screen>650){
     
 
     setTab(true);
     setIsPhone(false);
    }
    else{
     setIsPhone(true);
     setTab(false)
    }
 
     
    
    
   }, [screen])

  const sidebarStyles = {
    width:  location.pathname === '/website' ? 0 :isPhone ? '20%' : isExpanded ? '20%' : '4%',
    opacity: isExpanded ? 1 : 0.6,
    transition: 'width 0.3s ease, opacity 0.3s ease',
    position: isPhone ? 'absolute' : 'relative',
    right: 0,
    paddingTop: headerHeight,
    // height: '100vh',
    backgroundColor: isPhone ? 'transparent' : '#E5F1E3', 
    zIndex: 10,
    overflow: 'hidden',
  
  };

  const menuStyles = {
    padding: '10px',
  };

  const menuItemStyles = {
    display: 'block',
    textDecoration: 'none',
    color: 'black',
    fontWeight: "bold",
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 10px',
    justifyContent: 'space-between',
    borderRadius: '10px',
  };

  const iconStyles = {
    width: '24px',
    height: '24px',
    marginRight: '10px',
  };

  const arrowLogo = {
    width: '15px',
    height: '12px',
  };

  const submenuStyles = {
    marginBottom: '10px',
    paddingLeft: '10px',
  };

  const submenuLabelStyles = {
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 10px',
    justifyContent: 'space-between',
    borderRadius: '10px',
  };

  return (
    <div
      style={sidebarStyles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!isPhone && (
        <div style={menuStyles}>
          {!showAboutItems && (
            <>
              <div style={submenuStyles}>
                <span
                  style={submenuLabelStyles}
                  onClick={() => handleSubmenuClick('charts')}
                >
                  Charts
                </span>
                {openSubmenu === 'charts' && (
                  <>
                    <Link to="/pie-charts" style={menuItemStyles}>
                      Pie Charts
                    </Link>
                    <Link to="/line-charts" style={menuItemStyles}>
                      Line Charts
                    </Link>
                  </>
                )}
              </div>
              <Link to="/documentation" style={menuItemStyles}>
                Documentation
              </Link>
              <Link to="/calendar" style={menuItemStyles}>
                Calendar
              </Link>
              <div style={submenuStyles}>
                <span
                  style={submenuLabelStyles}
                  onClick={() => {
                    handleSubmenuClick('about');
                    // handleViewToggle('about');
                  }}
                >
                  <img src={aboutIcon} alt="About Icon" style={iconStyles} />
                  {isExpanded && 'About'}
                  <img
                    src={activeView === 'about' ? dropdownUpArrow : dropdownArrow}
                    alt="Dropdown Arrow"
                    style={arrowLogo}
                  />
                </span>

                {openSubmenu === 'about' && (
                  <>
                    <Link to="/about" style={menuItemStyles}>
                      About Section
                    </Link>
                  </>
                )}
              </div>
              <Link to="/contact" style={menuItemStyles}>
                <img src={contact} alt="Contact Icon" style={iconStyles} />
                {isExpanded && 'Contact'}
              </Link>
            </>
          )}

          {showAboutItems && (
            <div style={submenuStyles}>
              <span style={submenuLabelStyles}>About</span>
              <Link to="/about" style={menuItemStyles}>
                About Us
              </Link>
              <Link to="/about" style={menuItemStyles}>
                About Us
              </Link>
              <Link to="/about" style={menuItemStyles}>
                About Us
              </Link>
            </div>
          )}
        </div>
      )}
      {isPhone &&  <SidebarMobile/>}
    </div>
  );
};

export default SideBarScreen;
