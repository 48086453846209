import React, { useState, useEffect, useRef } from "react";
import FancyCarousel from "react-fancy-circular-carousel";
import "react-fancy-circular-carousel/FancyCarousel.css";
import { motion } from "framer-motion";
// Import your images
import image1 from "../../assets/WebsiteImages/digital backbone.jpg";
import image2 from "../../assets/WebsiteImages/equalAccessToEducation.png";
import image3 from "../../assets/WebsiteImages/holisticDevelopment 1.png";
import image4 from "../../assets/WebsiteImages/skillBasedLearning 1.png";
import image5 from "../../assets/WebsiteImages/aComprehensiveCurriculum.png";
import image6 from "../../assets/WebsiteImages/ConnectedEducationEcosystem.jpg";
import image7 from "../../assets/WebsiteImages/nationalPride.png";
import useScreenSizes from "../../utils/useScreenSize";
import { borderRadius, height } from "@mui/system";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

// Info1 component definition

// VisionContainer component definition
const VisionContainer = () => {
  const [focusElement, setFocusElement] = useState(0);


  const [opacity, setOpacity] = useState(1);
  const [isInView, setIsInView] = useState(false);

  const [hasAnimated, setHasAnimated] = useState(false); // Track if animation has run
  const containerLeftRef = useRef(null);
  const containerRightRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasAnimated) {
          // Check if not animated yet
          setIsInView(true);
          setHasAnimated(true); // Set animated to true after first animation
        }
      });
    });

    if (containerLeftRef.current) observer.observe(containerLeftRef.current);
    if (containerRightRef.current) observer.observe(containerRightRef.current);

    return () => {
      if (containerLeftRef.current)
        observer.unobserve(containerLeftRef.current);
      if (containerRightRef.current)
        observer.unobserve(containerRightRef.current);
    };
  }, [hasAnimated]); // Add hasAnimated as a dependency
  const screenType = useSelector((state) => state.website.screenType);
  const screen = useScreenSizes()
 

  const images = [image1, image2, image3, image4, image5, image6, image7];
  const [autoRotate, setAutoRotate] = useState(true); // State to control auto-rotation

  const handleMouseEnter = () => {
    setAutoRotate(false); // Stop auto-rotation on hover
  };

  const handleMouseLeave = () => {
    setAutoRotate(true); // Resume auto-rotation when hover ends
  };

  // Content for each focus element
  const info = [
    {
      title: "A Digital Backbone for Education",
      content:
        "We aim to modernize India's education infrastructure, providing a seamless and efficient digital platform for tracking, managing, and enhancing educational outcomes.",
    },
    {
      title: "Equal Access to Education",
      content:
        "No student should be left behind. StudyBuddy is committed to ensuring that every individual, regardless of their background, has access to quality education and the resources they need to succeed.",
    },
    {
      title: "Holistic Development",
      content:
        "We believe that education is more than just academics. StudyBuddy fosters character development, critical thinking, and social responsibility to create well-rounded individuals who can contribute positively to society.",
    },
    {
      title: "Skill-Based Learning",
      content:
        "In today's competitive world, skills are as important as degrees. StudyBuddy equips students with the practical skills and knowledge they need to thrive in the workplace and contribute to India's economic growth.",
    },
    {
      title: "A Comprehensive Curriculum",
      content:
        "Beyond traditional subjects, StudyBuddy offers essential life skills like financial literacy, time management, and stress management to prepare students for the challenges of the real world.",
    },
    {
      title: "A Connected Education Ecosystem",
      content:
        "We envision a more interconnected educational landscape where students, teachers, and institutions can collaborate, share resources, and learn from each other.",
    },
    {
      title: "Pride in India",
      content:
        "StudyBuddy is dedicated to promoting Indian culture, history, and values. We believe that by fostering a sense of national pride, we can inspire students to contribute to India's growth and development.",
    },
    {
      title: "Student Empowerment",
      content:
        "We empower students to be active participants in their education. StudyBuddy provides a platform for students to connect, collaborate, and share their ideas and experiences.",
    },
    {
      title: "Healthy Competition",
      content:
        "We believe that healthy competition can drive excellence. StudyBuddy creates a platform for students, teachers, and institutions to compete and showcase their achievements.",
    },
  ];

  // Inline styles
  const styles = {
    carousel: {
      position: "relative",
      width: "100%",
      height: screenType==="phone"
        ? "110vw"
        : screenType==="smallPhone"
        ? autoRotate
          ? "60vh"
          : "70vh"
        : screenType==="tablet"
        ? "60vw"
        : screenType==="tab"
        ? "72vw"
        : "100vh", // Adjust height for phone
      display: "flex",
      flexDirection: screenType==="smallPhone" || screenType==="phone" ? "column" : "",
      justifyContent: "center",
      alignItems: "center",
      // overflow:"hidden",
      marginBottom: screenType==="smallPhone" || screenType==="phone" ? "5vh" : "",
      marginLeft:screenType==="smallPhone"|| screenType==="phone"? autoRotate? "" : "20px":""
    },
    main: {
      position: "absolute",
      // width: screenType==="smallPhone" || screenType==="tab" || screenType==="tablet" ? "80vw" : "80vw", // Set 80vw for phone, 20vw otherwise
      left: screenType==="phone" ? "" : screenType==="smallPhone" ? "" : screenType==="tab" || screenType==="tablet" ? "" : "7vw",
    },
    infoBoxWrapper: {
      position: "relative",
      top: screenType==="smallPhone" || screenType==="phone" ? "0px" : screenType==="tab" || screenType==="tablet" ? "" : -475,
      left: screenType==="tab" || screenType==="tablet" ? "10px" : screenType==="smallPhone" || screenType==="phone" ? "10px" : "45vw",
      backgroundColor: "#fff",
      padding: "12px",
      width: screenType==="smallPhone" || screenType==="phone"  || screenType==="tab" || screenType==="tablet" ? "95%" : "50%",
      borderRadius: "10px",
      boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
      zIndex: 1,
    },
    title: {
      fontSize: screenType==="smallPhone" ? "20px" : "34px",
      // fontFamily: '',
      color: "black",
      //  fontFamily: '',
      gap: "20px",
      zIndex: 100,
    },
    intro: {
      display: "flex",

      fontFamily: "raleway",
      marginLeft: "10px",
      borderRadius: "20px",
      fontSize: screenType==="smallPhone" ? "14px" : "16px", // Adjust font size for intro text
      lineHeight: screenType==="smallPhone" ? "1.2" : "1.5", // Adjust line height for readability
    },
  };

  // Navigation button styling
  const navigationButtonStyling = {
    borderRadius: "50%",
    backgroundColor: "#ffffff",
    color: "#1c364f",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "2rem",
    transition: "background-color 0.3s ease",
  };

  const handleImageClick = (index) => {
    setFocusElement(index); // Update state when an image is clicked
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "100vw",
        // marginLeft: "2vw",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          height: screenType==="tab" || screenType==="tablet" ? "35vw" : screenType==="smallPhone" || screenType==="phone" ? "" : "60vh",
          width: "95vw",
          backgroundColor: "white",
          marginTop: "40px",
          display: "flex",
          flexDirection: screenType==="smallPhone" || screenType==="phone" ? "column" : "row",
          boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)",
          borderRadius: "20px",
          margin: "2vw auto",
          padding: "15px",
        }}
      > 
        <div
          style={{
            height: "100%",
            width: screenType==="smallPhone" ? "100%" : screenType==="phone" ? "100%" : "50vw",

            transform: isInView
              ? screenType==="phone"
                ? " translateY(0%)"
                : screenType==="tab"
                ? "translateY(0%)"
                : screenType==="tablet"
                ? "translateY(0%)"
                : "translateY(0%)"
              : "translateY(-100%)",
            opacity: isInView ? 1 : 0,
            padding: "15px",
            transition: "transform 1s ease-in-out, opacity 1s ease-in-out",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          ref={containerLeftRef}
        >  <motion.p style={{ ...styles.title, alignSelf: "center" }}>
      "Shaping the Future Together – Inspiring Progress Through Innovation and Excellence."
      </motion.p> </div>
        <div
          style={{
            height: "100%",
            width: screenType==="smallPhone" || screenType==="phone" ? "" : "",
            transform: isInView ? "translateX(0)" : "translateX(100%)",
            opacity: isInView ? 1 : 0,
            transition: "transform 1s ease-in-out, opacity 1s ease-in-out",
          }}
          ref={containerRightRef}
        >
          <img
            src={require("../../assets/WebsiteImages/for vision.png")}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      </div>

      <div
        style={{
          transition: "opacity 0.6s ease-in-out",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          borderRadius: "12px",
          display: "flex",
          padding: "15px",
          // margin: "20px 28px",
          width: "95vw",
          display: "flex",
          flexDirection: screenType==="smallPhone" ? "column" : "column",
          justifyContent: "space-between",
          // alignItems: "center",
          backgroundColor: "white",
          margin: "2vw auto",

          boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
        }}
      >
        <motion.p style={{ ...styles.title, alignSelf: "center" }}>
          "Unlock Your Potential with Study Buddy—Your Ultimate Learning
          Companion"
        </motion.p>
        <div>
          <p
            style={{
              fontSize: "35px",
              margin: "0px",
              fontFamily: "",
            }}
          >
            studybuddy vision
          </p>
        </div>
        <p style={styles.intro}>
          "Imagine a world where every student, regardless of their
          circumstances, has the power to unlock their full potential. A world
          where classrooms are not just places of learning, but incubators of
          innovation and social responsibility. A world where India's youth are
          not just consumers of knowledge, but active contributors to the
          nation's progress. This is the vision that drives StudyBuddy. We
          believe that education is the cornerstone of a thriving nation, and
          that every student deserves the opportunity to reach their highest
          aspirations. Join us as we embark on a journey to transform India's
          education landscape and create a brighter future for generations to
          come."
        </p>
      </div>
      <div style={styles.carousel}>
        <div style={styles.main}>
          <div
            style={{
              height: screenType==="tab" ? "" : "40%",
              // width: screenType==="smallPhone" || screenType==="tab" || screenType==="tablet" ? "96%" : "96%",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <FancyCarousel
              images={images}
              setFocusElement={handleImageClick} // Pass function for image click
              carouselRadius={
                screen < 400
                  ? 120
                  : screenType==="smallPhone"||screenType==="phone"
                  ? 150
                  : screenType==="tab"
                  ? 200
                  : screenType==="tablet"
                  ? 250
                  : 200
              } // Adjust radius for phone
              peripheralImageRadius={screenType==="smallPhone"||screenType==="phone"? 30 : screenType==="tab" ? 50 : screenType==="tablet" ? 65 : 50} // Adjust radius for phone
              centralImageRadius={
                screen < 400 ? 60 :screenType==="smallPhone"||screenType==="phone" ? 80 : screenType==="tab" ? 80 : 120
              } // Adjust radius for phone
              focusElementStyling={{ border: "2px solid #ba4949" }}
              autoRotateTime={autoRotate ? 2 : 0}
              borderWidth={4}
              borderHexColor={"#1c364f"} // Corrected hex format
              navigationTextSize={2}
              navigationButtonRadius={20}
              navigationButtonColor={"#1c364f"} // Use '#' for hex
              navigationButtonBgColor={"#ffffff"} // Use '#' for hex
              navigationButtonStyling={navigationButtonStyling}
            />
          </div>
        </div>
      </div>
      <div style={styles.infoBoxWrapper}>
        {info.map((item, index) => {
          if (index === focusElement) {
            // Only show the focused element
            return (
              <div key={index } style={{
                // height:"70vh"
              }}>
                <h3 style={{ fontFamily: "raleway", fontSize:"30px"  }}>{item.title}</h3>
                <p style={{fontSize:"20px"}}>{item.content}</p>
              </div>
            );
          }
          return null; // Avoid rendering other elements
        })}
      </div>
    </div>
  );
};

export default VisionContainer;
