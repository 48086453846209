import React, { useState , useEffect} from 'react';
import useScreenSizes from '../../utils/useScreenSize';
import { useSelector } from 'react-redux';

const CardBox = () => {
 

  const cardDataArrays = [
    [
      {
        imageUrl: "https://via.placeholder.com/150",
        title: "Small Card 1A",
        description: "Description of Small Card 1A",
      },
      {
        imageUrl: "https://via.placeholder.com/150",
        title: "Small Card 1B",
        description: "Description of Small Card 1B",
        isPremium: true,
      },
      {
        imageUrl: "https://via.placeholder.com/150",
        title: "Small Card 1C",
        description: "Description of Small Card 1C",
      },
    ],
   
   
  ];

  const [activeIndex, setActiveIndex] = useState(0); // State to track the active card data
  const screenType = useSelector((state) => state.website.screenType);
 
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  


  },
  buttonContainer: {
    marginBottom: '20px',
  },
 
  cardWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: screenType==="smallPhone"||screenType==="phone"? "column":"",
    justifyContent: 'space-between',
    gap: '20px',
    width:"95%",
  },
  cardBox: {
    width:  screenType==="smallPhone"||screenType==="phone"? "80%":'25%',
    height: screenType==="smallPhone"||screenType==="phone"?"" : screenType==="tab"||screenType==="tablet"? "50vw":"70vh",
    borderRadius: '20px',
    background: 'linear-gradient(170deg, rgba(58, 56, 56, 0.623) 0%, rgb(31, 31, 31) 100%)',
    position: 'relative',
    boxShadow: '0 25px 50px rgba(0,0,0,0.55)',
    cursor: 'pointer',
    transition: 'all 0.3s',
    padding: '10px',
    color: '#fff',
  },
  cardBoxNoPremium: {
    width:  screenType==="smallPhone"||screenType==="phone"? "80%":'25%',
    borderRadius: '20px',
    background: 'linear-gradient(170deg, rgba(58, 56, 56, 0.623) 0%, rgb(31, 31, 31) 100%)',
    position: 'relative',
    boxShadow: '0 25px 50px rgba(0,0,0,0.55)',
    cursor: 'pointer',
    transition: 'all 0.3s',
    height: screenType==="smallPhone"||screenType==="phone"?"" : screenType==="tab"||screenType==="tablet"? "50vw":"70vh",
    padding: '10px',
    color: '#fff',
  },
  span: {
    position: 'absolute',
    overflow: 'hidden',
    width: '150px',
    height: '150px',
    top: '-10px',
    left: '-10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  before: {
    content: '"Premium"',
    position: 'absolute',
    width: '150%',
    height: '40px',
    backgroundImage: 'linear-gradient(45deg, #ff6547 0%, #ffb144 51%, #ff7053 100%)',
    transform: 'rotate(-45deg) translateY(-20px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontWeight: '600',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    boxShadow: '0 5px 10px rgba(0,0,0,0.23)',
  },
  after: {
    content: '""',
    position: 'absolute',
    width: '10px',
    bottom: '0',
    left: '0',
    height: '10px',
    zIndex: '-1',
    boxShadow: '140px -140px #cc3f47',
    backgroundImage: 'linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%)',
  },
  image: {
    width: '100%',
    borderRadius: '10px',
    marginBottom: '10px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  description: {
    fontSize: '14px',
    color: '#ddd',
  },
};
  return (
    <div style={styles.container}>
     
      
   
      <div style={styles.cardWrapper}>
        {cardDataArrays[activeIndex].map((card, index) => (
          <div 
            key={index} 
            style={index === 1 ? styles.cardBox : styles.cardBoxNoPremium} // Apply premium style to second card
          >
            {index === 1 && (
              <span style={styles.span}>
                <span style={styles.before}>Premium</span>
                <span style={styles.after}></span>
              </span>
            )}
            <img src={card.imageUrl} alt={card.title} style={styles.image} />
            <h3 style={styles.title}>{card.title}</h3>
            <p style={styles.description}>{card.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};


export default CardBox;
