import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./reduxStore/store";
import AppContent from "./navigation/AppContent";
import "./App.css";

const Loader = () => {
  const loading = useSelector((state) => state.website.loading);
console.log(loading, "kkkkkkkkkkkkkkk")
  if (!loading) return null;

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        position: "absolute",
        zIndex: 100,
        display: "flex",
        backgroundColor: "white",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={require("./assets/Animations/loader.gif")} alt="Loading..." />
    </div>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Loader />
          <AppContent />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
