import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useScreenSizes from '../../utils/useScreenSize';
import { useSelector } from 'react-redux';

const initialProducts = [
  {
    id: 1,
    name: "StudyBuddy For Student",
    img: require("../../assets/WebsiteImages/student.png"),
  },
  {
    id: 2,
    name: "StudyBuddy For Parent",
    img: require("../../assets/WebsiteImages/parents.png"),
  },
  {
    id: 3,
    name: "StudyBuddy For Teacher",
    img: require("../../assets/WebsiteImages/teacher.png"),
  },
  {
    id: 4,
    name: "StudyBuddy For Institute",
    img: require("../../assets/WebsiteImages/school.png"),
  },
];

const Card = () => {
  const [products] = useState(initialProducts);
  const [hoveredCard, setHoveredCard] = useState(null); // State to track which card is hovered
  const navigate = useNavigate();
 
  const screen = useScreenSizes();
  const location = useLocation();

  // Mapping route to card ID for background color
  const routeToIdMap = {
    '/forStudent': 1,
    '/forParent': 2,
    '/forTeacher': 3,
    '/forInstitute': 4,
  };  const screenType = useSelector((state) => state.website.screenType);

  // Custom click functions for each card
  const handleCardClick = (id) => {
    switch (id) {
      case 1:
        navigate('/forStudent');
        break;
      case 2:
        navigate('/forParent');
        break;
      case 3:
        navigate('/forTeacher');
        break;
      case 4:
        navigate('/forInstitute');
        break;
      default:
        break;
    }
  };

  // Determine the active card ID based on the route
  const activeCardId = routeToIdMap[location.pathname] || null;

  return (
    <div style={{ display: 'flex', flexWrap: screenType==="phone" || screenType==="smallphone" || screenType==="tablet" ? "wrap" : "", justifyContent: 'space-around', width: "100vw",  fontFamily:"raleway" }}>
      {products.map((product) => (
        <div
          key={product.id}
          style={{
            border: '1px solid #ccc',
            borderRadius: '10px',
            margin: '10px',
            padding: '10px',
            width: screenType==="phone" || screenType==="smallphone" ? "159px" : '200px',
            textAlign: 'center',
            boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)",
            transition: 'transform 0.2s, box-shadow 0.2s',
            backgroundColor: activeCardId === product.id ? 'lightblue' : 'white',
          }}
          onClick={() => handleCardClick(product.id)} // Set unique onClick for each card
          onMouseEnter={() => setHoveredCard(product.id)} // Set the hovered card ID
          onMouseLeave={() => setHoveredCard(null)} // Reset on mouse leave
        >
          <div style={{ height: '150px', marginBottom: '10px' }}>
            <img
              src={product.img}
              alt={product.name}
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
                transform: hoveredCard === product.id ? 'scale(1.6)' : 'scale(1)',
                transition: "transform 1s ease"
              }}
            />
          </div>
          <div style={{ fontSize: "20px" }}>
            <p style={{
              fontFamily: "raleway",
              transition: "transform 1s ease",
              color: "black",
            }}>{product.name}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Card;
