import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  headerHeight: "", // Default height
  reach: false, // Add reach to initial state
  theme: "default", // Default theme
  screenType: "desktop",
  loading: false,
};
const websiteSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHeaderHeight: (state, action) => {
      state.headerHeight = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setReach: (state, action) => {
      state.reach = action.payload; // New action to set reach
    },
    setTheme: (state, action) => {
      state.theme = action.payload; // New action to set theme
    },
    setScreenType: (state, action) => {
      state.screenType = action.payload; // Action to update screen type
    },
  },
});
// Exporting actions
export const { setHeaderHeight, setReach, setLoading, setTheme, setScreenType } =
websiteSlice.actions;


export default websiteSlice.reducer;
