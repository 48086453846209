import React from 'react';

const TermsOfUse = () => {
  // Inline styling variable
  const style = {
    container: {
        width: "90%",
        // maxWidth: '800px',
        margin: "40px auto",
        padding: "20px 40px",
        backgroundColor: "#f7f9fc",
        borderRadius: "10px",
        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
        fontFamily: '"Arial", sans-serif',
        color: "#333",
        lineHeight: "1.8",
        letterSpacing: "0.5px",
      },
    heading: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#333',
    },
    subHeading: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginTop: '20px',
      color: '#444',
    },
    section: {
      marginTop: '15px',
    },
    list: {
      marginLeft: '20px',
    },
    listItem: {
      marginBottom: '10px',
    },
    paragraph: {
      marginBottom: '15px',
    },
    disclaimer: {
      fontStyle: 'italic',
      color: '#888',
    },
    footer: {
      marginTop: '30px',
      textAlign: 'center',
      color: '#555',
      fontSize: '14px',
    },
    
    contact: {
        fontWeight: "bold",
        color: "black",
        textDecoration: "none",
        display: "inline-block",
        marginTop: "20px",
        padding: "5px 10px",
        borderRadius: "5px",
        border: "1px solid #1a73e8",
        transition: "background-color 0.3s, color 0.3s",
      },
      title: {
        fontSize: "2.5em",
        color: "black",
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: "20px",
        borderBottom: "2px solid #1a73e8",
        paddingBottom: "10px",
      },
  };

  return (
    <div style={style.container}>
      <h1 style={style.title}>TERMS OF USE</h1>

      <div style={style.section}>
        <h2 style={style.subHeading}>ACCEPTANCE OF TERMS</h2>
        <p style={style.paragraph}>
          1.1. Welcome to Siyaram Nextech Pvt. Ltd. (“Siyaram Nextech,” “we,” “us,” or “our”). These Terms of Use (“Terms”) govern your access to and use of our services (the “Services”). By engaging Siyaram Nextech for any Services, you (the “Client,” “you,” or “your”) acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, you are not authorized to use the Services.
        </p>
        <p style={style.paragraph}>
          1.2. This Terms of Services is an electronic record in the form of an electronic contract formed under the Information Technology Act, 2000, rules made thereunder, and any other applicable statutes, as amended from time to time.
        </p>
      </div>

      <div style={style.section}>
        <h2 style={style.subHeading}>DEFINITIONS</h2>
        <ul style={style.list}>
          <li style={style.listItem}><strong>Client:</strong> Refers to the person or entity engaging Siyaram Nextech Pvt. Ltd. (“Siyaram Nextech,” “we,” “us,” or “our”) for the Services.</li>
          <li style={style.listItem}><strong>Services:</strong> The IT development, security, maintenance, and other IT solution services offered by Siyaram Nextech.</li>
          <li style={style.listItem}><strong>Project:</strong> Refers to a specific engagement between Siyaram Nextech and a Client for the delivery of Services.</li>
          <li style={style.listItem}><strong>Deliverables:</strong> The tangible or intangible outputs produced by Siyaram Nextech in connection with a Project, as specified in the Project agreement.</li>
          <li style={style.listItem}><strong>Intellectual Property Rights:</strong> Patents, copyrights, trademarks, trade secrets, and all other intellectual property rights.</li>
        </ul>
      </div>

      <div style={style.section}>
        <h2 style={style.subHeading}>USE OF THE SERVICES</h2>
        <p style={style.paragraph}>
          Siyaram Nextech Pvt. Ltd. (“Siyaram Nextech,” “we,” “us,” or “our”) endeavors to provide high-quality IT development, security, maintenance, and other IT solution services (the “Services”) to our Clients.
        </p>
        <p style={style.paragraph}>
          Clients engaging Siyaram Nextech for Services must be authorized to enter into legally binding agreements. In most cases, this means the Client will be a company or other legal entity.
        </p>
        <p style={style.paragraph}>
          Certain Services may require the creation of a user account. The Client is responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their account.
        </p>
        <p style={style.paragraph}>
          Siyaram Nextech grants the Client a non-exclusive, non-transferable, non-sublicensable right to access and use the Services solely for the purpose of completing the Project as outlined in the project agreement. This right is subject to the terms and conditions set forth in these Terms of Use.
        </p>
        <p style={style.paragraph}>
          Siyaram Nextech may integrate with third-party applications or services to better serve our Clients. The Client is responsible for understanding and complying with the terms of service of any such third-party applications or services.
        </p>
        <p style={style.paragraph}>
          Siyaram Nextech may implement access controls to restrict access to certain features or functionalities of the Services based on the Client’s project requirements and user roles.
        </p>
        <p style={style.paragraph}>
          Siyaram Nextech reserves the right to refuse service, terminate Client accounts, or remove content from the Services at our sole discretion. This may be done for reasons such as a violation of these Terms of Use, non-payment, or other legitimate business reasons.
        </p>
      </div>

      <div style={style.section}>
        <h2 style={style.subHeading}>CONTENT AND INTELLECTUAL PROPERTY</h2>
        <p style={style.paragraph}>
          Siyaram Nextech Pvt. Ltd. (“Siyaram Nextech,” “we,” “us,” or “our”) owns all intellectual property rights associated with the Services and any content provided through the Services (collectively, the “Content”).
        </p>
        <h3 style={style.subHeading}>Ownership of Content</h3>
        <p style={style.paragraph}>
          All Content provided by Siyaram Nextech on the Services is our exclusive property. You are granted a limited, non-exclusive, and non-transferable license to access and use the Content solely in connection with your authorized use of the Services.
        </p>
        <h3 style={style.subHeading}>Restriction of Use</h3>
        <p style={style.paragraph}>You may not:</p>
        <ul style={style.list}>
          <li style={style.listItem}>Reproduce, modify, distribute, or create derivative works of the Content without our prior written consent.</li>
          <li style={style.listItem}>Reverse engineer, decompile, or disassemble the Services.</li>
          <li style={style.listItem}>Remove or obscure any proprietary notices from the Services.</li>
          <li style={style.listItem}>Use the Services for any illegal or unauthorized purpose.</li>
        </ul>
        <h3 style={style.subHeading}>User Generated Content</h3>
        <p style={style.paragraph}>
          The Services may allow you to submit or post content (your “User Content”). You retain all ownership rights to your User Content. However, by submitting User Content, you grant Siyaram Nextech a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to use, modify, publicly perform, publicly display, reproduce, distribute, and re-distribute your User Content on and through the Services.
        </p>
        <h3 style={style.subHeading}>Content Monitoring and Removal</h3>
        <p style={style.paragraph}>
          Siyaram Nextech reserves the right, but does not assume the obligation, to monitor, edit, or remove User Content that we deem to be in violation of these Terms of Use or otherwise objectionable. We may also remove User Content for any other reason, at any time, without notice.
        </p>
        <h3 style={style.subHeading}>User Responsibility</h3>
        <p style={style.paragraph}>
          You are solely responsible for your User Content and any consequences of its use. You represent and warrant that you have all necessary rights and permissions to submit User Content and that your User Content does not violate any third-party rights.
        </p>
        <h3 style={style.subHeading}>Limitation of Liabilities</h3>
        <p style={style.paragraph}>
          Siyaram Nextech will not be liable for any damages arising out of or related to your use of the Content, including but not limited to indirect, incidental, consequential, punitive, or special damages.
        </p>
      </div>

      <div style={style.section}>
        <h2 style={style.subHeading}>USER CONDUCT</h2>
        <p style={style.paragraph}>
          Siyaram Nextech Pvt. Ltd. (“Siyaram Nextech,” “we,” “us,” or “our”) expects all users of the Services (the “Users”) to conduct themselves in a professional and respectful manner.
        </p>
        <h3 style={style.subHeading}>Compliance with Law</h3>
        <p style={style.paragraph}>
          Users are required to comply with all applicable laws and regulations in connection with their use of the Services.
        </p>
        <h3 style={style.subHeading}>Prohibited Activities</h3>
        <p style={style.paragraph}>
          Users are prohibited from engaging in any of the following activities:
        </p>
        <ul style={style.list}>
          <li style={style.listItem}>Uploading or transmitting any content that is illegal, harmful, threatening, abusive, harassing, defamatory, obscene, hateful, or racially or ethnically offensive.</li>
          <li style={style.listItem}>Infringing on the intellectual property rights of others.</li>
          <li style={style.listItem}>Disrupting or interfering with the Services or any servers or networks connected to the Services.</li>
          <li style={style.listItem}>Introducing viruses or other malicious code into the Services.</li>
          <li style={style.listItem}>Spamming, phishing, or engaging in fraudulent activities through the Services.</li>
        </ul>
      </div>
      <div style={style.section}>
        <h2 style={style.subHeading}>ENFORCEMENT</h2>
        <p style={style.paragraph}>
          Siyaram Nextech reserves the right to take any action we deem necessary to enforce these Terms of Use, including but not limited to:
        </p>
        <ul style={style.list}>
          <li style={style.listItem}>Terminating your access to the Services</li>
          <li style={style.listItem}>Removing any content you submit that violates these Terms of Use</li>
          <li style={style.listItem}>Reporting you to law enforcement</li>
        </ul>
      </div>
      <div style={style.section}>
        <h2 style={style.subHeading}>DISCLAIMER OF LIABILITY</h2>
        <p style={style.paragraph}>
          Siyaram Nextech is not responsible for the conduct of any User. We are not liable for any damages arising out of or related to your use of the Services by you or any other User.
        </p>
      </div>

      <div style={style.section}>
        <h2 style={style.subHeading}>USER INDEMNITY</h2>
        <p style={style.paragraph}>
          You agree to indemnify and hold harmless Siyaram Nextech, our officers, directors, employees, agents, and licensors from any claims, demands, losses, liabilities, costs, or expenses (including reasonable attorneys’ fees) arising out of or related to your violation of these Terms of Use.
        </p>
      </div>
      <div style={style.section}>
        <h2 style={style.subHeading}>PRIVACY</h2>
        <p style={style.paragraph}>
          Our Privacy Policy explains how we collect, use, and protect your personal information. You agree to the terms outlined in our Privacy Policy. By using the Services, you consent to the collection and use of your personal information as described in the Privacy Policy.
        </p>
      </div>

      <div style={style.section}>
        <h2 style={style.subHeading}>EDUCATIONAL CONTENT DISCLAIMER</h2>
        <p style={style.paragraph}>
          Siyaram Nextech may provide access to educational content, either through our own resources or third-party sources. The following disclaimers apply to all educational content:
        </p>

        <h3 style={style.subHeading}>Educational Purpose</h3>
        <p style={style.paragraph}>
          The content is for educational purposes only and does not promote any specific religious, political, or defamatory agenda.
        </p>

        <h3 style={style.subHeading}>Accuracy and Validity</h3>
        <p style={style.paragraph}>
          While we strive for accuracy, the content is subject to change, and we cannot guarantee its absolute correctness or completeness. Users are encouraged to verify information independently and consult with experts for critical subjects.
        </p>

        <h3 style={style.subHeading}>Personal Interpretation</h3>
        <p style={style.paragraph}>
          The content may be subject to personal interpretation and should not be taken as the sole or authoritative perspective. Users should engage in critical thinking, form their own opinions, and conduct further research as needed.
        </p>

        <h3 style={style.subHeading}>No Defamatory or Libelous Intent</h3>
        <p style={style.paragraph}>
          The content is not intended to defame or slander any individual or entity. We respect the rights and dignity of all.
        </p>

        <h3 style={style.subHeading}>User Responsibility</h3>
        <p style={style.paragraph}>
          Users are responsible for their conduct within the educational community of the Services. We expect respectful, ethical, and responsible behavior. Inappropriate content will be moderated and removed.
        </p>

        <h3 style={style.subHeading}>Legal Obligations</h3>
        <p style={style.paragraph}>
          This disclaimer does not waive or alter any legal rights, protections, or responsibilities applicable to the Services or its users. If you have concerns about the legality of any content or activity, please seek legal counsel.
        </p>

        <h3 style={style.subHeading}>Contact Information</h3>
        <p style={style.paragraph}>
          If you have any concerns or questions regarding the educational content provided by the Application, please contact us at <a href="mailto:siyaramgroup.nextech@gmail.com">siyaramgroup.nextech@gmail.com</a>.
        </p>
      </div>


      <div style={style.section}>
        <h2 style={style.subHeading}>LIMITATION OF LIABILITY</h2>
        <p style={style.paragraph}>
          Siyaram Nextech Pvt. Ltd. (“Siyaram Nextech,” “we,” “us,” or “our”) strives to deliver high-quality IT development, security, maintenance, and other IT solution services (the “Services”). However, we cannot guarantee perfect performance and limit our liability as follows:
        </p>
        <ul style={style.list}>
          <li style={style.listItem}>Damages for loss of profits, data, or goodwill.</li>
          <li style={style.listItem}>Business interruption.</li>
          <li style={style.listItem}>Personal injury or property damage.</li>
          <li style={style.listItem}>Damages arising out of your reliance on the information or content provided through the Services.</li>
          <li style={style.listItem}>Damages arising out of any third-party content or services accessed through the Services.</li>
        </ul>
      </div>

      <div style={style.section}>
        <h2 style={style.subHeading}>TERMINATION</h2>
        <p style={style.paragraph}>
          Siyaram Nextech Pvt. Ltd. (“Siyaram Nextech,” “we,” “us,” or “our”) reserves the right to terminate or suspend your access to the Services at any time, with or without notice, for any reason, including but not limited to:
        </p>
        <ul style={style.list}>
          <li style={style.listItem}>Violation of these Terms of Use</li>
          <li style={style.listItem}>Non-payment of fees (if applicable)</li>
          <li style={style.listItem}>Illegal or unauthorized activity</li>
          <li style={style.listItem}>Request from law enforcement or other government agency</li>
          <li style={style.listItem}>Inactivity for an extended period</li>
        </ul>
        <p style={style.paragraph}>
          Certain provisions of these Terms of Use, such as those relating to ownership of intellectual property, limitation of liability, and indemnification, shall survive the termination of these Terms of Use.
        </p>
      </div>
      <div style={style.section}>
        <h2 style={style.subHeading}>CHANGES TO TERMS</h2>
        <p style={style.paragraph}>
          Siyaram Nextech Pvt. Ltd. (“Siyaram Nextech,” “we,” “us,” or “our”) reserves the right to modify these Terms of Use at any time. We will make reasonable efforts to notify you of any significant changes by posting a notice on the Services or sending you an email.
        </p>
        <p style={style.paragraph}>
          Your continued use of the Services after the revised Terms of Use are posted constitutes your acceptance of the revised terms. You are encouraged to review the Terms of Use periodically for any updates or changes.
        </p>
      </div>
      <div style={style.section}>
        <h2 style={style.subHeading}>GOVERNING LAW</h2>
        <p style={style.paragraph}>
          These Terms of Services shall be governed and construed in accordance with the laws of India, which governing law applies to the User Agreement without regard to its conflict of law provisions, and any disputes relating to these Terms of Services and User Agreements will be subject to the exclusive jurisdiction of the courts of Rajasthan, India.
        </p>
      </div>

      <a href="mailto:Siyaramgroup.nextech@gmail.com" style={style.contact}>
          Siyaramgroup.nextech@gmail.com
        </a>
    </div>
  );
};

export default TermsOfUse;
