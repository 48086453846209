import React, { useState, useEffect, useRef } from "react";
import useScreenSizes from "../../utils/useScreenSize";
import { useSelector } from "react-redux";

const PricingCard = ({
  baseCardStyle,
  buttonStyle,
  buttonHoverStyle,
  headerStyle,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [smallCardFlipped, setSmallCardFlipped] = useState(false);
  
  // Screen size states
  const screen = useScreenSizes();

  const [opacity, setOpacity] = useState(1);
  const [isInView, setIsInView] = useState(false);
  const screenType = useSelector((state) => state.website.screenType);
  const [hasAnimated, setHasAnimated] = useState(false); // Track if animation has run
  const containerLeftRef = useRef(null);
  const containerRightRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !hasAnimated) { // Check if not animated yet
          setIsInView(true);
          setHasAnimated(true); // Set animated to true after first animation
        }
      });
    });

    if (containerLeftRef.current) observer.observe(containerLeftRef.current);
    if (containerRightRef.current) observer.observe(containerRightRef.current);

    return () => {
      if (containerLeftRef.current) observer.unobserve(containerLeftRef.current);
      if (containerRightRef.current) observer.unobserve(containerRightRef.current);
    };
  }, [hasAnimated]); // Add hasAnimated as a dependency

  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const isScrollingUp = prevScrollPos > currentScrollPos;

    setOpacity(isScrollingUp ? 1 : 0);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);
  console.log(handleScroll)

  const handleButtonClick = (index) => {
    setActiveIndex(index); // Update the active index based on button clicked
  };
  // Sample data for small cards based on button index
  const smallCardDataSets = [
    [
      {
        imageUrl: "https://via.placeholder.com/150",
        title: "Small Card 1A",
        description: "Description of Small Card 1A",
        
      },
      {
        imageUrl: "https://via.placeholder.com/150",
        title: "Small Card 1B",
        description: "Description of Small Card 1B",
      },
      {
        imageUrl: "https://via.placeholder.com/150",
        title: "Small Card 1C",
        description: "Description of Small Card 1C",
      },
    ],
    [
      {
        imageUrl: "https://via.placeholder.com/150",
        title: "Small Card 2A",
        description: "Description of Small Card 2A",
      },
      {
        imageUrl: "https://via.placeholder.com/150",
        title: "Small Card 2B",
        description: "Description of Small Card 2B",
      },
    ],
    [
      {
        imageUrl: "https://via.placeholder.com/150",
        title: "Small Card 3A",
        description: "Description of Small Card 3A",
      },
      {
        imageUrl: "https://via.placeholder.com/150",
        title: "Small Card 3B",
        description: "Description of Small Card 3B",
      },
    ],
  ]; 
  const smallCardbuttons =[
    "ERP", "LMS", "WEBSITE"
  ]
const styles={
  leftContainer:{
width:"50vw",
height:"100%",
width:screenType==="smallphone"||screenType==="phone"? "":"50vw",
transform: isInView ? 'translateX(0)' : 'translateX(-100%)',
opacity: isInView ?1 : 0,
transition: 'transform 1s ease-in-out,opacity 1s ease-in-out',


  },
  RightContainer:{
width:screenType==="smallphone"||screenType==="phone"? "":"50vw",
height:"100%",
// backgroundColor:"blue",
transform: isInView ? 'translateX(0)' : 'translateX(100%)',
opacity: isInView ?1 : 0,
transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
display:"flex",
alignItems:"center"
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
  },
  cardBox: {
    width: '100%',
    height: screenType==="smallphone"||screenType==="phone"?"70vh" : '100%',
    borderRadius: '20px',
    background: 'linear-gradient(170deg, rgba(58, 56, 56, 0.623) 0%, rgb(31, 31, 31) 100%)',
    position: 'relative',
    boxShadow: '0 25px 50px rgba(0,0,0,0.55)',
    cursor: 'pointer',
    transition: 'all 0.3s',
    marginBottom:screenType==="smallphone"||screenType==="phone"?"5vh":"",
  },
  cardBoxNoPremium: {
    width: '100%',
    height: screenType==="smallphone"||screenType==="phone"?"70vh" : '100%',
    borderRadius: '20px',
    background: 'linear-gradient(170deg, rgba(58, 56, 56, 0.623) 0%, rgb(31, 31, 31) 100%)',
    position: 'relative',
    boxShadow: '0 25px 50px rgba(0,0,0,0.55)',
    cursor: 'pointer',
    marginBottom:screenType==="smallphone"||screenType==="phone"?"5vh":"",
    transition: 'all 0.3s',
  },
  span: {
    position: 'absolute',
    overflow: 'hidden',
    width: '150px',
    height: '150px',
    top: '-10px',
    left: '-10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  before: {
    content: '"Premium"',
    position: 'absolute',
    width: '150%',
    height: '40px',
    backgroundImage: 'linear-gradient(45deg, #ff6547 0%, #ffb144 51%, #ff7053 100%)',
    transform: 'rotate(-45deg) translateY(-20px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontWeight: '600',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    boxShadow: '0 5px 10px rgba(0,0,0,0.23)',
  },
  after: {
    content: '""',
    position: 'absolute',
    width: '10px',
    bottom: '0',
    left: '0',
    height: '10px',
    zIndex: '-1',
    boxShadow: '140px -140px #cc3f47',
    backgroundImage: 'linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%)',
  },
}
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop:"40px", fontFamily:"raleway"  }}>
      <div style={{height:screenType==="tab"||screenType==="tablet"? "50vw": screenType==="smallphone"||screenType==="phone"? "":"60vh", width:"95vw",   backgroundColor: "white", display:"flex" ,  display: "flex", flexDirection:screenType==="phone"||screenType==="smallphone"?"column" : "row",  boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)", borderRadius:"10px", overflow:"hidden", padding:"15px"}}>
        <div style={styles.leftContainer}  ref={containerLeftRef} >
<img src={require("../../assets/WebsiteImages/PRICING.png")} style={{  height:"100%", objectFit:"contain", width:"100%"

}} />
        </div>
        <div style={styles.RightContainer} ref={containerRightRef}>
<p 
style={{
  fontSize:"30px", 
  marginLeft:"20px",
  marginTop:"50px",
  fontFamily:""
}}>"Transparent, Flexible Pricing – Quality Solutions Tailored to Meet Your Needs and Budget."</p>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "transparent",
          // position: "fixed",
          // zIndex: 1,
          top: 0,
          marginTop: screenType==="smallphone" || screenType==="phone" ? "5vh" : screenType==="tab" ? "5vh" : "5vh",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          opacity: opacity,
          transition: "opacity 0.5s ease",
          ...headerStyle,
        }}
      >
        {smallCardbuttons.map((item , index) => (
          <button
            key={index}
            style={{
              ...buttonStyle,
              padding: "10px 20px",
              margin: "10px",
              borderRadius: "10px",
              border: "none",
              // width:"10vw",
              color: "#fff",
              fontSize: "1rem",
              cursor: "pointer",
              opacity: opacity,
              transition: "opacity 0.5s ease",
              backgroundImage: "linear-gradient(45deg, #ffebee, #9B59B6)",
              transition: "background-color 0.3s, transform 0.3s",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundImage =
                buttonHoverStyle?.backgroundColor || "linear-gradient(45deg, #8E44AD, #3498DB)";
              // e.currentTarget.style.transform = "scale(1.05)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundImage =
                buttonStyle?.backgroundColor || "linear-gradient(45deg, #3498DB, #9B59B6)";
              // e.currentTarget.style.transform = "scale(1)";
            }}
            onClick={() => {
              setActiveIndex(index);
              setSmallCardFlipped(true); // Trigger flip for small cards
              setTimeout(() => {
                setSmallCardFlipped(false); // Reset flipping state after animation
              }, 200); // Match with CSS transition duration
            }}
          >
          {item}
          </button>
        ))}
      </div>

      <div
        style={{
          ...baseCardStyle,
          position: "relative",
          perspective: "1000px",
          marginTop: "2vh", // Add space for fixed header
          margin:"2vw auto",
          // padding: "15px",
          
        }}
      >
        <div
          style={{
            position: "relative",
            width: "95vw",

            height: screenType==="smallphone" || screenType==="phone" ? "" : "45vw",
            borderRadius: "12px",
            boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.2)",
            backgroundColor: "white",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin:"2vw auto",
          
            padding: "15px",
            // borderImage: "linear-gradient(45deg, #1ABC9C, #3498DB) 1", // Gradient border
            borderWidth: "5px",
            borderStyle: "solid",
            transition: "transform 0.5s ease, box-shadow 0.5s ease",
          }}
          onMouseEnter={(e) => {
            // e.currentTarget.style.transform = "scale(1.03)";
            e.currentTarget.style.boxShadow = "0px 10px 30px rgba(0, 0, 0, 0.3)";
          }}
          onMouseLeave={(e) => {
            // e.currentTarget.style.transform = "scale(1)";
            e.currentTarget.style.boxShadow = "0px 6px 20px rgba(0, 0, 0, 0.2)";
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: screenType==="smallphone" || screenType==="phone" ? "column" : "row",
              height: "90%",
              width: "100%",
              justifyContent:"space-evenly"
            }}
          >
            {smallCardDataSets[activeIndex].map((card, index) => (
              <div
                key={index}
              
                style={{
                  width: screenType==="smallphone" || screenType==="phone" ? "100%" : "25%",
                  height: screenType==="smallphone" || screenType==="phone" ? "100%" : "95%",
                  marginTop: "10px",
                  textAlign: "center",
                  // backgroundColor: smallCardFlipped
                  //   ? "rgba(255, 255, 255, 0.8)"
                  //   : "#3498DB",
                  // borderRadius: "10px",
                  // padding: "10px",
                  // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.6s, scale 0.3s",
                  transform: smallCardFlipped ? "rotateY(180deg)" : "rotateY(0deg)",
                  backfaceVisibility: "hidden",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  // boxShadow:  "10px 10px 15px rgba(0, 0, 0, 0.3)",
                  

                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                }}
              >
                   <div 
            key={index} 
            style={index === 1 ? styles.cardBox : styles.cardBoxNoPremium} // Apply premium style to second card
          >
            {index === 1 && (
              <span style={styles.span}>
                <span style={styles.before}>Premium</span>
                <span style={styles.after}></span>
              </span>
            )}
                <img src={card.imageUrl} alt={card.title} style={{ width: "100px", height: "100px" }} />
                <h4>{card.title}</h4>
                <p>{card.description}</p>
                
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingCard;