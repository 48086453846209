import React from 'react';
import useScreenSizes from '../../utils/useScreenSize';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const SmartClass = () => {
  // Data array for all management systems
  const Data = [
    {
      title: 'Interactive Smart Boards',
      explanation:
        'The interactive smart boards in the Study Buddy smart class create an engaging and immersive learning environment. These boards use touchscreen technology, allowing both teachers and students to actively participate in lessons. Teachers can write, draw, and annotate directly on the board, while students can engage with the content, making learning more interactive and fun.',
      features: [
        {
          title: 'Touchscreen Technology',
          content: 'The smart boards allow teachers to directly interact with the screen, making it easy to highlight key points, navigate between slides, or draw diagrams.',
          image: require("../../assets/WebsiteImages/touchScreenTechnology.png"),   
        },
        {
          title: 'Real-time Annotations',
          content: 'Teachers can annotate lessons in real-time, adding notes, explanations, or highlights as they teach.',
          image: require("../../assets/WebsiteImages/realTimeAnnotations.png"),   
        },
        {
          title: 'Content Sharing',
          content: 'Teachers can share the screen content with students in real-time, making it easier for them to revise and study later.',
          image: require("../../assets/WebsiteImages/contentSharing.png"),   
        },
      ],
    },
    {
      title: 'High-Quality Projectors',
      explanation:
        'Study Buddy integrates high-quality projectors into the classroom setup to project lesson content onto large screens. These projectors help enhance the visibility of lessons for all students, even in large classrooms.',
      features: [
        {
          title: 'Crystal-Clear Visuals',
          content: 'The projectors ensure that all students, even those sitting at the back, can clearly see the lesson material.',
          image: require("../../assets/WebsiteImages/crystalClearVisuals.png"),   
        },
        {
          title: 'Multi-Media Integration',
          content: 'The projectors allow teachers to use videos, images, and animations, making learning more dynamic.',
          image: require("../../assets/WebsiteImages/multiMediaIntergration.png"),   
        },
        {
          title: 'Enhanced Classroom Setup',
          content: 'Projectors allow content to be displayed on a larger screen, making learning inclusive for everyone in large classrooms.',
          image: require("../../assets/WebsiteImages/enhancedClassroomSetup.png"),   
        },
      ],
    },
    {
      title: 'Live Video Streaming for Remote Learning',
      explanation:
        'Live video streaming enables real-time connection between students and teachers, especially helpful for remote or hybrid learning environments.',
      features: [
        {
          title: 'Instant Connection with Teachers',
          content: 'Students can attend live classes and directly communicate with their teachers.',
          image: require("../../assets/WebsiteImages/instantConnectionWithTeacher.png"),   
        },
        {
          title: 'Interactive Q&A Sessions',
          content: 'Students can participate in interactive question-and-answer sessions during live classes.',
          image: require("../../assets/WebsiteImages/interactiveQ&ASession.png"),   
        },
        {
          title: 'Session Recordings',
          content: 'Teachers can record live sessions, and students can access these recordings later for revision.',
          image: require("../../assets/WebsiteImages/sessionRecordings.png"),   
        },
      ],
    },
    {
      title: 'Augmented Reality (AR) Learning Tools',
      explanation:
        'AR tools bring subjects to life by visualizing them in 3D, helping students better understand complex concepts.',
      features: [
        {
          title: 'Immersive Learning Experiences',
          content: 'AR allows students to interact with 3D models like anatomical structures or historical artifacts.',
          image: require("../../assets/WebsiteImages/immersiveLearningExperinces.png"),   
        },
        {
          title: 'Enhanced Conceptual Understanding',
          content: 'Students can visualize abstract concepts like molecular compounds in 3D.',
          image: require("../../assets/WebsiteImages/enhancedConceptualUnderstanding.png"),   
        },
        {
          title: 'Interactive Exercises',
          content: 'AR-based learning includes interactive quizzes and simulations for hands-on learning.',
          image: require("../../assets/WebsiteImages/interactiveExercise.png"),   
        },
      ],
    },
    {
      title: 'Digital Whiteboards and Collaborative Tools',
      explanation:
        'Digital whiteboards allow students to collaborate on assignments in real-time, promoting teamwork and creativity.',
      features: [
        {
          title: 'Group Projects and Brainstorming',
          content: 'The digital whiteboard enables students to brainstorm and collaborate on projects in a virtual space.',
          image: require("../../assets/WebsiteImages/groupsProjectsAndBrainstorming.png"),   
        },
        {
          title: 'Real-Time Notes',
          content: 'Students and teachers can take real-time notes on the whiteboard, making it easy to refer back to them.',
          image: require("../../assets/WebsiteImages/realTimeNotes.png"),   
        },
        {
          title: 'Document Sharing and Editing',
          content: 'The digital whiteboard allows students to share documents and edit them collaboratively.',
          image: require("../../assets/WebsiteImages/330909630_3c91c898-559e-449d-b6c6-c75491511a74.jpg"),   
        },
      ],
    },
    {
      title: 'AI-Powered Learning Assistance',
      explanation:
        'AI-powered assistance personalizes the learning experience for each student, offering instant feedback and suggestions for improvement.',
      features: [
        {
          title: 'Personalized Learning Paths',
          content: 'AI creates customized learning paths based on individual student performance.',
          image: require("../../assets/WebsiteImages/personalizedLearningPaths (1).png"),   
        },
        {
          title: 'Instant Doubt Resolution',
          content: 'The AI assistant is available 24/7 to resolve any doubts students may have.',
          image: require("../../assets/WebsiteImages/instantDoutResolution.png"),   
        },
        {
          title: 'Performance Analytics',
          content: 'AI tracks student performance and generates detailed reports to highlight areas of improvement.',
          image: require("../../assets/WebsiteImages/performanceAnalytics.png"),   
        },
      ],
    },
    {
      title: 'Digital Assessment and Feedback System',
      explanation:
        'The digital assessment system in Study Buddy makes evaluating student performance efficient and transparent.',
      features: [
        {
          title: 'Immediate Feedback on Quizzes',
          content: 'The system provides instant results and feedback on quizzes, helping students improve.',
          image: require("../../assets/WebsiteImages/immediateFeedbackOnQuizzes.png"),   
        },
        {
          title: 'Performance Tracking',
          content: 'The system tracks performance over time and helps adjust teaching methods accordingly.',
          image: require("../../assets/WebsiteImages/performanceTracking.png"),   
        },
        {
          title: 'Parent and Teacher Access',
          content: 'Parents and teachers can access performance reports to provide necessary support.',
          image: require("../../assets/WebsiteImages/parentsAndTeacherAccess.png"),   
        },
      ],
    },
  ];

  const screen = useScreenSizes();
  
  const [hoveredCardIndex, setHoveredCardIndex] = useState();

  const screenType = useSelector((state) => state.website.screenType);

  const styles = {
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingtop:  screenType==="smallPhone"||screenType==="phone"?"7vh" : '10vh', 
       },
    cardContainer: {
      display: 'flex',
      justifyContent: screenType==="smallPhone" || screenType==="phone" ? "center" : 'space-between',
      width: screenType==="smallPhone" || screenType==="phone" ? "100vw" : '95vw',
      flexDirection: screenType==="smallPhone" || screenType==="phone" ? "column" : "",
      height: screenType==="smallPhone" || screenType==="phone" ? "100vh" : '50vh',
      marginBottom: "25px"
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: screenType==="smallPhone" || screenType==="phone" ? "90vw" : '30%',
      backgroundColor: '#f0f0f0',
      borderRadius: '10px',
      overflowY: 'scroll',
      boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)",
      marginBottom: "25px",
      transition: 'transform 0.3s ease', // Smooth transition for scaling
    },
    cardHover: {
      transform: 'scale(1.05)', // Scale up the card on hover
    },
    cardImage: {
      height: '70%',
      overflow: 'hidden',
    },
    cardContent: {
      height: '30%',
      padding: '10px',
      backgroundColor: '#fff',
      textAlign: 'center',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    dataContainer: {
      marginTop: screenType==="smallPhone" ? "25vw":screenType==="phone"?"25vw" : screenType==="tab"? "15vw":screenType==="tablet"?"12vw": '2vw',  
            width: screenType==="smallPhone" || screenType==="phone" ? "98vw" : '95vw',
      padding: '20px',
      backgroundColor: '#fff',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
    },
  };
  
  return (
    <div style={styles.mainContainer}>
      {Data.map((system, systemIndex) => (
        <div key={systemIndex} style={styles.dataContainer}>
         <p style={{fontSize:"25px", fontWeight:"bold" ,}}>{system.title}</p>
         <div style={styles.cardContainer}>
            {system.features.map((feature, index) => (
              <div
                key={index}
                style={{
                  ...styles.card,
                  ...(hoveredCardIndex === index ? styles.cardHover : {}),
                }}
                onMouseEnter={() => setHoveredCardIndex(index)}
                onMouseLeave={() => setHoveredCardIndex(null)}
              >
                <div style={styles.cardImage}>
                  <img
                    src={feature.image} // Dynamic image loading
                    alt={feature.title}
                    style={styles.image}
                  />
                </div>
                <div style={styles.cardContent}>
                <p style={{fontSize:"20px", margin:"0px"}}>{feature.title}</p>
                                  <p>{feature.content}</p>
                </div>
              </div>
            ))}
          </div>
          <p>{system.explanation}</p>
        </div>
      ))}
    </div>
  );
};

export default SmartClass;
