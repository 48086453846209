import React from "react";

const PrivacyPolicy = () => {
  const styles = {
    container: {
      width: "90%",
      // maxWidth: '800px',
      margin: "40px auto",
      padding: "20px 40px",
      backgroundColor: "#f7f9fc",
      borderRadius: "10px",
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
      fontFamily: '"Arial", sans-serif',
      color: "#333",
      lineHeight: "1.8",
      letterSpacing: "0.5px",
    },
    title: {
      fontSize: "2.5em",
      color: "black",
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: "20px",
      borderBottom: "2px solid #1a73e8",
      paddingBottom: "10px",
    },
    section: {
      margin: "30px 0",
    },
    heading: {
      color: "black",
      fontWeight: "700",
      fontSize: "1.5em",
      marginBottom: "10px",
      borderBottom: "1px solid #ddd",
      paddingBottom: "5px",
    },
    paragraph: {
      margin: "15px 0",
      fontSize: "1.1em",
      color: "#555",
    },
    note: {
      backgroundColor: "#eaf3ff",
      borderLeft: "5px solid #1a73e8",
      padding: "15px",
      margin: "20px 0",
      fontStyle: "italic",
      color: "black",
    },
    list: {
      listStyleType: "disc",
      paddingLeft: "20px",
      fontSize: "1.1em",
      color: "#555",
      marginTop: "10px",
    },
    listItem: {
      marginBottom: "8px",
    },
    contact: {
      fontWeight: "bold",
      color: "black",
      textDecoration: "none",
      display: "inline-block",
      marginTop: "20px",
      padding: "5px 10px",
      borderRadius: "5px",
      border: "1px solid #1a73e8",
      transition: "background-color 0.3s, color 0.3s",
    },
    contactHover: {
      backgroundColor: "#1a73e8",
      color: "#fff",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Privacy Policy</h1>
      <div style={styles.section}>
        <h2 style={styles.heading}>Introduction</h2>
        <p style={styles.paragraph}>
          This Privacy Policy applies to the services developed and operated by
          SIYARAM NEXTECH Pvt. Ltd. (“we”, “our” or “us”). Siyaram Nextech Pvt
          Ltd is an IT company providing cutting-edge technology solutions, IT
          development, security, and maintenance services. This policy applies
          to all clients (“you”, “your” and/or “Users”) of the company.
        </p>
        <p style={styles.paragraph}>
          We are committed to protecting the privacy of our clients, and we
          ensure that their personal data is collected, used, and shared
          responsibly and securely. This Privacy Policy describes how we
          collect, use, and share your personal data when you use our services.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.heading}>
          Consent for Siyaram Nextech Pvt. Ltd. Client Privacy Policy
        </h2>
        <p style={styles.paragraph}>
          This Privacy Policy outlines the data collection and usage practices
          of Siyaram Nextech Pvt. Ltd. (“Siyaram Nextech,” “we,” “us,” or “our”)
          in connection with the services we provide to our clients (the
          “Client,” “you,” or “your”).
        </p>
        <p>
          By engaging Siyaram Nextech for IT development, security, maintenance,
          or other IT solution services (the “Services”), you acknowledge that
          you have read, understood, and agree to this Privacy Policy. This
          includes your agreement to the collection, use, disclosure, and
          storage of your personal data as described herein.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.heading}>Client Representatives</h2>
        <p style={styles.paragraph}>
          If you are acting as a representative on behalf of your organization,
          you confirm that you have the necessary authority to bind your
          organization to this Privacy Policy.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.heading}>Your Responsibilities</h2>
        <p style={styles.paragraph}>
          You are responsible for ensuring that any personal data you share with
          Siyaram Nextech Pvt. Ltd. complies with all applicable laws and
          regulations. You should also obtain any necessary consents from
          individuals whose personal data may be included in the project scope.
        </p>
        <div style={styles.note}>
          <p>
            This Privacy Policy applies solely to Client data collected and used
            by Siyaram Nextech in the course of providing Services. For
            information regarding the privacy practices of Siyaram Nextech’s own
            products (like the StudyBuddy app), please refer to the specific
            privacy policy for that product.
          </p>
          <p>
            If you are engaging Siyaram Nextech on behalf of an organization,
            you represent that you have the necessary authority to bind the
            organization to this Privacy Policy.
          </p>
        </div>
      </div>

      <div style={styles.section}>
        <h2 style={styles.heading}>Personal Data We Collect</h2>
        <p style={styles.paragraph}>
          Siyaram Nextech Pvt. Ltd. (“Siyaram Nextech,” “we,” “us,” or “our”) is
          committed to protecting the privacy of our clients. This Privacy
          Policy describes the types of personal data we collect, use, and
          disclose in connection with the IT development, security, maintenance,
          and other IT solution services we offer (the “Services”).
        </p>
        <p style={{ fontSize: "30px", fontWeight: "bold" }}>
          Data Collected from Clients:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <strong>Client Contact Information:</strong> Your name, job title,
            company affiliation, email address, phone number, and other contact
            details that enable us to communicate with you and fulfill your
            requests.
          </li>
          <li style={styles.listItem}>
            <strong>Project Information:</strong> Details pertaining to the IT
            project you engage us for, such as project specifications,
            requirements documents, technical data, and any other information
            necessary to complete the project successfully.
          </li>
          <li style={styles.listItem}>
            <strong>Communication Data:</strong> Records of our interactions
            with you, including emails, phone calls, meeting notes, and other
            forms of communication related to the Services.
          </li>
          <li style={styles.listItem}>
            <strong>Payment Information:</strong> In some cases, we may collect
            payment information such as credit card details or bank account
            information to facilitate financial transactions for the Services.
            We use secure third-party payment processors to handle this data,
            and we do not store your full payment card details ourselves.
          </li>
        </ul>
      </div>
      <p style={{ fontSize: "30px", fontWeight: "bold" }}>
        Data Collected from Client Users (if applicable):
      </p>
      <p>
        If the Services involve the development or maintenance of a platform
        used by your end users, we may collect and process data from those users
        on your behalf. The specific data collected will depend on the nature of
        the platform and your requirements. However, we will ensure compliance
        with your instructions and all applicable data protection laws and
        regulations regarding such user data.
      </p>
      <p style={{ fontWeight: "bold" }}>
        We will never collect any personal data from you or your end users that
        is not necessary to deliver the Services or comply with legal
        requirements.
      </p>
      <p style={{ fontWeight: "bold" }}>Please Note :</p>
      <p>
        This Privacy Policy is not intended to replace any specific data privacy
        agreements you may have with your end users. You are responsible for
        ensuring that you have the necessary legal basis to collect and share
        your end user data with Siyaram Nextech.
      </p>
      <div style={styles.section}>
        <h2 style={styles.heading}>How We Use Your Personal Data</h2>
        <p style={styles.paragraph}>
          Siyaram Nextech Pvt. Ltd. (“Siyaram Nextech,” “we,” “us,” or “our”) is
          committed to using your personal data responsibly and in accordance
          with this Privacy Policy. We will only use your personal data for the
          purposes outlined below, or for any additional purposes for which we
          obtain your specific consent.
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            {" "}
            <strong> Delivering the Services:</strong> We use your personal data
            to provide the IT development, security, maintenance, or other IT
            solutions you engage us for. This includes using your contact
            information to communicate project details, using project
            information to complete the work, and using communication data to
            manage the project effectively.
          </li>
          <li style={styles.listItem}>
            <strong>Client Relationship Management:</strong> We may use your
            contact information to maintain a positive client relationship,
            including sending updates about our services, informing you of
            relevant industry developments, and responding to your inquiries.
          </li>
          <li style={styles.listItem}>
            {" "}
            <strong>Administrative Purposes</strong> We may use your personal
            data for internal administrative purposes, such as managing our
            contracts, invoicing, and ensuring compliance with legal and
            regulatory requirements.
          </li>
        </ul>
        <p style={{ fontSize: "20px", fontWeight: "bold" }}>Data Retention:</p>
        <p>
          We will retain your personal data for as long as necessary to fulfill
          the purposes outlined in this Privacy Policy, unless a longer
          retention period is required or permitted by law.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.heading}>Your Choices</h2>
        <p style={styles.paragraph}>
          Siyaram Nextech Pvt. Ltd. (“Siyaram Nextech,” “we,” “us,” or “our”)
          respects your right to control your personal data. Here’s how you can
          exercise your choices:
        </p>
        <li>
          <strong>Access and Correction:</strong>
          You have the right to request access to your personal data that we
          hold. You can also request that we correct any inaccurate or
          incomplete information
        </li>
        <li>
          <strong>Deletion:</strong>
          You have the right to request that we delete your personal data,
          subject to certain exceptions. For instance, we may need to retain
          some data for legal or contractual reasons.
        </li>
        <li>
          <strong>Objection and Restriction:</strong>
          You may have the right to object to the processing of your personal
          data for certain purposes or to request restriction on how we process
          it.
        </li>
        <p>
        To exercise any of these rights, please contact us using the information provided in the Contact Us section below.


        </p>
        <li>
           <strong>
           Please note: 
            </strong> 
            We may require you to verify your identity before processing your request. There may also be a fee associated with fulfilling certain requests, as permitted by law.


        </li>
      </div>
<p style={{fontWeight:"bold"}}>
Withdrawal of Consent:
</p>
<p>
If you have previously provided your consent to the collection and processing of your personal data, you have the right to withdraw your consent at any time. However, this withdrawal will not affect the lawfulness of any processing we conducted before your withdrawal.
</p>
<p>
Additionally, depending on the nature of the Services and the data involved, withdrawing your consent might limit our ability to provide certain services to you. 


</p>
<h3>Compliance with applicable Privacy Laws</h3>
<p>
This Privacy Policy is designed to comply with the General Data Protection Regulation (GDPR) and Indian privacy laws, including the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data of Information) Rules, 2011 (“Data Protection Rules”) under the Information Technology Act, 2000 that require publishing of the Privacy Policy for collection, use, storage and transfer of information.
</p>
<div>
    <p style={{fontSize:"30px"}}>
    Changes to This Privacy Policy
    </p>
    We may update this Privacy Policy from time to time. If we make any material changes, we will post a notice on the App or send you an email notification.


</div>
      <div style={styles.section}>
        <h2 style={styles.heading}>Contact Us</h2>
        <p style={styles.paragraph}>
          If you have any questions about this Privacy Policy, please contact us
          at:
        </p>
        <a href="mailto:Siyaramgroup.nextech@gmail.com" style={styles.contact}>
          Siyaramgroup.nextech@gmail.com
        </a>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
