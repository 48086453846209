import React, { useState, useEffect } from "react";
import useScreenSizes from "../../utils/useScreenSize";
import { useSelector } from "react-redux";
const defaultStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '20px',
    width: '96%',
    backgroundColor: '#fff',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    margin: '5vw auto',
  },
  jobTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  jobDetails: {
    display: 'flex',
    alignItems: 'center',
    color: '#757575',
    marginBottom: '20px',
  },
  detailItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
  },
  locationIcon: {
    height: '20px',
    marginRight: '5px',
  },
  jobDescription: {
    fontSize: '16px',
    color: '#424242',
    marginBottom: '20px',
  },
  applyButton: {
    backgroundColor: '#5A67D8',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
};

const Careerscard = ({
  person,
  location,
  jobtime,
  jobinfo,
  containerStyle,
  jobTitleStyle,
  jobDetailsStyle,
  jobDescriptionStyle,
  applyButtonStyle,
  locationIconStyle,
  buttonContainerStyle,

}) => {
 
  const screenType = useSelector((state) => state.website.screenType);
  const container = {
    ...defaultStyles.container,
    padding: screenType==="phone" ? '10px' : screenType==="tablet" ? '15px' : '20px',
    width: screenType==="phone" ? '90%' : screenType==="tablet" ? '90%' : '90%',
    ...containerStyle,
  };

  const jobTitle = {
    ...defaultStyles.jobTitle,
    fontSize: screenType==="phone" ? '20px' : screenType==="tablet" ? '22px' : '24px',
    ...jobTitleStyle,
  };

  const jobDescription = {
    ...defaultStyles.jobDescription,
    fontSize: screenType==="phone" ? '14px' : screenType==="tablet" ? '15px' : '16px',
    ...jobDescriptionStyle,
  };

  const applyButton = {
    ...defaultStyles.applyButton,
    padding: screenType==="phone" ? '8px 16px' : '10px 20px',
    ...applyButtonStyle,
  };

  return (
    <div style={container}>
      <div style={jobTitle}>
        {person || 'Subject Matter Expert'}
      </div>
      <div style={{ ...defaultStyles.jobDetails, ...jobDetailsStyle }}>
        <div style={defaultStyles.detailItem}>
          <img
            src={require('../../assets/images/location.png')}
            style={{ ...defaultStyles.locationIcon, ...locationIconStyle }}
            alt='location'
          />
          <p>{location || 'Anywhere India'}</p>
        </div>
        <div style={defaultStyles.detailItem}>
          <img
            src={require('../../assets/images/location.png')}
            style={{ ...defaultStyles.locationIcon, ...locationIconStyle }}
            alt='jobtime'
          />
          <p>{jobtime || 'Full Time'}</p>
        </div>
      </div>
      <div style={jobDescription}>
        {jobinfo ||
          'Are you passionate about spreading the word about transformative educational solutions? Join our sales team and help schools and educational institutions discover the benefits of StudyBuddy, empowering them to provide students with innovative learning experiences.'}
      </div>
      <div style={{ ...defaultStyles.buttonContainer, ...buttonContainerStyle }}>
        <button style={applyButton}>
          Apply Now
        </button>
      </div>
    </div>
  );
};

export default Careerscard;
