import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, auth } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { setSideBar, setUser } from "../../reduxStore/slices/authSlice";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorModalActive, setErrorModalActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [forgotPasswordActive, setForgotPasswordActive] = useState(false); // State to toggle forgot password UI
  const [resetEmail, setResetEmail] = useState(""); // For reset email
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const screenType = useSelector((state) => state.website.screenType);
  useEffect(() => {
    if (token) {
      dispatch(setSideBar(true));
    } else {
      dispatch(setSideBar(false));
    }
  }, [token, dispatch]);

  const validateEmail = (email) => {
    // Simple email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const signIn = async () => {
    if (!email) {
      setErrorMessage("Please enter your email.");
      setErrorModalActive(true);
      return;
    }
    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      setErrorModalActive(true);
      return;
    }
    if (!password) {
      setErrorMessage("Please enter your password.");
      setErrorModalActive(true);
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const token = await user.getIdToken();
      dispatch(setUser({ user: user.toJSON(), token }));

      setEmail("");
      setPassword("");
      navigate("/LMS");
      dispatch(setSideBar(true));
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
      setErrorModalActive(true);
    }
  };

  const handleForgotPassword = () => {
    setForgotPasswordActive(true);
  };

  const handleResetPassword = async () => {
    if (!resetEmail) {
      setErrorMessage("Please enter your email for password reset.");
      setErrorModalActive(true);
      return;
    }
    if (!validateEmail(resetEmail)) {
      setErrorMessage("Please enter a valid email address.");
      setErrorModalActive(true);
      return;
    }

    try {
      // Add your logic for sending a password reset email
      // Example: await sendPasswordResetEmail(auth, resetEmail);
      alert(`Password reset link sent to ${resetEmail}`);
      setResetEmail("");
      setForgotPasswordActive(false); // Hide reset fields after sending the email
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
      setErrorModalActive(true);

    }
  };
  const handleLogin=()=>{
    setErrorMessage("Please enter a valid email address and password");
 
    setErrorModalActive(true);
  }

  const styles = {
    container: {
      margin: 0,
      padding: 0,
      height: "100vh",
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    infoContainer: {
      width: "100%",
      backgroundColor: "#c7985f",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    LoginInfo: {
      marginTop: "20px",
      width: "30%",
      flex: 0.1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    LoginButton: {
      flex: 0.1,
      display: "flex",
      cursor: "pointer",
      width: 300,
      color: "white",
      backgroundColor: "#295FF4",
      borderRadius: "4px",
      justifyContent: "center",
      marginTop: "2%",
    },
    NewUserButton: {
      marginTop: 15,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
    },
    input: {
      marginTop: "10px",
      width: "100%",
      padding: "10px",
      borderRadius: "4px",
      border: "none",
    },
    signUp: {
      height: "40px",
      width: "300px",
      border: "none",
      marginTop: "2%",
      borderRadius: "4px",
      backgroundColor: "#007BFF",
      color: "#fff",
      cursor: "pointer",
    },
    errorStyle: {
      marginTop: "20px",
      padding: "10px",
      borderRadius: "5px",
      backgroundColor: "#f8d7da",
      color: "#721c24",
      border: "1px solid #f5c6cb",
      textAlign: "center",
    },
  };
  const handleImageClick = () => {
    navigate("/");
  };
  return (
    <div style={styles.container}>
      <div style={{display:"flex", width:"100vw", marginTop:screenType==="desktop"? "50px": screenType==="phone"||screenType==="smallPhone"?"20px": screenType === "tab"|| screenType==="tablet" ? "":"", display:"flex", justifyContent:"end" }}>
        <img src={require("../../assets/Icons/close.png")} style={{height:"30px", width:"auto", marginRight:"20px",  cursor:"pointer", }} onClick={handleImageClick}/>
      </div>
      <div style={styles.infoContainer}>
        {!forgotPasswordActive && (
          <>
            <div style={styles.LoginInfo}>
              <input
                title="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={styles.input}
              />
              <br />
              <input
                title="password"
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={styles.input}
              />
            </div>
            <div style={styles.LoginButton} onClick={signIn}>
              <p>Login</p>
            </div>
            <div style={styles.NewUserButton}>
              <button style={styles.signUp} onClick={handleForgotPassword}>
                Forget Password?
              </button>
            </div>
          </>
        )}
        {forgotPasswordActive && (
          <div style={styles.LoginInfo}>
            <input
              title="resetEmail"
              placeholder="Enter your email for reset"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              style={styles.input}
            />
            <button style={styles.signUp} onClick={handleResetPassword}>
              Reset
            </button>
          </div>
        )}
        {errorModalActive && (
          <div style={styles.errorStyle}>
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
