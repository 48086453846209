import React from 'react';
import useScreenSizes from '../../utils/useScreenSize';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const Website = () => {
  // Data array for all management systems
  const Data = [
    {
      title: 'Customized Website Solutions for Your institute ',
      explanation:
        'At Study Buddy, we understand how important it is to have a strong online presence in today’s education world. We don’t just offer a learning management system; we also provide personalized website design services tailored to fit your school’s unique style and goals.We work closely with you to create a custom website that showcases your institutions values and style. Our designs are not only visually appealing but also focus on improving communication and engagement between students and teachers. With fully customizable options, we make sure your website delivers a smooth experience for everyone involved in your educational platform.',
      features: [
        {
          title: 'Tailored Design Aligned with Your Institute’s Brand',
          content: 'We ensure that the website reflects your institutes unique branding and values. Each design aspect is carefully selected to create a cohesive and professional look that resonates with your school’s identity',
          image: require("../../assets/WebsiteImages/tailoredDesignAlignedWithYourInstitute'sBrand (1).png"),   
        },
        {
          title: 'Enhanced User Experience and Accessibility',
          content: 'Our websites are designed to be user-friendly and accessible for all users, including students, parents, and faculty. We focus on intuitive navigation and accessibility features, ensuring that everyone can interact with your platform easily',
          image: require("../../assets/WebsiteImages/enhancedUserExperienceAndAccessibility (1).png"),   
        },
        {
          title: 'Integrated Communication and Engagement Tools',
          content: 'We include features like online chat, announcements, and forums that improve communication and engagement between students, teachers, and parents. This helps in creating a collaborative and interactive online community',
          image: require("../../assets/WebsiteImages/integratedCommunicationAndEngagementTools (1).png"),   
        },
      ],
    },
    {
      title: 'Custom Software Solutions for institute',
      explanation:
        'At Study Buddy, we offer strong software development solutions designed specifically for educational institutions. Our services cover every step of the software development process, from planning and design to development, testing, and deployment.Using the latest technologies and best practices, we create applications that are scalable, secure, and easy to use. Our goal is to boost your operational efficiency and enhance the learning experience for students.',
      features: [
        {
          title: 'Comprehensive Software Development Lifecycle',
          content: 'From planning and design to deployment, we manage the entire development process. This allows us to deliver software solutions that are efficient, secure, and tailored to meet the specific needs of your institution.',
          image: require("../../assets/WebsiteImages/comprehensiveSoftwareDevelopmentLifecycle (1).png"),   
        },
        {
          title: 'Focus on Security and Scalability',
          content: 'Our software solutions prioritize data security and scalability to support future growth. We use the latest encryption methods and scalable frameworks, ensuring that your software remains secure and adapts to evolving needs',
          image: require("../../assets/WebsiteImages/focusOnSecurityAndScalbility.png"),   
        },
        {
          title: 'User-Centric and Easy-to-Navigate Interfaces',
          content: 'We design applications with user experience in mind, creating intuitive interfaces for both students and faculty. Our goal is to enhance the educational experience by making it easy for users to access and interact with the platform',
          image: require("../../assets/WebsiteImages/userCentricAndEasyToNavigateInterfaces (1).png"),   
        },
      ],
    },
   
   
    
  ];

  const screen = useScreenSizes();

  const [hoveredCardIndex, setHoveredCardIndex] = useState();
  const screenType = useSelector((state) => state.website.screenType);

  const styles = {
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingtop:  screenType==="smallPhone"||screenType==="phone"?"7vh" : '10vh', 
       },
    cardContainer: {
      display: 'flex',
      justifyContent: screenType==="smallPhone" || screenType==="phone" ? "center" : 'space-between',
      width: screenType==="smallPhone" || screenType==="phone" ? "100vw" : '95vw',
      flexDirection: screenType==="smallPhone" || screenType==="phone" ? "column" : "",
      height: screenType==="smallPhone" || screenType==="phone" ? "100vh" : '50vh',
      marginBottom: "25px"
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: screenType==="smallPhone" || screenType==="phone" ? "90vw" : '30%',
      backgroundColor: '#f0f0f0',
      borderRadius: '10px',
      overflowY: 'scroll',
      boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)",
      marginBottom: "25px",
      transition: 'transform 0.3s ease', // Smooth transition for scaling
    },
    cardHover: {
      transform: 'scale(1.05)', // Scale up the card on hover
    },
    cardImage: {
      height: '70%',
      overflow: 'hidden',
    },
    cardContent: {
      height: '30%',
      padding: '10px',
      backgroundColor: '#fff',
      textAlign: 'center',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    dataContainer: {
      marginTop: screenType==="smallPhone" ? "25vw":screenType==="phone"?"25vw" : screenType==="tab"? "15vw":screenType==="tablet"?"12vw": '2vw',  
      width: screenType==="smallPhone" || screenType==="phone" ? "98vw" : '95vw',
      padding: '20px',
      backgroundColor: '#fff',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
    },
  };
  
  return (
    <div style={styles.mainContainer}>
      {Data.map((system, systemIndex) => (
        <div key={systemIndex} style={styles.dataContainer}>
         <p style={{fontSize:"25px", fontWeight:"bold" , fontFamily: "raleway", }}>{system.title}</p>
         <div style={styles.cardContainer}>
            {system.features.map((feature, index) => (
              <div
                key={index}
                style={{
                  ...styles.card,
                  ...(hoveredCardIndex === index ? styles.cardHover : {}),
                }}
                onMouseEnter={() => setHoveredCardIndex(index)}
                onMouseLeave={() => setHoveredCardIndex(null)}
              >
                <div style={styles.cardImage}>
                  <img
                    src={feature.image} // Dynamic image loading
                    alt={feature.title}
                    style={styles.image}
                  />
                </div>
                <div style={styles.cardContent}>
                <p style={{fontSize:"20px", margin:"0px"}}>{feature.title}</p>
                                  <p>{feature.content}</p>
                </div>
              </div>
            ))}
          </div>
          <p>{system.explanation}</p>
        </div>
      ))}
    </div>
  );
};

export default Website;
